import FIELD_TYPE from 'Component/Field/Field.config';
import { validatePassword } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/**
 * Overridden to change guest_email field label and remove field placeholder
 * @namespace Scandipwa/Component/CheckoutGuestForm/Form/checkoutGuestForm */
export const checkoutGuestForm = (props, events) => {
    const {
        emailValue,
        isCreateUser,
        range,
        minimunPasswordCharacter
    } = props;
    const {
        handleEmailInput,
        handlePasswordInput
    } = events;

    return [
        {
            type: FIELD_TYPE.email,
            label: __('Email address'),
            attr: {
                name: 'guest_email',
                defaultValue: emailValue,
                'aria-label': __('Your email'),
                autocomplete: 'email'
            },
            events: {
                onChange: handleEmailInput
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.email,
                isRequired: true
            }
        },
        ...(isCreateUser ? [{
            type: FIELD_TYPE.password,
            label: __('Create Password'),
            attr: {
                name: 'guest_password',
                placeholder: __('Create Password'),
                'aria-label': __('Create Password')
            },
            addRequiredTag: true,
            events: {
                onChange: handlePasswordInput
            },
            validateOn: ['onChange'],
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.password,
                isRequired: true,
                match: (value) => validatePassword(value, range, minimunPasswordCharacter)
            }
        }] : [])
    ];
};

export default checkoutGuestForm;
