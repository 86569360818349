import PropTypes from 'prop-types';

import {
    GRID_LAYOUT_MULTIPLE_COLUMNS,
    GRID_LAYOUT_ONE_COLUMN
} from 'Route/CategoryPage/CategoryPage.config';

export const GridLayoutTotalColumnType = PropTypes.oneOf([
    GRID_LAYOUT_MULTIPLE_COLUMNS,
    GRID_LAYOUT_ONE_COLUMN
]);
