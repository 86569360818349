/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import Popup from 'Component/Popup';
import PopupStandard from 'Component/PopupStandard';

import {
    PayfortElements
} from '../../Payfort.config';
import { camelToSnakeCase } from '../../util/Payfort.util';
import { MerchantPageComponent } from '../MerchantPage/MerchantPage.component';

import './RedirectionPage.override.style';

/** @namespace Scandiweb/AmazonPayfort/Component/RedirectionPage/Component */
export class RedirectionPageComponent extends MerchantPageComponent {
    renderDataForm() {
        const {
            formData = {},
            formUrl,
            formRef
        } = this.props;

        return (
            <form
              ref={ formRef }
              method="post"
              action={ formUrl }
              style={ { display: 'none' } }
            >
                { Object.keys(formData).map((dataName) => {
                    const dataValue = formData[dataName];
                    if (!dataValue) {
                        return null;
                    }

                    const name = camelToSnakeCase(dataName);

                    return (
                        <input
                          type="hidden"
                          name={ name }
                          value={ dataValue }
                          key={ dataName }
                        />
                    );
                }) }
            </form>
        );
    }

    render() {
        const {
            handleCancel
        } = this.props;

        return (
            <Popup
              id={ PayfortElements.PAYMENT_POPUP }
              mix={ { block: 'PayfortWindowPopup', elem: 'Tabby' } }
              isCloseOnOutsideClick={ false }
              onClose={ handleCancel }
            >
                <PopupStandard>
                    <div block="MerchantPage">
                        { this.renderDataForm() }
                    </div>
                </PopupStandard>
            </Popup>
        );
    }
}

export default RedirectionPageComponent;
