import PropTypes from 'prop-types';

import CameraIcon from 'Component/CameraIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import UploadIcon from 'Component/UploadIcon';
import {
    FieldFile as SourceFieldFile
} from 'SourceComponent/FieldFile/FieldFile.component';

import './FieldFile.override.style';

/** @namespace Scandipwa/Component/FieldFile/Component */
export class FieldFileComponent extends SourceFieldFile {
    static propTypes = {
        ...this.propTypes,
        getOnUploadImageClick: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    /**
     * New method to render default file input area
     */
    renderAreaInput() {
        const {
            attr = {},
            attr: { accept = '' } = {},
            events = {},
            setRef
        } = this.props;

        const allowedFieldTypes = (accept || '')
            .split(',')
            .map((type = '') => type.split('/').slice(-1)[0])
            .join(', ');

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  type={ FIELD_TYPE.file }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                />
                { this.renderFileLabel() }
                { allowedFieldTypes.length > 0 && this.renderSubLabel(allowedFieldTypes) }
            </>
        );
    }

    /**
     * New method to render capture image button
     */
    renderCaptureButton() {
        const {
            getOnUploadImageClick,
            isMobile,
            attr: {
                isCaptureOnMobile = false
            } = {}
        } = this.props;

        if (!isMobile || !isCaptureOnMobile) {
            return null;
        }

        return (
            <button
              type="button"
              mix={ {
                  block: 'Button',
                  mods: {
                      isHollow: true,
                      isSmall: !isMobile
                  }
              } }
              onClick={ getOnUploadImageClick(true) }
            >
              <CameraIcon />
              { __('Take a picture') }
            </button>
        );
    }

    /**
     * New method to render image input as upload button
     */
    renderImageInput() {
        const {
            isMobile,
            setRef,
            attr = {},
            events = {},
            getOnUploadImageClick,
            fileName = ''
        } = this.props;

        return (
            <>
                <div block="FieldFile" elem="ImageUpload">
                    <button
                      type="button"
                      mix={ {
                          block: 'Button',
                          mods: {
                              isHollow: true,
                              isSmall: !isMobile
                          }
                      } }
                      onClick={ getOnUploadImageClick(false) }
                    >
                        <UploadIcon />
                        { __('Upload file') }
                    </button>
                    { this.renderCaptureButton() }
                    <input
                      ref={ (elem) => setRef(elem) }
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...attr }
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...events }
                      type="file"
                      accept="image/*"
                    />
                </div>
                <span block="FieldFile" elem="FileName">
                    { fileName && fileName }
                </span>
            </>
        );
    }

    /**
     * Overidden to:
     * - Render corresponding input depending on attr value
     */
    render() {
        const {
            attr: {
                isImageUpload = false
            } = {}
        } = this.props;

        return isImageUpload ? this.renderImageInput() : this.renderAreaInput();
    }
}

export default FieldFileComponent;
