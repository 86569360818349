import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import ResetPasswordPopup from 'Component/ResetPasswordPopup';
import {
    MyAccountSignIn as SourceMyAccountSignIn
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';
import { noopFn } from 'Util/Common';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './MyAccountSignIn.override.style';

/** @namespace Scandipwa/Component/MyAccountSignIn/Component */
export class MyAccountSignInComponent extends SourceMyAccountSignIn {
    static propTypes = {
        ...this.propTypes,
        handleForgetPasswordPopupClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        handleForgetPasswordPopupClick: noopFn
    };

    /**
     * Overridden to:
     * - Change position of forgot password nad text for sign in button
     * - Remove loader because it has been rendered on LoginAccount component render method
     */
    renderSignInForm() {
        const {
            onSignInSuccess,
            onFormError,
            handleForgetPasswordPopupClick,
            emailValue,
            isCheckout,
            handleEmailInput
        } = this.props;

        return (
            <Form
              key="sign-in"
              onSubmit={ onSignInSuccess }
              onError={ onFormError }
            >
                <Field
                  label={ __('Email') }
                  type={ FIELD_TYPE.email }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      defaultValue: emailValue,
                      autocomplete: isCheckout ? 'off' : 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  events={ { onChange: handleEmailInput } }
                  addRequiredTag
                />
                <Field
                  label={ __('Password') }
                  type={ FIELD_TYPE.password }
                  attr={ {
                      id: 'password',
                      name: 'password',
                      autocomplete: 'current-password'
                  } }
                  validateOn={ isCheckout ? ['onSubmit'] : ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password
                  } }
                  addRequiredTag
                />
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{ __('Log in') }</button>
                </div>
                <button
                  type="button"
                  block="Button"
                  mods={ { likeLink: true } }
                  mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
                  onClick={ handleForgetPasswordPopupClick }
                >
                    { __('Forgot password?') }
                </button>
            </Form>
        );
    }

    /**
     * New method to render reset password pop-up
     */
    renderResetPasswordPopup() {
        return <ResetPasswordPopup />;
    }

    /**
     * Overridden to renderResetPasswordPopup usage
     */
    render() {
        return (
            <>
                { super.render() }
                { this.renderResetPasswordPopup() }
            </>
        );
    }
}

export default MyAccountSignInComponent;
