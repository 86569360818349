import CheckEmailQuery from 'Query/CheckEmail.query';
import { CheckoutDispatcher as SourceCheckoutDispatcher } from 'SourceStore/Checkout/Checkout.dispatcher';

/** @namespace Scandipwa/Store/Checkout/Dispatcher */
export class CheckoutDispatcher extends SourceCheckoutDispatcher {
    /**
     * Overridden to encode email to be sent correctly in persistent query url
     */
    prepareRequest(email) {
        return CheckEmailQuery.getIsEmailAvailableQuery(encodeURIComponent(email));
    }
}

export default new CheckoutDispatcher();
