import {
    ProductCompareDispatcher as SourceProductCompareDispatcher
} from 'SourceStore/ProductCompare/ProductCompare.dispatcher';

/** @namespace Scandipwa/Store/ProductCompare/Dispatcher */
export class ProductCompareDispatcher extends SourceProductCompareDispatcher {
    getCompareList() {
        return true;
    }

    assignCompareList() {
        return true;
    }
}

export default new ProductCompareDispatcher();
