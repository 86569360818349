/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addGtmEnglishMetaTitle = (args, callback) => [
    ...callback(...args),
    'meta_title_eng'
];

export default {
    'Query/Category/Query': {
        'member-function': {
            _getDefaultFields: addGtmEnglishMetaTitle
        }
    }
};
