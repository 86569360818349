import { sortAlphabetically } from 'Util/Product/Product';

/** @namespace Scandipwa/Util/StoreInPickup/pickupStoresToOptions */
export const pickupStoresToOptions = (pickupStores) => {
    const filteredStores = pickupStores?.filter(
        // vvv Make sure to not include null stores (might happen if preselected pickup store is null)
        (pickupStore) => !!pickupStore
    );
    const structuredStores = filteredStores.map(({
        pickup_location_code,
        city,
        area,
        storeName
    }) => ({
        id: pickup_location_code,
        label: [city, area, storeName].filter((item) => item).join(' - '),
        value: pickup_location_code
    }));

    sortAlphabetically(structuredStores, 'label');

    return structuredStores;
};
