export const UPDATE_MENU_ITEMS = 'UPDATE_MENU_ITEMS';
export const SET_MENU_STATUS = 'SET_MENU_STATUS';
export const SET_SELECTED_MENU = 'SET_SELECTED_MENU';

/**
 * Set navigation menu items
 * @param  {Object} menus Object with 3 different types of menus
 * @return {void}
 * @namespace Scandipwa/Store/Menu/Action/updateMenuItems */
export const updateMenuItems = (menus) => ({
    type: UPDATE_MENU_ITEMS,
    menus
});

/** @namespace Scandipwa/Store/Menu/Action/setMenuStatus */
export const setMenuStatus = (status, animated = true) => ({
    type: SET_MENU_STATUS,
    status,
    animated
});

/** @namespace Scandipwa/Store/Menu/Action/setSelectedMenu */
export const setSelectedMenu = (selectedMenu) => ({
    type: SET_SELECTED_MENU,
    selectedMenu
});
