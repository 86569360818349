import {
    STATE_SIGN_IN
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    CheckoutGuestForm as SourceCheckoutGuestForm
} from 'SourceComponent/CheckoutGuestForm/CheckoutGuestForm.component';

import './CheckoutGuestForm.override.style';

/** @namespace Scandipwa/Component/CheckoutGuestForm/Component */
export class CheckoutGuestFormComponent extends SourceCheckoutGuestForm {
    // eslint-disable-next-line
    handleAlreadyHaveAnAccountClick = () => {
        const { setSignInState } = this.props;
        setSignInState(STATE_SIGN_IN);
    };

    /**
     * Overridden to add selector on span tag
     */
    renderFormBody() {
        return (
            <>
                <div block="FieldForm" elem="Body">
                    <div block="FieldForm" elem="Fields">
                        <div block="Field" elem="Label" className="already-have-an-account">
                            Have an account?
                            <button block="Field" elem="Label" onClick={ this.handleAlreadyHaveAnAccountClick }>
                                Log in
                            </button>
                        </div>
                        { this.fieldMap.map(this.renderSection.bind(this)) }
                    </div>
                    { this.renderActions() }
                </div>
                <span
                  block="CheckoutGuestForm"
                  elem="TextWrapper"
                >
                    { __('You can create an account after checkout.') }
                </span>
            </>
        );
    }
}

export default CheckoutGuestFormComponent;
