import { PureComponent } from 'react';

import './CameraIcon.style';

/** @namespace Scandipwa/Component/CameraIcon/Component */
export class CameraIconComponent extends PureComponent {
    /**
     * New Method to render Camera icon
     */
    render() {
        return (
            <svg
              block="CameraIcon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
                <path
                  d="M 20 5 H 16.83 L 15 3 H 9 L 7.17 5 H 4 C 3.4696 5 2.9609 5.2107
                     2.5858 5.5858 C 2.2107 5.9609 2 6.4696 2 7 V 19 C 2 19.5304 2.2107
                     20.0391 2.5858 20.4142 C 2.9609 20.7893 3.4696 21 4 21 H 20
                     C 20.5304 21 21.0391 20.7893 21.4142 20.4142 C 21.7893 20.0391 22
                     19.5304 22 19 V 7 C 22 6.4696 21.7893 5.9609 21.4142 5.5858
                     C 21.0391 5.2107 20.5304 5 20 5 Z M 20 19.4 H 3.6 V 6.6 H 8.05
                     L 9.88 4.6 H 14.12 L 15.95 6.6 H 20.4 V 19.4 Z M 12 8 C 10.6739 8
                     9.4022 8.5268 8.4645 9.4645 C 7.5268 10.4021 7 11.6739 7 13 C 7
                     14.3261 7.5268 15.5979 8.4645 16.5355 C 9.4022 17.4732 10.6739 18
                     12 18 C 13.3261 18 14.5979 17.4732 15.5355 16.5355 C 16.4732 15.5979
                     17 14.3261 17 13 C 17 11.6739 16.4732 10.4021 15.5355 9.4645 C 14.5979
                     8.5268 13.3261 8 12 8 Z M 12 16 C 11.2044 16 10.4413 15.6839 9.8787
                     15.1213 C 9.3161 14.5587 9 13.7956 9 13 C 9 12.2044 9.3161 11.4413
                     9.8787 10.8787 C 10.4413 10.3161 11.2044 10 12 10 C 12.7956 10 13.5587
                     10.3161 14.1213 10.8787 C 14.6839 11.4413 15 12.2044 15 13 C 15 13.7956
                     14.6839 14.5587 14.1213 15.1213 C 13.5587 15.6839 12.7956 16 12 16 Z"
                />
            </svg>
        );
    }
}

export default CameraIconComponent;
