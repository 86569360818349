import PropTypes from 'prop-types';
import { isValidElement } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Scandipwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    static propTypes = {
        ...super.propTypes,
        selectedOptionLabel: PropTypes.element.isRequired,
        selectedOptionValue: PropTypes.string.isRequired,
        handleOnMouseDown: PropTypes.func.isRequired,
        isNoResultsAndIsSearching: PropTypes.bool,
        handleNativeSelectBlur: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        isNoResultsAndIsSearching: false
    };

    renderSearch() {
        const {
            isSearch,
            isDisabled,
            filter,
            handleSearchChange,
            focusSelect,
            handleSearchBlur,
            handleSearchFocus,
            placeholder,
            attr: { defaultValue }
        } = this.props;

        if (!isSearch) {
            return null;
        }

        return (
            <input
              block="FieldSelect"
              elem="Input"
              disabled={ isDisabled }
              type="text"
              defaultValue={ defaultValue }
              value={ filter }
              onChange={ handleSearchChange }
              onClick={ focusSelect }
              onBlur={ handleSearchBlur }
              onFocus={ handleSearchFocus }
              placeholder={ placeholder }
            />
        );
    }

    renderSelectHtmlLabel() {
        const { selectedOptionLabel, isDisabled } = this.props;

        if (
            isDisabled
            || (!isDisabled && !isValidElement(selectedOptionLabel))
        ) {
            return null;
        }

        return (
            <div block="FieldSelect" elem="HtmlLabels">
                { selectedOptionLabel }
            </div>
        );
    }

    renderDefaultSearchValueOption() {
        const { isSearch } = this.props;

        if (!isSearch) {
            return null;
        }

        return <option value="" selected disabled hidden>Select</option>;
    }

    /**
    * Extended this method because the label for installments is not a string so [object Object]
    * was being rendered in the place of dropdown option labels
    */
    renderNativeOption(option) {
        const {
            id,
            value,
            disabled,
            label,
            subLabel = '',
            isAvailable = true,
            isPlaceholder
        } = option;

        const { isDisabled } = this.props;

        if (typeof label !== 'string') {
            const installmentDurationAndInterestRate = label?.props?.children[0]?.props?.children;
            const priceOfInstallments = label?.props?.children[2]?.props?.children;

            return (
                <option
                  key={ id }
                  id={ id }
                  value={ value }
                  disabled={ disabled || isDisabled || !isAvailable }
                  selected={ isPlaceholder }
                >
                    { `${installmentDurationAndInterestRate} \n ${priceOfInstallments}` }
                </option>
            );
        }

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled || isDisabled || !isAvailable }
              selected={ isPlaceholder }
            >
                { `${label} ${subLabel}` }
            </option>
        );
    }

    /**
     * Overridden to add isNotSelected and renderSearch
     */
    renderNativeSelect() {
        const {
            setRef,
            attr: {
                defaultValue,
                ...attr
            },
            events,
            isDisabled,
            options,
            handleSelectListOptionClick,
            isSelectedOptionAvailable,
            isSearch,
            selectedOptionLabel,
            selectedOptionValue,
            isShowHtmlOptions,
            handleNativeSelectBlur
        } = this.props;

        const fieldValue = 'value' in attr ? attr.value : attr.defaultValue;

        const isNotSelected = options.every(
            (option) => option.isPlaceholder
                || String(option.value) !== String(fieldValue)
        );

        return (
            <div
              block="FieldSelect"
              elem="NativeSelectWrapper"
            >
                { this.renderSelectHtmlLabel() }
                <select
                  block="FieldSelect"
                  elem="Select"
                  mods={ {
                      isDisabled: !isSelectedOptionAvailable,
                      isHidden:
                            !isDisabled && isValidElement(selectedOptionLabel),
                      isNotSelected,
                      isSearch,
                      isPointerEventsDisabled: isShowHtmlOptions
                  } }
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                    /* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
                  { ...attr }
                  { ...events }
                  onBlur={ handleNativeSelectBlur }
                    // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (...args) => handleSelectListOptionClick(...args)() }
                  value={ selectedOptionValue }
                  defaultValue={ defaultValue === '' ? null : defaultValue }
                >
                    { this.renderDefaultSearchValueOption() }
                    { options.map((option) => this.renderNativeOption(option)) }
                </select>
                { this.renderSearch() }
            </div>
        );
    }

    /**
     * Overridden to solve mobile on start scrolling list options selects the list option unintentionally
     * by removing onTouchStart attribute on .FieldSelect-Option element
     */
    renderOption(option) {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            isShowHtmlOptions
        } = this.props;

        if (!isAvailable) {
            return null;
        }

        if (isPlaceholder) {
            return (
                <li
                  block="FieldSelect"
                  elem="Option"
                  mods={ {
                      isExpanded,
                      isPlaceholder,
                      isShowOnMobile: isShowHtmlOptions
                  } }
                  key={ id }
                  id={ `o${id}` }
                  role="menuitem"
                  onMouseDown={ handleSelectListOptionClick(option) }
                  onKeyPress={ handleSelectListOptionClick(option) }
                  tabIndex={ isExpanded ? '0' : '-1' }
                >
                    <span block="FieldSelect" elem="PlaceHolderSpan">
                        { label }
                    </span>
                </li>
            );
        }

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered,
                  isShowOnMobile: isShowHtmlOptions
              } }
              key={ id }
              id={ `o${id}` }
              role="menuitem"
              onMouseDown={ handleSelectListOptionClick(option) }
              onKeyPress={ handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
                { subLabel && <strong>{ ` ${subLabel}` }</strong> }
            </li>
        );
    }

    renderNoResults() {
        const {
            filter,
            isNoResultsAndIsSearching,
            isExpanded
        } = this.props;

        if (!isNoResultsAndIsSearching) {
            return null;
        }

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isExpanded
              } }
              key="no-results"
              id="no-results"
              role="menuitem"
            >
                { __('No results for: %s', filter) }
            </li>
        );
    }

    /**
     * Overridden to show html options on mobile optionally
     */
    renderOptions() {
        const {
            options,
            isExpanded,
            isDropdownOpenUpwards,
            isScrollable,
            isShowHtmlOptions,
            handleOnMouseDown
        } = this.props;

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ {
                  isExpanded,
                  isDropdownOpenUpwards,
                  isNotScrollable: !isScrollable,
                  isShowHtmlOptions
              } }
              onMouseDown={ handleOnMouseDown }
            >
                <div
                  block="FieldSelect"
                  elem="OptionsWrapper"
                  role="menu"
                  mods={ { isExpanded } }
                >
                    { this.renderNoResults() }
                    { options.map(this.renderOption.bind(this)) }
                </div>
            </ul>
        );
    }

    /**
     * Overridden to add isSearch mod to .FieldSelect element
     */
    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled,
            isSearch
        } = this.props;

        const elementOnClick = !isDisabled ? handleSelectExpand : null;
        const elementOnKeyPress = !isDisabled ? handleSelectListKeyPress : null;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${id}_wrapper` }
                  block="FieldSelect"
                  mods={ {
                      isExpanded,
                      isSearch,
                      isDisabled
                  } }
                  onClick={ elementOnClick }
                  onKeyPress={ elementOnKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderSortSelect() }
                        { this.renderNativeSelect() }
                        <ChevronIcon direction={ isExpanded ? TOP : BOTTOM } />
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
