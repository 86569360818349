import { Suspense } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import { ExpandableContent } from 'Route/Checkout/Checkout.component';
import {
    CategoryDetails as SourceCategoryDetails
} from 'SourceComponent/CategoryDetails/CategoryDetails.component';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { getCloudflareOptimizationImage } from 'Util/Resize';

import AmastyFaqReducer from '../../../packages/amasty-faq/src/store/AmastyFaq.reducer';
import MagefanBlogReducer from '../../../packages/magefan-blog/src/store/MagefanBlog.reducer';

import './CategoryDetails.override.style';

/** @namespace Scandipwa/Component/CategoryDetails/Component */
export class CategoryDetailsComponent extends SourceCategoryDetails {
    renderCategoryName() {
        const {
            category: { name, id, is_banner_active },
            isCurrentCategoryLoaded
        } = this.props;

        if (is_banner_active || (id && !name)) {
            return null;
        }

        return (
            <p
              block="CategoryDetails"
              elem="Heading"
              mods={ {
                  isReady: !!name || isCurrentCategoryLoaded
              } }
            >
                { this.renderCategoryText() }
            </p>
        );
    }

    renderCategoryImage() {
        const {
            location: {
                state: {
                    categoryImage,
                    category: categoryId
                } = {}
            }
        } = history;
        const {
            category: {
                image,
                id = categoryId,
                is_banner_active
            },
            isCurrentCategoryLoaded
        } = this.props;
        const {
            actionName: {
                imageUrl = ''
            }
        } = window;

        if (is_banner_active) {
            return null;
        }

        if (
            !id
            || (!isCurrentCategoryLoaded && !categoryImage && !imageUrl)
            || (!image && !categoryImage && !imageUrl)
        ) {
            return this.renderCategoryImagePlaceholder();
        }

        const imageSrc = categoryImage || imageUrl || image;

        return (
            <Image
              mix={ { block: 'CategoryDetails', elem: 'Picture' } }
              // eslint-disable-next-line no-magic-numbers
              src={ getCloudflareOptimizationImage(imageSrc, 700) || '' }
              ratio="custom"
              objectFit="cover"
              key={ imageSrc }
              onImageLoad={ setLoadedFlag }
            />
        );
    }

    renderBannerImage() {
        const {
            category: {
                is_banner_active,
                banner_desktop_image,
                banner_mobile_image
            },
            isMobile,
            baseUrl
        } = this.props;

        const src = isMobile
            ? baseUrl + banner_mobile_image?.substring(1)
            : baseUrl + banner_desktop_image?.substring(1);

        if (!is_banner_active) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'CategoryDetails', elem: 'Banner' } }
              // eslint-disable-next-line no-magic-numbers
              src={ src || '' }
              ratio="custom"
              key={ src }
              onImageLoad={ setLoadedFlag }
            />
        );
    }

    renderCategoryDescription() {
        const { location: { state: { categoryDescription, category: categoryId } = {} } } = history;

        const {
            category: { description, id = categoryId, is_banner_active },
            isCurrentCategoryLoaded
        } = this.props;
        const {
            actionName: {
                description: preloadedDescription = ''
            }
        } = window;

        if (is_banner_active || (!categoryDescription && !isCurrentCategoryLoaded && !preloadedDescription)) {
            return null;
        }

        if (!id || (!isCurrentCategoryLoaded && !categoryDescription && !preloadedDescription)) {
            return this.renderCategoryDescriptionPlaceholder();
        }

        if (!description && !categoryDescription && !preloadedDescription) {
            return null;
        }

        return <Html content={ categoryDescription || description || preloadedDescription } />;
    }

    renderCategoryNameFallback(isExpandable = false) {
        return (
            <div block="CategoryDetails" elem="Description" mods={ { isExpandable, isFallback: true } }>
                { this.renderCategoryName() }
            </div>
        );
    }

    renderCategoryDetails() {
        const { isMobile } = this.props;

        if (!isMobile) {
            return (
                <div block="CategoryDetails" elem="Description">
                    { this.renderCategoryName() }
                    { this.renderCategoryDescription() }
                </div>
            );
        }

        return (
            <Suspense fallback={ this.renderCategoryNameFallback(true) }>
                <div block="CategoryDetails" elem="Description" mods={ { isExpandable: true } }>
                    <ExpandableContent
                      isArrow
                      heading={ this.renderCategoryName() }
                      mix={ { block: 'CategoryDetails', elem: 'DescriptionText' } }
                    >
                        { this.renderCategoryDescription() }
                        { this.renderCategoryImage() }
                    </ExpandableContent>
                </div>
            </Suspense>
        );
    }

    render() {
        const { category: { is_banner_active: isBannerActive }, isMobile } = this.props;

        return (
            <article
              block="CategoryDetails"
              mods={ { isBannerActive } }
            >
                { this.renderCategoryDetails() }
                { !isMobile && this.renderCategoryImage() }
                { this.renderBannerImage() }
            </article>
        );
    }
}

export default withReducers({
    AmastyFaqReducer,
    MagefanBlogReducer
})(CategoryDetailsComponent);
