/**
 * MageAnts Sample Product compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageantsSampleProduct/Query/AddSampleProduct/Query */
export class AddSampleProductQuery {
    getSampleProductToCartMutation(cartId, cartItems) {
        return new Field('addSampleProductToCart')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('cartItems', '[CartItemInput!]!', cartItems)
            .addField(this._getUserErrorsField());
    }

    _getUserErrorsField() {
        return new Field('user_errors')
            .addFieldList(this._getUserErrorsFields());
    }

    _getUserErrorsFields() {
        return [
            'message',
            'code'
        ];
    }
}

export default new AddSampleProductQuery();
