/**
 * GA4MeasurementProtocol frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/ga4MeasurementProtocol/Query/GaCookie/Query */
export class GaCookieQuery {
    setGaCookie(gaCid, gaSessionCookie, guestCartId = null) {
        return new Field('setGaCookie')
            .addArgument('ga_cookie', 'String!', gaCid)
            .addArgument('ga_session_cookie', 'String!', gaSessionCookie)
            .addArgument('guestCartId', 'String', guestCartId);
    }

    getGaSessionCookie() {
        return new Field('getGaSessionCookie');
    }

    setGaAdditionalData(referrer, landingPageUrl, guestCartId = null) {
        return new Field('setGaAdditionalData')
            .addArgument('referrer', 'String!', referrer)
            .addArgument('landingPageUrl', 'String!', landingPageUrl)
            .addArgument('guestCartId', 'String', guestCartId);
    }
}

export default new (GaCookieQuery)();
