/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_AMAZON_PAYFORT_STORE } from './AmazonPayfort.config';

/** @namespace Scandiweb/AmazonPayfort/Store/AmazonPayfort/Action/updateAmazonPayfortStore */
export const updateAmazonPayfortStore = (state) => ({
    type: UPDATE_AMAZON_PAYFORT_STORE,
    state
});
