/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { noopFn } from 'Util/Common';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { PayfortHostedCard } from '../../Payfort.config';
import {
    focusAndSelectCardNumber,
    formatCardNumber,
    formatCVC,
    formatExpirationDate,
    validateCardNumber,
    validateCVC,
    validateExpirationDate
} from '../../util/Payfort.util';

import './PaymentCard.style';

/** @namespace Scandiweb/AmazonPayfort/Component/PaymentCard/Component */
export class PaymentCardComponent extends PureComponent {
    static defaultProps = {
        setExpirationDateState: noopFn,
        setCardHolderNameState: noopFn,
        cardNumberValidator: validateCardNumber,
        cardNumberFailMessage: __('Invalid card number.'),
        isShowCVC: true,
        isShowCardNumber: true,
        isShowExpiryDate: true,
        isRequired: true
    };

    static propTypes = {
        cardNumberValidator: PropTypes.func,
        setExpirationDateState: PropTypes.func,
        setCardHolderNameState: PropTypes.func,
        cardNumberFailMessage: PropTypes.string,
        isShowCVC: PropTypes.bool,
        isShowCardNumber: PropTypes.bool,
        isShowExpiryDate: PropTypes.bool,
        isRequired: PropTypes.bool
    };

    renderCardholderNameField() {
        const {
            isRequired,
            setCardHolderNameState
        } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.text }
              label={ __('Card Holder Name') + (!isRequired ? __(' (Optional)') : '') }
              attr={ {
                  name: PayfortHostedCard.CARDHOLDER_NAME,
                  placeholder: __('Card Holder Name'),
                  'aria-label': __('Card Holder Name')
              } }
              validateOn={ ['onChange'] }
              events={ {
                  onChange: setCardHolderNameState
              } }
              validationRule={ {
                  isRequired,
                  inputType: VALIDATION_INPUT_TYPE.alphaSpace
              } }
              addRequiredTag={ isRequired }
            />
        );
    }

    renderCardNumberField() {
        const { isShowCardNumber, cardNumberValidator, cardNumberFailMessage } = this.props;

        if (!isShowCardNumber) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.text }
              label={ __('Card Number') }
              attr={ {
                  name: PayfortHostedCard.CARD_NUMBER,
                  placeholder: __('Card Number'),
                  'aria-label': __('Card Number')
              } }
              events={ {
                  onClick: focusAndSelectCardNumber,
                  onChange: formatCardNumber
              } }
              validateOn={ ['onBlur'] }
              validationRule={ {
                  isRequired: true,
                  match: cardNumberValidator,
                  customErrorMessages: {
                      onMatchFail: cardNumberFailMessage
                  }
              } }
              addRequiredTag
            />
        );
    }

    renderExpirationDateField() {
        const {
            isShowExpiryDate,
            isRequired,
            setExpirationDateState
        } = this.props;

        if (!isShowExpiryDate) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.text }
              label={ __('Expiration Date') + (!isRequired ? __(' (Optional)') : '') }
              attr={ {
                  name: PayfortHostedCard.EXPIRATION_DATE,
                  placeholder: __('MM / YY'),
                  'aria-label': __('Expiration Date')
              } }
              events={ {
                  onChange: formatExpirationDate.bind({}, setExpirationDateState)
              } }
              validateOn={ ['onBlur'] }
              validationRule={ {
                  isRequired,
                  match: validateExpirationDate.bind({}, isRequired),
                  customErrorMessages: {
                      onMatchFail: __('Invalid expiration date.')
                  }
              } }
              addRequiredTag={ isRequired }
            />
        );
    }

    renderCVCField() {
        const { isShowCVC } = this.props;

        if (!isShowCVC) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.text }
              label={ __('CVC') }
              attr={ {
                  name: PayfortHostedCard.CVC,
                  placeholder: '***',
                  'aria-label': __('CVC')
              } }
              events={ {
                  onChange: formatCVC
              } }
              validateOn={ ['onBlur'] }
              validationRule={ {
                  isRequired: true,
                  match: validateCVC,
                  customErrorMessages: {
                      onMatchFail: __('Invalid Card Verification Code.')
                  }
              } }
              addRequiredTag
            />
        );
    }

    render() {
        return (
            <div block="PaymentCard">
                { this.renderCardholderNameField() }
                { this.renderCardNumberField() }
                <div block="PaymentCard" elem="Row">
                    { this.renderExpirationDateField() }
                    { this.renderCVCField() }
                </div>
            </div>
        );
    }
}

export default PaymentCardComponent;
