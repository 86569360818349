/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DeviceType, getDeviceType } from 'Util/Device';

import { AvailableSocialsType } from '../../type/Social.type';
import SocialLoginButtons from './SocialLoginButtons.component';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButtons/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    availableSocials: state.ConfigReducer.availableSocials,
    isAndroid: state.ConfigReducer.device.android
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButtons/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButtons/Container */
export class SocialLoginButtonsContainer extends PureComponent {
    static propTypes = {
        availableSocials: AvailableSocialsType.isRequired,
        isAndroid: PropTypes.bool.isRequired
    };

    containerFunctions = {};

    __construct(props) {
        super.__construct(props);
    }

    containerProps() {
        const { availableSocials } = this.props;

        return {
            availableSocials
        };
    }

    render() {
        const { isAndroid } = this.props;

        /**
         * Disables social login on Android device because of security reason
         * because currently, Android device social login implementation uses WebView
         * which isn't good for user security as described on below link:
         * - https://support.google.com/accounts/answer/12917337?hl=en#zippy=%2Cdisallowed-useragent
         * - https://developers.facebook.com/blog/post/2021/06/28/deprecating-support-fb-login-authentication-android-embedded-browsers/
         * - https://developers.facebook.com/docs/facebook-login/android/deprecating-webviews
         */
        if (
            getDeviceType() === DeviceType.IOS_WKWEBVIEW
            || isAndroid
        ) {
            return null;
        }

        return (
            <SocialLoginButtons
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLoginButtonsContainer);
