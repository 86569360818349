/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmazonPayfort/Query/PayfortAppleValidateAddress/Query */
export class PayfortAppleValidateAddressQuery {
    getPayfortAppleValidateAddressMutation(cartId, addressObject) {
        return new Field('payfortAppleValidateAddress')
            .addArgument('input', 'PayfortAppleValidateAddressInput', {
                cartId,
                addressObject
            })
            .addFieldList(this.getPayfortAppleValidateFields());
    }

    getPayfortAppleValidateFields() {
        return [
            'status',
            'taxes',
            'error_msg',
            this.getDataField(),
            this.getTotalsField()
        ];
    }

    getDataField() {
        return new Field('data')
            .addFieldList(this.getShippingRateFields());
    }

    getTotalsField() {
        return new Field('totals')
            .addFieldList(this.getTotalsFields());
    }

    getShippingRateFields() {
        return [
            'identifier',
            'label',
            'detail',
            'amount'
        ];
    }

    getTotalsFields() {
        return [
            'subTotal',
            'shippingFees',
            'discount',
            'taxes',
            'total'
        ];
    }
}

export default new PayfortAppleValidateAddressQuery();
