/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import {
    lazy,
    Suspense,
    useEffect,
    useRef,
    useState
} from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getPrice } from 'Util/Product/Extract';
import getStore from 'Util/Store';

import {
    CLERK_LAST_CATEGORY,
    CLERK_LAST_DAY,
    CLERK_LAST_PRODUCT,
    CLERK_POWERSTEP,
    CLERK_PRODUCT_IDS,
    CLERK_THANKYOU
} from '../Recommend/Recommend.config';
import {
    BLOG_WIDGET,
    CART_WIDGET,
    CATEGORY_WIDGET,
    CLERK_EMAIL,
    CLERK_LAST_RECOMMEND_TYPE,
    CLERK_NOT_USE_CACHED,
    CLERK_PRODUCT_LIMIT,
    CLERK_WAITING_TIME_LIMIT_MS,
    getterType,
    HOMEPAGE_WIDGET,
    MINICART_WIDGET,
    PRODUCT_WIDGET,
    strategyMap,
    WIDGET_CMS_TYPE
} from './Widgets.config';

export const Recommend = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "clerk" */
    '../Recommend'
));

export {
    PRODUCT_WIDGET,
    CATEGORY_WIDGET,
    CART_WIDGET,
    MINICART_WIDGET,
    WIDGET_CMS_TYPE,
    HOMEPAGE_WIDGET,
    BLOG_WIDGET,
    CLERK_PRODUCT_LIMIT,
    CLERK_EMAIL,
    CLERK_WAITING_TIME_LIMIT_MS,
    CLERK_LAST_RECOMMEND_TYPE,
    CLERK_NOT_USE_CACHED,
    strategyMap,
    getterType
};

/** @namespace Scandiweb/Clerk/Component/Widgets/Widget */
export const Widget = ({ widget, type, order }) => {
    const [productIDs, setProductIDs] = useState([]);
    const [changeIds, setChangeIds] = useState([]);
    const currentPage = useRef('');
    const { id } = getStore().getState().UrlRewritesReducer.urlRewrite;
    const [currentId, setCurrentId] = useState(0);
    const isCartType = type === CART_WIDGET
        || type === MINICART_WIDGET
        || type === CLERK_THANKYOU
        || type === CLERK_POWERSTEP;
    const { items = [] } = BrowserDatabase.getItem('cart_totals') || {};
    const isWidget = (!Object.values(widget).length && !type && !order.length) || type === 'widget';
    const isBlog = window.location.pathname.split('/').includes('blog');
    const blogOrHomePage = isBlog ? BLOG_WIDGET : HOMEPAGE_WIDGET;
    const widgetType = isWidget ? blogOrHomePage : type;
    const iIds = items.map(({ id }) => id).sort((a, b) => a - b);
    const arrayIds = widgetType === CATEGORY_WIDGET ? currentId : [id || window?.clerkSelectedProduct];
    const isProductPage = getStore().getState().UrlRewritesReducer.urlRewrite?.type === 'PRODUCT'
        && !isWidget
        && !isCartType;

    const currentProduct = getStore().getState().ProductReducer?.product || {};
    const currentIndex = useRef(1);
    const [currentFilter, setCurrentFilter] = useState('');
    const isRequested = useRef(false);
    const isRequestSuccess = useRef(false);
    const [currentCategory, setCurrentCategory] = useState({});
    const MIN_PRODUCT_LENGTH_FOR_CATEGORY = 4;
    const conf = useRef({});
    const result = useRef([]);
    const isChanged = useRef(true);
    const idsToExclude = useRef([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const clerkConfig = conf.current;
    const [countFailed, setCountFailed] = useState(0);
    const storeCode = getStore().getState().ConfigReducer.code;

    const configRecommendType = {
        category: clerkConfig?.categoryRecommend,
        cart: clerkConfig?.cartRecommend,
        minicart: clerkConfig?.minicartRecommend,
        popup: clerkConfig?.powerstepRecommend,
        thankyou: clerkConfig?.thankyouRecommend,
        blog: clerkConfig?.blogRecommend,
        homepage: clerkConfig?.homepageRecommend
    };

    const limitType = {
        product: clerkConfig?.productLimit,
        category: clerkConfig?.categoryLimit,
        cart: clerkConfig?.cartLimit,
        minicart: clerkConfig?.minicartLimit,
        popup: clerkConfig?.powerstepLimit,
        thankyou: clerkConfig?.thankyouLimit,
        blog: clerkConfig?.blogLimit,
        homepage: clerkConfig?.homepageLimit
    };

    const isUseEmail = getterType[configRecommendType[widgetType]] === CLERK_EMAIL;
    const email = BrowserDatabase.getItem('customer')?.email || 'test@test.com';
    const productLimit = limitType[widgetType] || CLERK_PRODUCT_LIMIT;
    const useIds = isCartType ? iIds : arrayIds;
    const countLoaded = useRef(0);
    const CLERK_MAX_LOADED = 3;
    const currentDay = new Date().getDay();
    const lastCategoryId = BrowserDatabase.getItem(CLERK_LAST_CATEGORY);
    const currentPageLoading = useRef(true);

    // vvv Getting productIDs from clerk recommendations
    const getRecommend = async () => {
        const { getClerk, getConfig } = await import('../../util/config');

        const id = await getStore().getState().UrlRewritesReducer.urlRewrite.id;
        const { isLoading } = getStore().getState().UrlRewritesReducer;
        const clerk = await getClerk();
        const config = getStore().getState().ConfigReducer?.clerkConfig;
        const localProductIds = BrowserDatabase.getItem(CLERK_PRODUCT_IDS);
        const currentCategoryBreadcrumbs = await getStore().getState().CategoryReducer?.category.breadcrumbs;
        const shouldUseBestSellersInCategory = widgetType === CATEGORY_WIDGET
         && (!currentCategoryBreadcrumbs || currentCategoryBreadcrumbs?.length === 1);

        const configRecommendType = {
            category: config?.categoryRecommend,
            cart: config?.cartRecommend,
            minicart: config?.minicartRecommend,
            popup: config?.powerstepRecommend,
            thankyou: config?.thankyouRecommend,
            blog: config?.blogRecommend,
            homepage: config?.homepageRecommend
        };

        const getter = getterType[configRecommendType[widgetType]];

        const recommendLabelMap = {
            cart: `Cart Page / ${config?.cartRecommend}`,
            category: `Category Page / ${config?.categoryRecommend}`,
            popup: `Product Page / ${config?.powerstepRecommend}`,
            minicart: `Cart Page / ${config?.minicartRecommend}`,
            thankyou: `Home Page / ${config?.thankyouRecommend}`,
            blog: `Home Page / ${config?.blogRecommend}`,
            homepage: `Home Page / ${config?.homepageRecommend}`
        };

        const keywordsType = {
            product: config?.productKeywords,
            category: config?.categoryKeywords,
            cart: config?.cartKeywords,
            minicart: config?.minicartKeywords,
            popup: config?.powerstepKeywords,
            thankyou: config?.thankyouKeywords,
            blog: config?.blogKeywords,
            homepage: config?.homepageKeywords
        };

        const limitType = {
            product: config?.productLimit,
            category: config?.categoryLimit,
            cart: config?.cartLimit,
            minicart: config?.minicartLimit,
            popup: config?.powerstepLimit,
            thankyou: config?.thankyouLimit,
            blog: config?.blogLimit,
            homepage: config?.homepageLimit
        };

        const lastDay = BrowserDatabase.getItem(CLERK_LAST_DAY);
        const lastRecommendType = BrowserDatabase.getItem(CLERK_LAST_RECOMMEND_TYPE);
        const shouldUseCachedProducts = lastDay?.[storeCode] === currentDay
        && (
            lastRecommendType?.[storeCode]?.[widgetType]?.recommendType === configRecommendType?.[widgetType]
        ) && (
            lastRecommendType?.[storeCode]?.[widgetType]?.keywordsType === keywordsType?.[widgetType]
        );

        const productLimit = limitType[widgetType] || CLERK_PRODUCT_LIMIT;

        const {
            clerkTrackingConfig: {
                publicKey
            } = {}
        } = await getConfig() || {};

        if (
            (widgetType === CATEGORY_WIDGET && !shouldUseBestSellersInCategory)
            || !configRecommendType[widgetType]
        ) {
            return;
        }

        if (!config) {
            const newCount = countFailed + 1;

            if (newCount <= CLERK_MAX_LOADED) {
                setCountFailed(newCount);
            } else {
                result.current = localProductIds?.[storeCode]?.[widgetType] || [];
                setProductIDs(result.current);
            }

            return;
        }

        if (
            (isProductPage && widgetType !== 'popup')
            || productIDs?.length
            || isLoading
        ) {
            return;
        }

        setCurrentId(id);

        if (widgetType === CATEGORY_WIDGET && !currentId) {
            return;
        }

        if (
            shouldUseCachedProducts
            && (widgetType === 'homepage' || widgetType === 'blog')
            && localProductIds
            && localProductIds?.[storeCode]?.[widgetType]?.length
        ) {
            BrowserDatabase.setItem(false, CLERK_NOT_USE_CACHED);
            result.current = localProductIds?.[storeCode]?.[widgetType];
            setProductIDs(localProductIds?.[storeCode]?.[widgetType]);

            return;
        }

        if (!clerk) {
            return;
        }

        clerk('call',
            strategyMap[configRecommendType[widgetType]],
            {
                key: publicKey,
                limit: productLimit,
                [getter]: isUseEmail ? email : useIds,
                labels: [recommendLabelMap[widgetType]],
                visitor: 'auto',
                keywords: keywordsType[widgetType]
            },
            (response) => {
                if (id !== currentId) {
                    return;
                }

                const shouldChangeIds = id === currentId
                    && JSON.stringify(
                        BrowserDatabase.getItem(CLERK_PRODUCT_IDS)?.[storeCode]?.[widgetType]
                    ) !== JSON.stringify(response.result);

                const newData = {
                    ...BrowserDatabase.getItem(CLERK_PRODUCT_IDS),
                    [storeCode]: {
                        ...BrowserDatabase.getItem(CLERK_PRODUCT_IDS)?.[storeCode],
                        [widgetType]: response.result
                    }
                };

                if (shouldChangeIds) {
                    BrowserDatabase.setItem(newData, CLERK_PRODUCT_IDS);
                }

                isChanged.current = shouldChangeIds;
                result.current = response.result;
                setProductIDs(response.result);
                isRequested.current = true;
                countLoaded.current += 1;

                if (currentDay !== lastDay) {
                    const data = { ...lastDay, [storeCode]: currentDay };
                    BrowserDatabase.setItem(data, CLERK_LAST_DAY);
                }

                if (widgetType === CATEGORY_WIDGET && id !== lastCategoryId) {
                    BrowserDatabase.setItem(id, CLERK_LAST_CATEGORY);
                }

                const newType = {
                    ...lastRecommendType,
                    [storeCode]: {
                        ...lastRecommendType?.[storeCode],
                        [widgetType]: {
                            recommendType: configRecommendType?.[widgetType],
                            keywordsType: keywordsType?.[widgetType]
                        }
                    }
                };

                if (!shouldUseCachedProducts) {
                    BrowserDatabase.setItem(true, CLERK_NOT_USE_CACHED);
                    BrowserDatabase.setItem(newType, CLERK_LAST_RECOMMEND_TYPE);
                }
            },
            (response) => {
                if (!countFailed > CLERK_MAX_LOADED) {
                    const newCount = countFailed + 1;
                    setCountFailed(newCount);
                }
                console.error(response);
            });
    };

    const getRecommendByCategory = async () => {
        const { getClerk, getConfig } = await import('../../util/config');

        const { categories: currentProductCategories = [], price_range = [], variants = [] } = currentProduct || {};
        const clerkConfig = await getConfig();
        const clerk = await getClerk();
        const {
            isLoading
        } = getStore().getState().UrlRewritesReducer;
        const { breadcrumbs = [] } = await getStore().getState().BreadcrumbsReducer || {};
        const {
            url: {
                state: {
                    category: lastCategoryIdFromBreadcrumbs
                } = {}
            } = {}
        } = breadcrumbs[1] || {};

        // vvv Utilizing history state, because on real instances UrlRewrites return nothing on page reload
        const {
            history: {
                state
            }
        } = window;
        const lastProduct = BrowserDatabase.getItem(CLERK_LAST_PRODUCT)?.[storeCode];
        const stateProduct = state?.state?.product;
        const isProductPage = type === 'product' || stateProduct;

        if (!isProductPage || !clerkConfig) {
            return;
        }

        const {
            id: stateId,
            childId
        } = stateProduct || {};

        const variant = variants.find(({ id }) => id === childId);
        const { categories: variantCategories = [] } = variant || {};
        const categories = currentProductCategories.length ? currentProductCategories : variantCategories;
        const lastCategoryIndex = categories.findIndex(({ id }) => id === lastCategoryIdFromBreadcrumbs);

        if (lastCategoryIndex === -1) {
            return;
        }

        const slicedCategories = categories.slice(0, lastCategoryIndex + 1);

        const productId = id || stateId;

        if (lastProduct === productId) {
            result.current = BrowserDatabase.getItem(CLERK_PRODUCT_IDS)?.[storeCode]?.[widgetType] || [];
            isChanged.current = false;

            setProductIDs(result.current);

            return;
        }

        // vvv Ensure categories are loaded
        if (!isRequested.current) {
            if (!slicedCategories.length) {
                return;
            }

            setCurrentCategory(slicedCategories[slicedCategories.length - 1]);

            isRequested.current = true;
        }

        const { name: currentCategoryName } = currentCategory || {};
        const { price: { finalPrice = 0 } = {} } = getPrice(price_range);

        if (!currentCategory || !currentCategoryName || !finalPrice) {
            const newCount = countFailed + 1;

            if (newCount <= CLERK_MAX_LOADED) {
                setCountFailed(newCount);
            }

            return;
        }

        if (isLoading || !clerk) {
            return;
        }

        clerk('call',
            'recommendations/keywords',
            {
                limit: productLimit,
                // category: currentCategoryId,
                keywords: `${currentCategoryName}`,
                labels: ['Best Sellers In Category'],
                visitor: 'auto',
                attributes: ['price', 'name'],
                filter: currentFilter
                    || `price > ${finalPrice.value}`,
                orderby: 'price',
                order: 'asc',
                exclude: changeIds
            },
            (response) => {
                // vvv if number of products < 4, change category to higher level
                if (
                    response.result.length < MIN_PRODUCT_LENGTH_FOR_CATEGORY
                    && slicedCategories.length > currentIndex.current
                ) {
                    currentIndex.current += 1;
                    setCurrentCategory(slicedCategories[currentIndex.current]);
                    setProductIDs([]);

                    return;
                }

                // vvv if no products in any category matches current filter, change filter strategy
                if (currentIndex.current === slicedCategories.length) {
                    setCurrentFilter(
                        `price < ${finalPrice.value} and price != 0`
                    );

                    setCurrentCategory(slicedCategories[slicedCategories.length - 1]);
                    currentIndex.current = 1;

                    return;
                }

                result.current = response.result;

                const priceToExclude = response.product_data.reduce((acc, item, idx) => {
                    const { price } = item;

                    if (!Object.values(acc).includes(price)) {
                        return { ...acc, [idx]: price };
                    }

                    return acc;
                }, {});

                const currentIdsToExclude = response.result.filter((_, idx) => !priceToExclude[idx]);

                idsToExclude.current = currentIdsToExclude;

                const newIds = [...changeIds, ...currentIdsToExclude];

                if (newIds !== changeIds) {
                    setChangeIds(newIds);
                }

                const shouldChangeIds = JSON.stringify(
                    BrowserDatabase.getItem(CLERK_PRODUCT_IDS)?.[storeCode]?.[widgetType]
                ) !== JSON.stringify(response.result);

                const newData = {
                    ...BrowserDatabase.getItem(CLERK_PRODUCT_IDS),
                    [storeCode]: {
                        ...BrowserDatabase.getItem(CLERK_PRODUCT_IDS)?.[storeCode],
                        [widgetType]: response.result
                    }
                };

                if (shouldChangeIds) {
                    BrowserDatabase.setItem(newData, CLERK_PRODUCT_IDS);
                }

                if (currentIdsToExclude.length) {
                    return;
                }

                isChanged.current = shouldChangeIds;
                result.current = response.result;

                setProductIDs(response.result);

                if (lastProduct !== productId) {
                    const newProduct = { [storeCode]: productId };

                    BrowserDatabase.setItem(newProduct, CLERK_LAST_PRODUCT);
                }

                isRequestSuccess.current = true;
            },
            (response) => {
                isRequestSuccess.current = false;
                console.error(response);
            });
    };

    useEffect(() => {
        if (widgetType === 'product') {
            return;
        }

        const newConf = getStore().getState()?.ConfigReducer?.clerkConfig;

        if (conf.current !== newConf) {
            conf.current = newConf;
        }

        if (currentPage.current !== window.location.pathname) {
            countLoaded.current = 0;
            setProductIDs([]);
            setCurrentId(0);
            setBreadcrumbs([]);
            setCountFailed(0);
            currentPageLoading.current = true;
            result.current = [];
            isRequestSuccess.current = false;
            isRequested.current = false;
            currentPage.current = window.location.pathname;
            window.clerkClickEvent = false;
        }

        if (countLoaded.current > CLERK_MAX_LOADED) {
            return;
        }

        getRecommend();
    }, [currentId, window.location, countFailed, storeCode, id, breadcrumbs]);

    useEffect(() => {
        if (widgetType !== 'product') {
            return;
        }

        if (currentPage.current !== window.location.pathname) {
            setProductIDs([]);
            setChangeIds([]);
            setCountFailed(0);
            currentPageLoading.current = true;
            result.current = [];
            isRequestSuccess.current = false;
            isRequested.current = false;
            currentPage.current = window.location.pathname;
            window.clerkClickEvent = false;
        }

        if (isRequestSuccess.current) {
            return;
        }

        getRecommendByCategory();
    }, [currentProduct, currentCategory, changeIds, countFailed, window.location]);

    useEffect(() => {
        const { breadcrumbs: actualBreadcrumbs = [] } = getStore().getState().BreadcrumbsReducer || {};

        if (JSON.stringify(actualBreadcrumbs) === JSON.stringify(breadcrumbs)) {
            return;
        }

        setBreadcrumbs(actualBreadcrumbs);
    }, [countFailed, window.location]);

    const localProductIds = BrowserDatabase.getItem(CLERK_PRODUCT_IDS);
    const shouldChange = (widgetType === 'homepage' || widgetType === 'blog')
        && localProductIds?.[storeCode]?.[widgetType]
        ? BrowserDatabase.getItem(CLERK_NOT_USE_CACHED)
        : isChanged.current;

    if (
        type === MINICART_WIDGET
        || type === CLERK_THANKYOU
        || type === CLERK_POWERSTEP
    ) {
        return (
            <Suspense fallback={ (
                <div
                  block="WidgetFactory"
                  elem="RecommendedProductsPlaceholder"
                />
                ) }
            >
                <Recommend result={ productIDs } type={ widgetType } isChanged={ isChanged.current } />
            </Suspense>
        );
    }

    return (
        <ContentWrapper
          wrapperMix={ {
              block: 'Recommend',
              elem: 'Wrapper'
          } }
        >
            <Suspense fallback={ (
                <div
                  block="WidgetFactory"
                  elem="RecommendedProductsPlaceholder"
                />
                ) }
            >
                <Recommend
                  result={ result.current }
                  type={ widgetType }
                  isChanged={ shouldChange }
                />
            </Suspense>
        </ContentWrapper>
    );
};

Widget.propTypes = {
    type: PropTypes.string.isRequired,
    order: PropTypes.string,
    widget: PropTypes.shape({})
};

Widget.defaultProps = {
    order: '',
    widget: {}
};

export default Widget;
