/* eslint-disable max-len */
import { PureComponent } from 'react';

import './UploadIcon.style';

/** @namespace Scandipwa/Component/UploadIcon/Component */
export class UploadIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="UploadIcon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
            >
                <path d="M1.33594 8H2.66927V11.3333H13.3359V8H14.6693V11.3333C14.6693 12.0733 14.0759 12.6667 13.3359 12.6667H2.66927C1.93594 12.6667 1.33594 12.0733 1.33594 11.3333V8ZM8.0026 1.33333L4.30927 4.97333L5.25594 5.92L7.33594 3.83333V10H8.66927V3.83333L10.7559 5.92L11.7026 4.96666L8.0026 1.33333Z" />
            </svg>
        );
    }
}

export default UploadIconComponent;
