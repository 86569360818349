import PropTypes from 'prop-types';
import { cloneElement } from 'react';

import ClickOutside from 'Component/ClickOutside';
import { QUICK_VIEW_POPUP_ID } from 'Component/QuickViewPopup/QuickViewPopup.config';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';
import { noopFn } from 'Util/Common';
import history from 'Util/History';

import { ESCAPE_KEY } from './Popup.config';

import './Popup.override.style';

/** @namespace Scandipwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    static propTypes = {
        ...this.propTypes,
        titleIcon: PropTypes.elementType,
        pdpHeading: PropTypes.func,
        isGoBackOnHide: PropTypes.boolean
    };

    static defaultProps = {
        pdpHeading: noopFn
    };

    unmountPopUp = this.unmountPopUp.bind(this);

    state = {
        isClosing: false
    };

    /**
     * Overridden to:
     * - Not go back when needed in some popups
     * - {isGoBackOnHideKeyEvent} is used when isGoBackOnHide is not accurate because of key event
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    hidePopupAndGoBack(isGoBackOnHideKeyEvent = false) {
        const { isGoBackOnHide } = this.props;

        this.hidePopUp();

        if (isGoBackOnHide && !isGoBackOnHideKeyEvent) {
            history.goBack();
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleKeyDown(e) {
        switch (e.keyCode) {
        case ESCAPE_KEY:
            this.hidePopupAndGoBack(true);
            break;
        default:
            break;
        }
    }

    componentFunctions = {
        // TODO: vvv Ideally, when all popups will be refactored, this should be removed
        renderTitle: this.renderTitle.bind(this),
        hidePopupAndGoBack: this.hidePopupAndGoBack.bind(this)
    };

    /**
     * Overridden to animate when closing quick view popup
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    hidePopUp() {
        const {
            id,
            hideActiveOverlay,
            goToPreviousNavigationState,
            onClose
        } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return;
        }

        if (id === QUICK_VIEW_POPUP_ID) {
            this.setState({ isClosing: true });
            return;
        }

        this.unfreezeScroll();
        hideActiveOverlay();
        goToPreviousNavigationState();
        onClose();
    }

    /**
     * Overridden to animate when closing quick view popup
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleClickOutside() {
        const { id, isCloseOnOutsideClick, isMobile } = this.props;

        if (!isCloseOnOutsideClick) {
            return;
        }

        if (isMobile) {
            return;
        }

        if (id === QUICK_VIEW_POPUP_ID) {
            this.setState({ isClosing: true });
            return;
        }

        this.hidePopupAndGoBack();
    }

    /**
     * Unmount popup when animation ends
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    unmountPopUp() {
        const {
            id,
            hideActiveOverlay,
            goToPreviousNavigationState,
            onClose
        } = this.props;
        const isVisible = this.getIsVisible();
        const { isClosing } = this.state;

        if (isVisible && isClosing && id === QUICK_VIEW_POPUP_ID) {
            this.unfreezeScroll();
            hideActiveOverlay();
            goToPreviousNavigationState();
            onClose();
            this.setState({ isClosing: false });
        }
    }

    renderContent() {
        const {
            children: PopupContent,
            contentMix
        } = this.props;
        const { isClosing } = this.state;

        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div
                  block="Popup"
                  elem="Content"
                  mods={ { isClosing } }
                  mix={ contentMix }
                  onAnimationEnd={ this.unmountPopUp }
                >
                    { cloneElement(PopupContent, {
                        ...PopupContent.props,
                        ...this.componentFunctions
                    }) }
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
