/**
 * Amasty FAQ & Product Questions compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ContentWrapper from 'Component/ContentWrapper';
import { COLUMN_MAP } from 'Component/Footer/Footer.config';
import Html from 'Component/Html';
import { getStore } from 'Util/Store';

import { AMASTY_FAQ_FOOTER_LINK } from '../../config/AmastyFAQ.config';

const addFaqSettingsToMapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        settings: state.ConfigReducer?.getAmFaqSettings
    };
};

export const addAmastyFaqSettingsToProps = (args, callback, instance) => {
    const { settings } = instance.props;

    return { settings, ...callback(...args) };
};

export const addFaqLinkToFooter = (args, callback, instance) => {
    const {
        urlPrefix,
        title,
        isShowLinkFooter
    } = getStore().getState().ConfigReducer.getAmFaqSettings;

    const {
        cmsBlock: {
            identifier,
            content,
            disabled
        },
        blockType
    } = instance.props;

    const result = callback(...args);

    if (identifier !== AMASTY_FAQ_FOOTER_LINK) {
        return result;
    }

    const footerFaq = `<a class=" Footer-ColumnItem" href="/${ urlPrefix }">${ title }</a>`;
    const modifiedContent = content.replace('Cookies</a>', `Cookies</a>\n${footerFaq}`);

    if (disabled || !isShowLinkFooter) {
        return null;
    }

    if (!identifier) {
        return instance.renderPlaceholder();
    }

    return (
         <div
           block="CmsBlock"
           elem="Wrapper"
           mods={ { type: blockType } }
         >
             <Html content={ modifiedContent } />
         </div>
    );
};

export const addFaqLinkToFooterComponent = (args, callback, instance) => {
    const { settings: { title, urlPrefix, isShowLinkFooter } } = instance.props;

    if (!isShowLinkFooter) {
        return callback(...args);
    }

    const href = `/${urlPrefix}`;
    const item = {
        href,
        title
    };

    const { items } = COLUMN_MAP[1];
    const isLoaded = items.some((element) => element.href === item.href);

    if (!isLoaded && title) {
        items.push(item);
    }

    return (
        <ContentWrapper
          isNotSection
          wrapperMix={ { block: 'Footer', elem: 'Columns' } }
          label=""
        >
            { COLUMN_MAP.map(instance.renderColumn.bind(instance)) }
        </ContentWrapper>
    );
};

// vvv Needed to update component after Footer Links changed
export const addUpdateSettings = (args, callback, instance) => {
    const [nextProps] = args;
    const { settings } = instance.props;
    const { settings: nextSettings } = nextProps;

    return callback(...args) || settings !== nextSettings;
};

export default {
    'Component/CmsBlock/Component': {
        'member-function': {
            render: addFaqLinkToFooter
        }
    },
    'Component/Footer/Container/mapStateToProps': {
        function: addFaqSettingsToMapStateToProps
    },
    'Component/Footer/Container': {
        'member-function': {
            containerProps: addAmastyFaqSettingsToProps
        }
    },
    'Component/Footer/Component': {
        'member-function': {
            shouldComponentUpdate: addUpdateSettings,
            renderColumns: addFaqLinkToFooterComponent
        }
    }
};
