import { ShippingMethods } from 'Util/OfflineShipping';

export const TIMES = [
    '09:00-13:00',
    '13:00-17:00',
    '17:00-20:00'
];

export const AMOUNT_OF_DELIVERY_DAYS_TO_SHOW = 3;

export const MAX_SLOTS_FOR_METHOD = {
    [ShippingMethods.StandartShipping]: 14,
    [ShippingMethods.ExpressShipping]: 5
};

export const SLOT_WIDTH = 60;

export const MAXIMUM_TOTAL_SLIDE_ITEM_PER_SLIDE = 7;
