export * from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.config';

export const DELIVERY_MODES_BY_CODE = {
    standartShipping: {
        deliveryType: 'N01',
        deliveryMode: 'WHL'
    },
    expressShipping: {
        deliveryType: 'E01',
        deliveryMode: 'WHL'
    }
};
