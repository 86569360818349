import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './EyeIcon.style';

/** @namespace Scandipwa/Component/EyeIcon/Component */
export class EyeIconComponent extends PureComponent {
    static propTypes = {
        isShown: PropTypes.bool,
        isPassword: PropTypes.bool
    };

    static defaultProps = {
        isShown: true,
        isPassword: false
    };

    /**
     * New method to render the activated and the inactivated password icon
     */
    renderPasswordEyeIcon() {
        const {
            isShown,
            isPassword
        } = this.props;

        if (!isShown) {
            return (
                <svg
                  version="1.1"
                  block="EyeIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  mods={ { isPassword, isActive: isShown } }
                >
                    <path d="M19.1663 10C17.7247 6.34167 14.1663 3.75 9.99967 3.75C5.83301 3.75 2.27467
                        6.34167 0.833008 10C2.27467 13.6583 5.83301 16.25 9.99967 16.25C14.1663
                        16.25 17.7247 13.6583 19.1663 10Z"
                    />
                    <path d="M9.99967 7.5C10.6627 7.5 11.2986 7.76339 11.7674 8.23223C12.2363 8.70107
                        12.4997 9.33696 12.4997 10C12.4997 10.663 12.2363 11.2989 11.7674 11.7678C11.2986
                        12.2366 10.6627 12.5 9.99967 12.5C9.33663 12.5 8.70075 12.2366 8.23191 11.7678C7.76307
                        11.2989 7.49967 10.663 7.49967 10C7.49967 9.33696 7.76307 8.70107 8.23191 8.23223C8.70075
                        7.76339 9.33663 7.5 9.99967 7.5ZM9.99967 3.75C14.1663 3.75 17.7247 6.34167 19.1663
                        10C17.7247 13.6583 14.1663 16.25 9.99967 16.25C5.83301 16.25 2.27467 13.6583 0.833008
                        10C2.27467 6.34167 5.83301 3.75 9.99967 3.75ZM2.64967 10C4.02467 12.8 6.86634 14.5833
                        9.99967 14.5833C13.133 14.5833 15.9747 12.8 17.3497 10C15.9747 7.2 13.133 5.41667 9.99967
                        5.41667C6.86634 5.41667 4.02467 7.2 2.64967 10Z"
                    />
                </svg>
            );
        }

        return (
            <svg
              block="EyeIcon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              mods={ { isPassword, isActive: isShown } }
            >
                <path d="M19.1663 10C17.7247 6.34167 14.1663 3.75 9.99967 3.75C5.83301 3.75 2.27467
                    6.34167 0.833008 10C2.27467 13.6583 5.83301 16.25 9.99967 16.25C14.1663
                    16.25 17.7247 13.6583 19.1663 10Z"
                />
                <path d="M9.99967 7.5C10.6627 7.5 11.2986 7.76339 11.7674 8.23223C12.2363 8.70107
                    12.4997 9.33696 12.4997 10C12.4997 10.663 12.2363 11.2989 11.7674 11.7678C11.2986
                    12.2366 10.6627 12.5 9.99967 12.5C9.33663 12.5 8.70075 12.2366 8.23191 11.7678C7.76307
                    11.2989 7.49967 10.663 7.49967 10C7.49967 9.33696 7.76307 8.70107 8.23191 8.23223C8.70075
                    7.76339 9.33663 7.5 9.99967 7.5ZM9.99967 3.75C14.1663 3.75 17.7247 6.34167 19.1663
                    10C17.7247 13.6583 14.1663 16.25 9.99967 16.25C5.83301 16.25 2.27467 13.6583 0.833008
                    10C2.27467 6.34167 5.83301 3.75 9.99967 3.75ZM2.64967 10C4.02467 12.8 6.86634 14.5833
                    9.99967 14.5833C13.133 14.5833 15.9747 12.8 17.3497 10C15.9747 7.2 13.133 5.41667 9.99967
                    5.41667C6.86634 5.41667 4.02467 7.2 2.64967 10Z"
                />
            </svg>
        );
    }

    renderAccountEyeIcon() {
        return (
            <svg block="EyeIcon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.9974 6.5C8.52783 6.5 9.03654 6.71071 9.41161 7.08579C9.78668 7.46086 9.9974 7.96957
                    9.9974 8.5C9.9974 9.03043 9.78668 9.53914 9.41161 9.91421C9.03654 10.2893 8.52783 10.5
                    7.9974 10.5C7.46696 10.5 6.95825 10.2893 6.58318 9.91421C6.20811 9.53914 5.9974 9.03043
                    5.9974 8.5C5.9974 7.96957 6.20811 7.46086 6.58318 7.08579C6.95825 6.71071 7.46696 6.5
                    7.9974 6.5ZM7.9974 3.5C11.3307 3.5 14.1774 5.57333 15.3307 8.5C14.1774 11.4267 11.3307
                    13.5 7.9974 13.5C4.66406 13.5 1.8174 11.4267 0.664062 8.5C1.8174 5.57333 4.66406 3.5
                    7.9974 3.5ZM2.1174 8.5C3.2174 10.74 5.49073 12.1667 7.9974 12.1667C10.5041 12.1667
                    12.7774 10.74 13.8774 8.5C12.7774 6.26 10.5041 4.83333 7.9974 4.83333C5.49073 4.83333
                    3.2174 6.26 2.1174 8.5Z"
                />
            </svg>
        );
    }

    /**
     * Overridden to switch between eye icon in account page and the show/hide password icon
     */
    render() {
        const {
            isPassword
        } = this.props;

        return isPassword ? this.renderPasswordEyeIcon() : this.renderAccountEyeIcon();
    }
}

export default EyeIconComponent;
