/** @namespace Scandipwa/Util/MenuHelper/getChildren */
export const getChildren = (activeMenuId, menu) => {
    // eslint-disable-next-line
    for (let el of menu) {
        if (el.item_id === activeMenuId) {
            return [{ ...el }];
        }

        if (el.children) {
            const children = getChildren(activeMenuId, Object.values(el.children));

            if (children !== null) {
                return children;
            }
        }
    }

    return null;
};

/**
 * @param {MenuItem} menuItem
 * @param {number} targetId category or menu item id (depending on byCategoryId)
 * @param {Array} path , keep default [],
 * @param {Boolean} byCategoryId whether to search menu item by category_id or menu_id
 * @returns {{menuItem, path}}
 * @namespace Scandipwa/Util/MenuHelper/getMenuItem
 */
export const getMenuItem = (menuItem, targetId, path = [], byCategoryId = false) => {
    const categoryId = byCategoryId ? menuItem?.url?.state?.category : menuItem?.item_id;

    if (Number(categoryId) === Number(targetId)) {
        return {
            menuItem,
            path
        };
    }

    if (!menuItem?.children) {
        return null;
    }

    const children = Object.values(menuItem?.children);

    const result = children.reduce((acc, child) => {
        if (!acc) {
            const currentPath = [...path, byCategoryId ? child.url?.state?.category : child.item_id];
            return getMenuItem(child, targetId, currentPath, byCategoryId);
        }

        return acc;
    }, null);

    return result;
};

/** @namespace Scandipwa/Util/MenuHelper/getMenuItemByLevels */
export const getMenuItemByLevels = (
    menuItem,
    targetId,
    byCategoryId = false,
    furnitureMenu = {},
    path = [],
    levels = []
) => {
    const categoryId = byCategoryId ? menuItem?.url?.state?.category : menuItem?.item_id;

    if (Number(categoryId) === Number(targetId)) {
        const newLevels = Array.from({ length: 3 }, (_, i) => levels?.[i] || {});
        const { children = {} } = newLevels?.[0] || {};

        const { category_id, item_id } = Object.values(children).find(
            ({ title }) => title === newLevels?.[0]?.title
        ) || {};
        const currentPath = path.concat({ categoryId: category_id, itemId: item_id }) || path;
        const currentLevels = !Object.keys(newLevels?.[1] || {}).length
            ? [newLevels[0], Object.values(children).find(({ title }) => title === newLevels?.[0]?.title) || {}]
            : newLevels;

        return {
            menuItem,
            path: currentPath,
            levels: currentLevels
        };
    }

    if (!menuItem?.children) {
        return null;
    }

    const children = Object.values(menuItem?.children || {});

    const result = children.reduce((acc, child) => {
        if (acc) {
            return acc;
        }

        if (
            child?.url?.state?.category === furnitureMenu?.url?.state?.category
            && child?.item_id !== furnitureMenu?.item_id
        ) {
            return acc;
        }

        const childCategoryId = byCategoryId ? child?.url?.state?.category : child?.item_id;
        const currentPath = path.concat({ categoryId: childCategoryId, itemId: child?.item_id });
        const currentLevels = levels.concat(child);

        return getMenuItemByLevels(child, targetId, byCategoryId, furnitureMenu, currentPath, currentLevels) || null;
    }, null);

    return result;
};
