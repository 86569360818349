/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/prop-types */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { scrollToTop } from 'Util/Browser';
import { withReducers } from 'Util/DynamicReducer';

import {
    PAYFORT_NEW_TOKEN_POPUP_WINDOW, PayfortIntegrationTypes, PayfortMerchantPageEvents, PayfortPaymentMethods
} from '../../Payfort.config';
import { updateAmazonPayfortStore } from '../../store/AmazonPayfort/AmazonPayfort.action';
import AmazonPayfortReducer from '../../store/AmazonPayfort/AmazonPayfort.reducer';
import {
    cancelPayfortOrder,
    formatExpiryDateForRequest,
    payfortFailEventDispatcher
} from '../../util/Payfort.util';
import MerchantPage from './MerchantPage.component';

export const CartDispatcher = import(
    /* webpackMode: "eager"*/
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/AmazonPayfort/Component/MerchantPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    paymentCardIntegrationType: state.ConfigReducer.payfortConfig.apsFortCC.integrationType,
    installmentIntegrationType: state.ConfigReducer.payfortConfig.apsInstallment.integrationType,
    merchantPageData: state.AmazonPayfortReducer.merchantPageData,
    merchantPageDispatched: state.AmazonPayfortReducer.merchantPageDispatched
});

/** @namespace Scandiweb/AmazonPayfort/Component/MerchantPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    createGuestEmptyCart: () => CartDispatcher.createGuestEmptyCart(dispatch),
    updateInitialCartData: () => CartDispatcher.updateInitialCartData(dispatch),
    dispatchMerchantPage: (merchantPageDispatched) => dispatch(updateAmazonPayfortStore({ merchantPageDispatched }))
});

/** @namespace Scandiweb/AmazonPayfort/Component/MerchantPage/Container */
export class MerchantPageContainer extends PureComponent {
    static propTypes = {
        updateInitialCartData: PropTypes.func.isRequired,
        createGuestEmptyCart: PropTypes.func.isRequired,
        paymentCardIntegrationType: PropTypes.string.isRequired,
        installmentIntegrationType: PropTypes.string.isRequired,
        setLoading: PropTypes.func.isRequired
    };

    formRef = null;

    // vvv Can't access prevContext in componentDidUpdate so using this :p
    prevMerchantPageData = {};

    containerFunctions = {
        setLoadingToFalse: this.setLoadingToFalse.bind(this),
        handleCancel: this.handleCancel.bind(this)
    };

    // eslint-disable-next-line react/sort-comp
    __construct(props) {
        super.__construct(props);

        this.formRef = createRef();
        this.tokenFormRef = createRef();
        this.state = {
            isLoading: true,
            formData: {},
            formUrl: null,
            merchantPageEvent: null,
            isNewToken: false
        };
    }

    setLoadingToFalse() {
        this.setState({ isLoading: false });
    }

    async handleCancel() {
        const {
            setLoading,
            merchantPageData: {
                orderId
            } = {}
        } = this.context;
        const { merchantPageEvent } = this.state;

        if (merchantPageEvent !== PayfortMerchantPageEvents.PAYMENT) {
            return;
        }

        await cancelPayfortOrder(orderId);

        if (setLoading) {
            setLoading(false);
        }
    }

    componentDidUpdate() {
        const {
            merchantPageDispatched,
            dispatchMerchantPage,
            merchantPageData: {
                event
            } = {}
        } = this.props;

        if (!merchantPageDispatched) {
            return;
        }

        dispatchMerchantPage(false);

        switch (event) {
        case PayfortMerchantPageEvents.PAYMENT:
            this.dispatchPayment();
            break;

        case PayfortMerchantPageEvents.NEW_TOKEN:
            this.dispatchNewToken();
            break;

        default:
            break;
        }
    }

    dispatchPayment() {
        const {
            merchantPageData,
            merchantPageData: {
                orderId
            }
        } = this.props;

        const {
            orderId: prevOrderId
        } = this.prevMerchantPageData;

        if (prevOrderId === orderId) {
            return;
        }

        this.prevMerchantPageData = merchantPageData;

        this.setState({
            merchantPageEvent: PayfortMerchantPageEvents.PAYMENT
        });

        try {
            this.submitForm(this.getPaymentFormData());
        } catch (e) {
            payfortFailEventDispatcher(e);
        }
    }

    getPaymentFormData() {
        const {
            paymentCardIntegrationType,
            installmentIntegrationType
        } = this.props;

        const {
            merchantPageData: {
                paymentMethod,
                formData: apsFormData,
                formUrl,
                token,
                payfortVaultData: {
                    params: apsSavedCardData,
                    url
                } = {},
                paymentFields: {
                    card_number,
                    card_holder_name,
                    card_security_code,
                    expiry_date
                } = {}
            } = {}
        } = this.props;

        switch (paymentMethod) {
        case PayfortPaymentMethods.FORT_CC:
            if (token) {
                return {
                    formData: apsSavedCardData,
                    formUrl: url
                };
            }

            if (paymentCardIntegrationType !== PayfortIntegrationTypes.HOSTED) {
                return {
                    formData: apsFormData,
                    formUrl
                };
            }

            return {
                formData: {
                    ...apsFormData,
                    card_holder_name,
                    card_number: card_number.replace(/[^\d]/g, ''),
                    expiry_date: formatExpiryDateForRequest(expiry_date),
                    card_security_code
                },
                formUrl
            };

        case PayfortPaymentMethods.INSTALLMENT:
            if (installmentIntegrationType !== PayfortIntegrationTypes.HOSTED) {
                return {
                    formData: apsFormData,
                    formUrl
                };
            }

            // hosted checkout for installment
            return {
                formData: {
                    ...apsFormData,
                    card_holder_name,
                    card_number: card_number.replace(/[^\d]/g, ''),
                    expiry_date: formatExpiryDateForRequest(expiry_date),
                    card_security_code
                },
                formUrl
            };

        case PayfortPaymentMethods.TABBY:
            return {
                formData: apsFormData,
                formUrl
            };

        default:
            throw new Error('Unknown payment method');
        }
    }

    dispatchNewToken() {
        const {
            merchantPageData: {
                newTokenData
            } = {}
        } = this.props;

        this.setState({
            merchantPageEvent: PayfortMerchantPageEvents.NEW_TOKEN
        });
        this.submitForm(newTokenData, true);
    }

    submitForm({ formData, formUrl }, isNewToken = false) {
        this.setState({
            formData,
            formUrl,
            isLoading: true
        });

        const IosPageAnimationTimeMs = 500;

        window.requestAnimationFrame(() => {
            if (isNewToken) {
                window.open('', PAYFORT_NEW_TOKEN_POPUP_WINDOW, 'popup, top=0, left=0, width=100, height=100');
                this.formRef.current.target = PAYFORT_NEW_TOKEN_POPUP_WINDOW;
            }
            setTimeout(() => {
                this.formRef.current.submit();
                scrollToTop();
            }, IosPageAnimationTimeMs);
        });
    }

    containerProps() {
        const { formRef } = this;
        const {
            formData,
            formUrl,
            isLoading
        } = this.state;
        const { merchantPageData: { event } } = this.props;

        return {
            formRef,
            formData,
            formUrl,
            isLoading,
            event
        };
    }

    render() {
        return (
            <MerchantPage
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    AmazonPayfortReducer
})(connect(mapStateToProps, mapDispatchToProps)(MerchantPageContainer));
