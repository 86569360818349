import { DEFAULT_SELECTED_MENU } from 'Component/Menu/Menu.config';
import MenuHelper from 'Util/Menu';

import { SET_MENU_STATUS, SET_SELECTED_MENU, UPDATE_MENU_ITEMS } from './Menu.action';

/** @namespace Scandipwa/Store/Menu/Reducer/updateMenuItems */
export const updateMenuItems = (action, state) => {
    const { menus } = action;

    // const result = menus.filter((menu) => menu.menu_type === state.selectedMenu)[0];
    const reducedMenu = MenuHelper.reduce(menus[0]);

    return { ...state, menu: reducedMenu };
};

/** @namespace Scandipwa/Store/Menu/Reducer/getInitialState */
export const getInitialState = () => ({
    menu: {},
    isMenuOpen: false,
    isMenuAnimated: true,
    selectedMenu: DEFAULT_SELECTED_MENU
});

/** @namespace Scandipwa/Store/Menu/Reducer/MenuReducer */
export const MenuReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_MENU_ITEMS:
        return updateMenuItems(action, state);
    case SET_MENU_STATUS:
        return { ...state, isMenuOpen: action.status, isMenuAnimated: action.animated };
    case SET_SELECTED_MENU:
        return { ...state, selectedMenu: action.selectedMenu };
    default:
        return state;
    }
};

export default MenuReducer;
