/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CART_OVERLAY } from 'Component/Header/Header.config';
import {
    DESKTOP,
    MOBILE
} from 'Util/Device';

export const CLERK_POWERSTEP = 'popup';
export const CLERK_MINICART = 'minicart';
export const CLERK_HOMEPAGE = 'homepage';
export const CLERK_THANKYOU = 'thankyou';
export const CLERK_PRODUCTS_NUMBER = 4;
export const CLERK_PRODUCTS_POWERSTEP = 3;
export const CLERK_PRODUCTS_YMAL = 3;
export const CLERK_DATA = 'ClerkData';
export const CLERK_PRODUCT_IDS = 'ClerkProductIds';
export const CLERK_LAST_DAY = 'ClerkLastDay';
export const CLERK_LAST_CATEGORY = 'ClerkLastCategory';
export const CLERK_LAST_PRODUCT = 'ClerkLastProduct';
export const TOTAL_PRODUCT_CARD_AND_MARGIN_WIDTH_IN_PX = {
    [DESKTOP]: 326,
    [MOBILE]: 212
};
export const PRODUCT_CARD_MARGIN_IN_PX = {
    [DESKTOP]: 24,
    [MOBILE]: 12
};
export const PLACEHOLDERS_COUNT = {
    [DESKTOP]: 4,
    [MOBILE]: 2
};
export const TOTAL_CARD_PER_SLIDE = {
    [DESKTOP]: 4,
    [MOBILE]: 1,
    [CART_OVERLAY]: 2
};
export const SLIDES_MAP = {
    mobile: 1,
    desktop: 4
};
