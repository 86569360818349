import { PureComponent } from 'react';

import './CheckmarkIcon.style';

/** @namespace Scandipwa/Component/CheckmarkIcon/Component */
export class CheckmarkIconComponent extends PureComponent {
    /*
     * New method to render checkmark icon
     */
    /* Disabled max len to render in line svg */
    /* eslint-disable max-len */
    render() {
        return (
            <svg block="CheckmarkIcon" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.2506 0.768799L19.1242 2.64236L6.88435 14.8822L0.894531 8.89237L2.7681 7.0188L6.88435 11.135L17.2506 0.768799Z" />
            </svg>
        );
    }
    /* eslint-disable max-len */
}

export default CheckmarkIconComponent;
