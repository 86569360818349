import { PICKUP_STORE_KEY } from 'Component/Pickup/Pickup.config';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import {
    SELECT_PICKUP_STORE,
    SELECT_PICKUP_STORE_IN_CHECKOUT,
    SET_PICKUP_STORES
} from './ClickAndCollect.action';

export const initialState = {
    pickupStores: null,
    selectedPickupStore: BrowserDatabase.getItem(PICKUP_STORE_KEY),
    selectedPickupStoreInCheckout: BrowserDatabase.getItem(PICKUP_STORE_KEY)
};

/** @namespace Scandipwa/Store/ClickAndCollect/Reducer/ClickAndCollectReducer */
export const ClickAndCollectReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_PICKUP_STORES:
        return {
            ...state,
            pickupStores: action.pickupStores
        };
    case SELECT_PICKUP_STORE:
        return {
            ...state,
            selectedPickupStore: action.selectedPickupStore
        };
    case SELECT_PICKUP_STORE_IN_CHECKOUT:
        return {
            ...state,
            selectedPickupStoreInCheckout: action.selectedPickupStoreInCheckout
        };
    default:
        return state;
    }
};

export default ClickAndCollectReducer;
