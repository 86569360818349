/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Link from 'Component/Link';
import Loader from 'Component/Loader';

import { PayfortIntegrationTypes } from '../../Payfort.config';
import {
    PayfortBankType,
    PayfortCountryType,
    PayfortPlanType
} from '../../type/Payfort.type';
import { getOptions } from '../../util/Payfort.util';
import InstallmentIcon from '../InstallmentIcon';
import PaymentCard from '../PaymentCard';

import './InstallmentPaymentMethod.style';

/** @namespace Scandiweb/AmazonPayfort/Component/InstallmentPaymentMethod/Component */
export class InstallmentPaymentMethodComponent extends PureComponent {
    static propTypes = {
        originalRender: PropTypes.element.isRequired,
        countries: PropTypes.arrayOf(PayfortCountryType).isRequired,
        banks: PropTypes.arrayOf(PayfortBankType).isRequired,
        plans: PropTypes.arrayOf(PayfortPlanType).isRequired,
        integrationType: PropTypes.string.isRequired,
        selectedBankTermsUrl: PropTypes.string.isRequired,
        isPlansFetchSuccessful: PropTypes.bool.isRequired,
        isSelected: PropTypes.bool.isRequired,
        isEmpty: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isCardApplicableWithSelectedBank: PropTypes.bool.isRequired,
        changeSelectedCountry: PropTypes.func.isRequired,
        changeSelectedBank: PropTypes.func.isRequired,
        changeSelectedPlan: PropTypes.func.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired,
        validateCardNumber: PropTypes.func.isRequired
    };

    renderMethodHeading() {
        const { originalRender } = this.props;

        return (
            <div block="InstallmentPaymentMethod" elem="Heading">
                { originalRender }
                <InstallmentIcon />
            </div>
        );
    }

    renderCountryField() {
        const { countries, changeSelectedCountry } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.select }
              options={ getOptions({ data: countries, valueKey: 'code' }) }
              label={ __('Select Country') }
              attr={ {
                  name: 'payfortInstallmentCountry'
              } }
              mix={ {
                  block: 'InstallmentPaymentMethod',
                  elem: 'CountryField'
              } }
              events={ {
                  onChange: changeSelectedCountry
              } }
              validationRule={ {
                  isRequired: true
              } }
              addRequiredTag
            />
        );
    }

    renderBankField() {
        const { banks, changeSelectedBank } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.select }
              label={ __('Select Bank') }
              options={ getOptions({
                  data: banks,
                  valueKey: 'issuer_code',
                  labelKey: 'issuer_name',
                  placeholderLabel: __('Select bank...')
              }) }
              mix={ {
                  block: 'InstallmentPaymentMethod',
                  elem: 'BankField'
              } }
              attr={ {
                  name: 'issuer_code'
              } }
              isDisabled={ !banks.length }
              events={ {
                  onChange: changeSelectedBank
              } }
              validationRule={ {
                  isRequired: true
              } }
              addRequiredTag
            />
        );
    }

    renderPlanField() {
        const { plans, changeSelectedPlan } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.select }
              label={ __('Select Plan') }
              options={ getOptions({
                  data: plans,
                  valueKey: 'plan_code',
                  labelKey: this.renderPlanLabel.bind(this),
                  placeholderLabel: __('Select plan...')
              }) }
              mix={ {
                  block: 'InstallmentPaymentMethod',
                  elem: 'PlanField'
              } }
              attr={ {
                  name: 'plan_code'
              } }
              events={ {
                  onChange: changeSelectedPlan
              } }
              isDisabled={ !plans.length }
              validationRule={ {
                  isRequired: true
              } }
              addRequiredTag
            />
        );
    }

    renderPlanLabel({
        number_of_installment,
        interest,
        interest_info,
        amountPerMonth,
        currency_code
    }) {
        return (
            <>
                <span>
                    { __(
                        '%s month (%s %s interest rate)',
                        number_of_installment,
                        interest,
                        interest_info
                    ) }
                </span>
                <br />
                <span
                  block="InstallmentPaymentMethod"
                  elem="PlanOptionSublabel"
                >
                    { __('%s %s per month', amountPerMonth, currency_code) }
                </span>
            </>
        );
    }

    renderTermsLabel() {
        const { selectedBankTermsUrl = '#' } = this.props;

        return (
            <div block="InstallmentPaymentMethod" elem="TermsCheckbox">
                <span>{ __("I accept the bank's ") }</span>
                <Link
                  block="InstallmentPaymentMethod"
                  elem="TermsUrl"
                  to={ selectedBankTermsUrl }
                  isOpenInNewTab
                >
                    { __('Terms & Conditions') }
                </Link>
            </div>
        );
    }

    renderMethodBody() {
        const {
            isPlansFetchSuccessful,
            isEmpty,
            setOrderButtonEnableStatus,
            isLoading,
            validateCardNumber,
            isCardApplicableWithSelectedBank
        } = this.props;

        if (isLoading) {
            return (
                <p block="InstallmentPaymentMethod" elem="Paragraph">
                    <Loader isLoading={ isLoading } />
                    { __('Loading...') }
                </p>
            );
        }

        if (!isPlansFetchSuccessful) {
            return (
                <p block="InstallmentPaymentMethod" elem="Paragraph">
                    { __(
                        'An error occurred while getting installment plans. Please try again later.'
                    ) }
                </p>
            );
        }

        if (isEmpty) {
            return (
                <p block="InstallmentPaymentMethod" elem="Paragraph">
                    { __(
                        'There are no installment plans available for this order.'
                    ) }
                </p>
            );
        }

        setOrderButtonEnableStatus(true);

        return (
            <>
                <div
                  block="InstallmentPaymentMethod"
                  elem="PlanFieldsContainer"
                >
                    { this.renderCountryField() }
                    { this.renderBankField() }
                    { this.renderPlanField() }
                </div>
                <PaymentCard
                  cardNumberValidator={ validateCardNumber }
                  cardNumberFailMessage={ !isCardApplicableWithSelectedBank
                      ? __(
                          'This card is not applicable for the selected bank'
                      )
                      : __('Invalid card number') }
                />
            </>
        );
    }

    render() {
        const { isSelected, integrationType } = this.props;

        if (!isSelected || integrationType !== PayfortIntegrationTypes.HOSTED) {
            return this.renderMethodHeading();
        }

        return (
            <>
                { this.renderMethodHeading() }
                <div block="InstallmentPaymentMethod" elem="Body">
                    { this.renderMethodBody() }
                </div>
            </>
        );
    }
}

export default InstallmentPaymentMethodComponent;
