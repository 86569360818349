Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/amasty-gift-cards/src/plugin/ThankyouPage.plugin.js')]);
/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CART_TOTALS } from '@scandipwa/scandipwa/src/store/Cart/Cart.reducer';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';
import { connect } from 'react-redux';

import { ONE_HOUR_IN_SECONDS } from 'Util/Date';
import { withReducers } from 'Util/DynamicReducer';

import {
    PayfortMerchantPageEvents,
    PayfortPaymentMethods
} from '../../Payfort.config';
import AmazonPayfortReducer from '../../store/AmazonPayfort/AmazonPayfort.reducer';
import { cancelPayfortOrder } from '../../util/Payfort.util';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MerchantPageContainer
} from '../MerchantPage/MerchantPage.container';
import RedirectionPageComponent from './RedirectionPage.component';

/** @namespace Scandiweb/AmazonPayfort/Component/RedirectionPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    merchantPageData: state.AmazonPayfortReducer.merchantPageData
});

/** @namespace Scandiweb/AmazonPayfort/Component/RedirectionPage/Container */
export class RedirectionPageContainer extends MerchantPageContainer {
    // vvv saving cart for some time because guest user order is being instantly cleared in localstorage which returns some errors
    setCachedCart() {
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS);
        BrowserDatabase.setItem(cartTotals, 'tabbyOrderCartData', ONE_HOUR_IN_SECONDS);
    }

    containerFunctions = {
        ...super.containerFunctions,
        handleCancel: this.handleCancel.bind(this)
    };

    async handleCancel() {
        const {
            setLoading,
            merchantPageData: {
                orderId
            } = {}
        } = this.props;
        const { merchantPageEvent } = this.state;

        if (merchantPageEvent !== PayfortMerchantPageEvents.PAYMENT) {
            return;
        }

        await cancelPayfortOrder(orderId);
        BrowserDatabase.deleteItem('tabbyOrderCartData');
        setLoading(false);
    }

    // vvv added Tabby
    getPaymentFormData() {
        const {
            merchantPageData: {
                paymentMethod,
                formData: apsFormData,
                formUrl
            } = {}
        } = this.props;

        if (paymentMethod !== PayfortPaymentMethods.TABBY) {
            return super.getPaymentFormData();
        }

        return {
            formData: apsFormData,
            formUrl
        };
    }

    submitForm({ formData, formUrl }) {
        this.setState({
            formData,
            formUrl,
            isLoading: true
        });

        this.setCachedCart();

        window.requestAnimationFrame(() => {
            this.formRef.current.submit();
        });
    }

    render() {
        return (
            <RedirectionPageComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    AmazonPayfortReducer
})(connect(mapStateToProps, mapDispatchToProps)(RedirectionPageContainer));
