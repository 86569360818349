import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import {
    CheckoutDeliveryOptionsContainer as SourceCheckoutDeliveryOptionsContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.container';
import { updateSelectedSlot } from 'Store/CheckoutDelivery/CheckoutDelivery.action';
import CheckoutDeliveryReducer from 'Store/CheckoutDelivery/CheckoutDelivery.reducer';
import { StoreType } from 'Type/Checkout.type';
import { withReducers } from 'Util/DynamicReducer';
import {
    areSlotsEnabledForMethod
} from 'Util/OfflineShipping';

/** @namespace Scandipwa/Component/CheckoutDeliveryOptions/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile,
    selectedSlot: state.CheckoutDeliveryReducer.selectedSlot,
    selectedPickupStore: state?.ClickAndCollectReducer?.selectedPickupStore
});

/** @namespace Scandipwa/Component/CheckoutDeliveryOptions/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    onSlotSelect: (selectedSlot) => dispatch(updateSelectedSlot(selectedSlot))
});

/** @namespace Scandipwa/Component/CheckoutDeliveryOptions/Container */
export class CheckoutDeliveryOptionsContainer extends SourceCheckoutDeliveryOptionsContainer {
    static propTypes = {
        ...this.propTypes,
        resetDelivery: PropTypes.func.isRequired,
        renderOrderTotal: PropTypes.func.isRequired,
        onSlotSelect: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
        isValid: PropTypes.bool,
        isMobile: PropTypes.bool.isRequired,
        setLoading: PropTypes.func.isRequired,
        isShowButtonsOnly: PropTypes.bool,
        isShowPaymentButtonOnly: PropTypes.bool,
        isDeliveryFieldsValidated: PropTypes.bool,
        selectedCustomerAddressId: PropTypes.number,
        selectedPickupStore: StoreType
    };

    static defaultProps = {
        ...this.defaultProps,
        isDisabled: false,
        isValid: false,
        isShowButtonsOnly: false,
        isShowPaymentButtonOnly: false,
        isDeliveryFieldsValidated: false,
        selectedCustomerAddressId: 0
    };

    /**
     * Overridden to remove separate option selection handling for pickup order
     */
    selectShippingMethod(shippingMethod) {
        const {
            onShippingMethodSelect,
            selectedShippingMethod: {
                carrier_code
            } = {},
            onSlotSelect
        } = this.props;

        const {
            carrier_code: newCarrierCode
        } = shippingMethod;

        if (carrier_code !== newCarrierCode) {
            // vvv deselect slot every time user changes shipping method
            onSlotSelect(null);
            onShippingMethodSelect(shippingMethod);
        }
    }

    getIsSelectedMethodValid() {
        const {
            selectedShippingMethod,
            selectedShippingMethod: {
                carrier_code,
                method_code
            } = {},
            selectedSlot,
            selectedPickupStore
        } = this.props;

        // Check if shipping method is selected
        if (typeof selectedShippingMethod === 'string' || !Object.keys(selectedShippingMethod).length) {
            return false;
        }

        if (method_code === STORE_IN_PICK_UP_METHOD_CODE && !selectedPickupStore) {
            return false;
        }

        // For methods with delivery slots, we need to check if slot is selected
        if (areSlotsEnabledForMethod(carrier_code)) {
            return selectedSlot !== null;
        }

        return true;
    }

    getIsDeliveryFieldsValidated() {
        const { selectedCustomerAddressId, isDeliveryFieldsValidated } = this.props;

        return (selectedCustomerAddressId && this.getIsSelectedMethodValid()) || isDeliveryFieldsValidated;
    }

    containerProps() {
        const {
            isDisabled,
            isValid,
            resetDelivery,
            renderOrderTotal,
            onSlotSelect,
            isMobile,
            setLoading,
            isShowButtonsOnly,
            isShowPaymentButtonOnly
        } = this.props;

        return {
            ...super.containerProps(),
            isDisabled,
            isValid,
            resetDelivery,
            renderOrderTotal,
            onSlotSelect,
            isMobile,
            isSelectedMethodValid: this.getIsSelectedMethodValid(),
            setLoading,
            isShowButtonsOnly,
            isShowPaymentButtonOnly,
            isDeliveryFieldsValidated: this.getIsDeliveryFieldsValidated()
        };
    }
}

export default withReducers({
    CheckoutDeliveryReducer
})(connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryOptionsContainer));
