/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_AMASTY_AUTOMATIC_RELATED_PRODUCTS } from './AmastyAutomaticRelatedProducts.config';

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Store/AmastyAutomaticRelatedProducts/Reducer/getInitialState */
export const getInitialState = () => ({
    bundlePacks: [],
    bundlePackMainId: 0,
    relatedProducts: [],
    relatedMainId: 0,
    relatedCache: {},
    packList: [],
    packListTotalPages: 0,
    previousLoginState: null,
    cachedItems: {}
});

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Store/AmastyAutomaticRelatedProducts/Reducer/AmastyAutomaticRelatedProductsReducer */
export const AmastyAutomaticRelatedProductsReducer = (state = getInitialState(), action) => {
    const { type, state: newState } = action;

    if (type === UPDATE_AMASTY_AUTOMATIC_RELATED_PRODUCTS) {
        return {
            ...state,
            ...newState
        };
    }

    return state;
};

export default AmastyAutomaticRelatedProductsReducer;
