import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import transformToNameValuePair from 'Util/Form/Transform';

import ResetPasswordPopup from './ResetPasswordPopup.component';
import { RESET_PASSWORD_POPUP_ID } from './ResetPasswordPopup.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "eager"*/
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/ResetPasswordPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer?.popupPayload[RESET_PASSWORD_POPUP_ID],
    isMobile: state.ConfigReducer?.device?.isMobile
});

/** @namespace Scandipwa/Component/ResetPasswordPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    forgotPassword: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.forgotPassword(options, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandipwa/Component/ResetPasswordPopup/Container */
export class ResetPasswordPopupContainer extends PureComponent {
    static propTypes = {
        forgotPassword: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            title: PropTypes.string.isRequired,
            customerEmail: PropTypes.string.isRequired
        }),
        isMobile: PropTypes.bool
    };

    static defaultProps = {
        payload: {},
        isMobile: true
    };

    containerFunctions = {
        handleResetPasswordFormValidationSuccess: this.handleResetPasswordFormValidationSuccess.bind(this),
        handleResetPasswordFormValidationError: this.handleResetPasswordFormValidationError.bind(this),
        handleCustomerEmailChange: this.handleCustomerEmailChange.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            isLoading: false,
            customerEmail: '',
            isCurrentCustomerEmailSet: false,
            isCustomerEmailFieldChanged: false
        };
    }

    containerProps() {
        const { isMobile } = this.props;
        const { isLoading } = this.state;

        return {
            isLoading,
            customerEmail: this.getCustomerEmailFieldValue(),
            isMobile
        };
    }

    /**
     * New method to set loading state
     */
    setLoadingState(isLoading) {
        this.setState({ isLoading });
    }

    /**
     * New method to handle reset password form validation success
     */
    async handleResetPasswordFormValidationSuccess(form, fields) {
        const {
            forgotPassword,
            showNotification
        } = this.props;
        const { value: submittedEmail } = form[0];

        this.setLoadingState(true);

        try {
            await forgotPassword(transformToNameValuePair(fields));

            const message = __(
                // eslint-disable-next-line max-len
                'If there is an account associated with %s you will receive an email with a link to reset your password',
                submittedEmail
            ).toString();

            showNotification('success', message);
        } finally {
            this.setLoadingState(false);
        }
    }

    /**
     * New method to handle reset password form validation error
     */
    handleResetPasswordFormValidationError() {
        this.setLoadingState(false);
    }

    /**
     * New method to handle customer email change
     */
    handleCustomerEmailChange(event) {
        const { isCustomerEmailFieldChanged } = this.state;
        const { target: { value } } = event;

        this.setState({ customerEmail: value });

        if (!isCustomerEmailFieldChanged) {
            this.setState({ isCustomerEmailFieldChanged: true });
        }
    }

    /**
     * New method to get customer email field value
     */
    getCustomerEmailFieldValue() {
        const {
            payload: {
                customerEmail: customerEmailFromPopupPayload
            }
        } = this.props;
        const {
            isCurrentCustomerEmailSet,
            customerEmail,
            isCustomerEmailFieldChanged
        } = this.state;

        if (
            !isCurrentCustomerEmailSet
            && !customerEmail
            && customerEmailFromPopupPayload
        ) {
            // If customerEmailFromPopupPayload hasn't been set on email field value
            this.setState({ isCurrentCustomerEmailSet: true });

            return customerEmailFromPopupPayload;
        }

        if (isCustomerEmailFieldChanged) {
            return customerEmail;
        }

        return customerEmailFromPopupPayload;
    }

    render() {
        return (
            <ResetPasswordPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPopupContainer);
