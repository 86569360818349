/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy, Suspense } from 'react';

import Loader from 'Component/Loader';
import getStore from 'Util/Store';

import ApplePayExpress from '../../component/ApplePayExpress';
import InstallmentPaymentMethod from '../../component/InstallmentPaymentMethod';
import RedirectionPage from '../../component/RedirectionPage';
import { PayfortPaymentMethods } from '../../Payfort.config';

const MerchantPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "payfort-payments" */
    '../../component/MerchantPage'
));

const CardPaymentMethod = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "payfort-payments" */
    '../../component/CardPaymentMethod'
));

const ApplePay = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "payfort-payments" */
    '../../component/ApplePay'
));

const PayfortPayments = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "payfort-payments" */
    '../../component/PayfortPayments'
));

const ApplePayMark = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "payfort-payments" */
    '../../component/ApplePayMark'
));

const renderMerchantPage = (isSelected, code, setLoading) => {
    if (!isSelected) {
        return null;
    }

    return (
        <Suspense fallback={ <Loader isLoading /> }>
            <PayfortPayments code={ code } />
            <MerchantPage setLoading={ setLoading } />
        </Suspense>
    );
};

const renderRedirectionPage = (isSelected, code, setLoading) => {
    if (!isSelected) {
        return null;
    }

    return (
        <Suspense fallback={ <Loader isLoading /> }>
            <PayfortPayments code={ code } />
            <RedirectionPage setLoading={ setLoading } />
        </Suspense>
    );
};

const getIsAppleMethodApplicable = (instance) => {
    const {
        payfortConfig: {
            apsApple: {
                appleSupportedNetwork,
                appleProductButton
            } = {}
        }
    } = instance.props;

    return appleProductButton && appleSupportedNetwork && window.ApplePaySession;
};

const getIsApplePayExpressApplicable = (instance) => {
    const {
        payfortConfig: {
            apsApple: {
                appleSupportedNetwork,
                appleCartButton
            } = {}
        }
    } = instance.props;

    return appleCartButton && appleSupportedNetwork && window.ApplePaySession;
};

const renderApplePay = (args, callback) => {
    const { selectedPaymentMethod } = getStore().getState().AmazonPayfortReducer;

    if (selectedPaymentMethod !== PayfortPaymentMethods.APPLE) {
        return callback(...args);
    }

    const originalRender = callback(...args);

    return (
        <Suspense fallback={ <Loader isLoading /> }>
            <ApplePay originalRender={ originalRender } />
        </Suspense>
    );
};

const addPayfortExpressPayments = (originalMember) => ({
    ...originalMember,
    [PayfortPaymentMethods.APPLE_EXPRESS]: {
        render: () => <ApplePayExpress />,
        getIsApplicable: getIsApplePayExpressApplicable,
        positon: 100
    }
});

const addCardPaymentMethod = (originalRender, instance) => {
    const {
        isSelected,
        setOrderButtonEnableStatus,
        setLoading
    } = instance.props;

    return (
        <>
            <Suspense fallback={ null }>
                <CardPaymentMethod
                  isSelected={ isSelected }
                  originalRender={ originalRender }
                  setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
                />
            </Suspense>
            { renderMerchantPage(
                isSelected,
                PayfortPaymentMethods.FORT_CC,
                setLoading
            ) }
        </>
    );
};

const addTabbyPaymentMethod = (originalRender, instance) => {
    const {
        isSelected,
        setLoading
    } = instance.props;

    return (
        <>
            { originalRender }
            { renderRedirectionPage(
                isSelected,
                PayfortPaymentMethods.TABBY,
                setLoading
            ) }
        </>
    );
};

const addInstallmentPaymentMethod = (originalRender, instance) => {
    const {
        isSelected,
        setOrderButtonEnableStatus,
        setLoading
    } = instance.props;

    return (
        <>
            <InstallmentPaymentMethod
              isSelected={ isSelected }
              originalRender={ originalRender }
              setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
            />
            { renderMerchantPage(
                isSelected,
                PayfortPaymentMethods.INSTALLMENT,
                setLoading
            ) }
        </>
    );
};

const renderApplePayMark = (originalRender) => (
    <>
        { originalRender }
        <Suspense fallback={ null }>
            <ApplePayMark />
        </Suspense>
    </>
);

const addPayfortPaymentFields = (args, callback, instance) => {
    const {
        method: { code }
    } = instance.props;
    const originalRender = callback(...args);

    switch (code) {
    case PayfortPaymentMethods.TABBY:
        return addTabbyPaymentMethod(originalRender, instance);

    case PayfortPaymentMethods.FORT_CC:
        return addCardPaymentMethod(originalRender, instance);

    case PayfortPaymentMethods.INSTALLMENT:
        return addInstallmentPaymentMethod(originalRender, instance);

    case PayfortPaymentMethods.APPLE:
        return renderApplePayMark(originalRender);

    default:
        return originalRender;
    }
};

const addPayfortConfigToProps = ([state], callback) => ({
    ...callback(state),
    payfortConfig: state.ConfigReducer.payfortConfig
});

/**
 * New method to:
 * - Remove Apple Pay from payment methods if not available
 * - Pass setLoading
 */
const configureCheckoutPaymentsContainerContainerProps = (args, callback, instance) => {
    const containerProps = callback(...args);

    const { paymentMethods } = containerProps;

    const removePayfortVault = paymentMethods.filter(({ code }) => code !== PayfortPaymentMethods.VAULT);

    const {
        setLoading
    } = instance.props;

    if (getIsAppleMethodApplicable(instance)) {
        return {
            ...containerProps,
            paymentMethods: removePayfortVault,
            setLoading
        };
    }

    return {
        ...containerProps,
        // remove out Apple pay if not applicable
        paymentMethods: removePayfortVault.filter(({ code }) => code !== PayfortPaymentMethods.APPLE),
        setLoading
    };
};

export default {
    'Scandipwa/Component/ExpressCheckoutMethodsList/Container': {
        'member-property': {
            expressPaymentRenderMap: addPayfortExpressPayments
        }
    },
    'Scandipwa/Component/ExpressCheckoutMethodsList/Container/mapStateToProps': {
        function: addPayfortConfigToProps
    },
    'Component/CheckoutPayments/Container/mapStateToProps': {
        function: addPayfortConfigToProps
    },
    'Component/CheckoutPayments/Container': {
        'member-function': {
            containerProps: configureCheckoutPaymentsContainerContainerProps
        }
    },
    'Component/CheckoutPayment/Component': {
        'member-function': {
            renderPaymentFields: addPayfortPaymentFields
        }
    },
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderActions: renderApplePay
        }
    }
};
