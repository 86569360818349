/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { TotalsType } from 'Type/MiniCart.type';
import { getCartId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

import {
    PayfortAppleVersions,
    RequiredShippingContactFields
} from '../../Payfort.config';
import PayfortAppleValidateAddressQuery from '../../query/PayfortAppleValidateAddress.query';
import PayfortSendAppleCartDataToApsQuery from '../../query/PayfortSendAppleCartDataToAps.query';
import { PayfortConfigType } from '../../type/Payfort.type';
import {
    appleLineItem,
    applePayEventDispatcher,
    appleShippingContactToMagentoAddress,
    getLineItem,
    setPayfortOrderFinished
} from '../../util/Payfort.util';
import { ApplePayExpressComponent } from './ApplePayExpress.component';

/** @namespace Scandiweb/AmazonPayfort/Component/ApplePayExpress/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payfortConfig: state.ConfigReducer.payfortConfig,
    totals: state.CartReducer.cartTotals
});

/** @namespace Scandiweb/AmazonPayfort/Component/ApplePayExpress/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandiweb/AmazonPayfort/Component/ApplePayExpress/Container */
export class ApplePayExpressContainer extends PureComponent {
    static propTypes = {
        payfortConfig: PayfortConfigType.isRequired,
        totals: TotalsType.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    containerFunctions = {
        placeOrder: this.placeOrder.bind(this)
    };

    placeOrder() {
        const {
            totals: {
                prices: {
                    quote_currency_code,
                    grand_total: {
                        value: grand_total
                    }
                }
            },
            payfortConfig: {
                apsApple: {
                    storeCountryCode,
                    storeName,
                    appleSupportedNetwork,
                    appleValidation
                }
            }
        } = this.props;

        const supportedNetworks = appleSupportedNetwork.split(',');

        const paymentRequest = {
            currencyCode: quote_currency_code,
            countryCode: storeCountryCode,
            requiredShippingContactFields: RequiredShippingContactFields,
            lineItems: this.getLineItems(),
            total: {
                label: storeName,
                amount: grand_total
            },
            supportedNetworks,
            merchantCapabilities: ['supports3DS']
        };

        // vvv Disabling eslint, because its Safari API
        const session = supportedNetworks.indexOf('mada') >= 0
        // eslint-disable-next-line no-undef
            ? new ApplePaySession(PayfortAppleVersions.FIVE, paymentRequest)
        // eslint-disable-next-line no-undef
            : new ApplePaySession(PayfortAppleVersions.THREE, paymentRequest);

        // vvv Aborts the ApplePay session, closes the ApplePay popup, throws error on checkout
        const handleAbort = () => {
            session.abort();
            applePayEventDispatcher({ error: true });
        };

        session.onvalidatemerchant = async (event) => {
            try {
                const merchantSession = await this.performValidation(event.validationURL, appleValidation);

                session.completeMerchantValidation(merchantSession);
            } catch (validationError) {
                handleAbort();
            }
        };

        session.onshippingcontactselected = async (event) => {
            try {
                const {
                    totals,
                    totals: {
                        total
                    }
                } = await this.validationShippingAddress(event.shippingContact);

                // eslint-disable-next-line no-undef
                const status = ApplePaySession.STATUS_SUCCESS;

                const updatedLineItems = this.makeLineItems(totals);

                const finalTotal = {
                    label: storeName,
                    amount: total
                };

                session.completeShippingContactSelection(
                    status,
                    [], // <- There can be provided list of selectable shipping methods, currently client doesnt need
                    finalTotal,
                    updatedLineItems
                );
            } catch (error) {
                const { message } = error || {};

                // eslint-disable-next-line no-undef
                const zipAppleError = new ApplePayError(
                    'shippingContactInvalid',
                    'postalCode',
                    message || 'Invalid Address'
                );

                session.completeShippingContactSelection({
                    newShippingMethods: [],
                    newTotal: { label: 'error', amount: '1', type: 'pending' },
                    newLineItems: [],
                    errors: [zipAppleError]
                });
            }
        };

        session.onpaymentauthorized = async (event) => {
            try {
                await this.sendPaymentToAps(event.payment);

                // vvv Disabling eslint, because its Safari API
                // eslint-disable-next-line no-undef
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
            } catch (validationErr) {
                handleAbort();
            }
        };

        session.oncancel = () => {
            applePayEventDispatcher({ cancel: true });
        };

        session.begin();
    }

    getLineItems(overrideFees) {
        const {
            shippingFee,
            totalTax
        } = overrideFees || {};

        const {
            totals: {
                prices: {
                    subtotal_excluding_tax: {
                        value: subtotal_excluding_tax
                    },
                    subtotal_including_tax: {
                        value: subtotal_including_tax
                    },
                    discount
                }
            }
        } = this.props;

        const {
            amount: {
                value: discount_amount = 0
            } = {}
        } = discount || {};

        const discount_positive = Math.abs(discount_amount);

        const cartTotalTax = subtotal_including_tax - subtotal_excluding_tax;

        // vvv Apple pay's API, line items store all the fees whcih are included in the payment
        const applePayLineItems = [];

        applePayLineItems.push(appleLineItem('Subtotal', subtotal_excluding_tax));

        if (discount_positive !== 0) {
            applePayLineItems.push(appleLineItem('Discount', discount_positive));
        }

        applePayLineItems.push(appleLineItem('Shipping Fees', shippingFee || 0));
        applePayLineItems.push(appleLineItem('Taxes', totalTax || cartTotalTax));

        return applePayLineItems;
    }

    makeLineItems({
        subTotal,
        shippingFees,
        discount,
        taxes
    }) {
        const applePayLineItems = [];

        applePayLineItems.push(appleLineItem('Subtotal', subTotal));

        const discount_positive = Math.abs(discount);

        if (discount_positive !== 0) {
            applePayLineItems.push(appleLineItem('Discount', discount_positive));
        }

        applePayLineItems.push(appleLineItem('Shipping Fees', shippingFees || 0));
        applePayLineItems.push(appleLineItem('Taxes', taxes));

        return applePayLineItems;
    }

    sumLineItems(lineItems) {
        const { amount: subTotal } = getLineItem('Subtotal', lineItems) || {};
        const { amount: discount = 0 } = getLineItem('Discount', lineItems) || {};
        const { amount: shippingFee = 0 } = getLineItem('Shipping Fees', lineItems) || {};
        const { amount: totalTax = 0 } = getLineItem('Taxes', lineItems) || {};

        return subTotal + shippingFee + totalTax - discount;
    }

    /**
     *
     * @param {string} valURL
     * @param {string} appleValidation
     * @description
     * Validates merchant and returns Apple session token
     */
    performValidation = async (valURL, appleValidation) => {
        try {
            const response = await fetch(`${appleValidation}?valURL=${valURL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            return response.json();
        } catch (error) {
            return Promise.reject(error);
        }
    };

    async validationShippingAddress(addressObject) {
        try {
            const {
                payfortAppleValidateAddress: {
                    status,
                    error_msg,
                    data,
                    totals
                }
            } = await fetchMutation(
                PayfortAppleValidateAddressQuery.getPayfortAppleValidateAddressMutation(
                    getCartId(),
                    addressObject
                )
            );

            if (status === 'success') {
                return {
                    data,
                    taxes: data[0]?.tax,
                    totals
                };
            }

            throw new Error(error_msg);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     *
     * @param {ApplePayPayment} applePayment
     * @description Sends payment request to Payment Service Provider (APS) and places magento order.
     */
    sendPaymentToAps = async (applePayment) => {
        window.cachedOrder = null;

        const {
            token,
            // vvv shipping address from apple pay sheet
            shippingContact
        } = applePayment;

        const {
            payfortConfig: {
                apsApple: {
                    title
                }
            },
            showNotification
        } = this.props;

        const cart_id = getCartId();

        try {
            const {
                payfortSendAppleCartDataToAps: {
                    orderId,
                    errorMessage,
                    shippingMethod,
                    deliveryDate,
                    cartData,
                    pan_reference_code
                }
            } = await fetchMutation(
                PayfortSendAppleCartDataToApsQuery
                    .getPayfortSendAppleCartDataToAps({
                        cart_id,
                        token,
                        shippingContact
                    })
            );

            const {
                items,
                shipping_addresses: [
                    {
                        selected_shipping_method = {}
                    } = {}
                ] = []
            } = cartData || {};

            const {
                address: {
                    city: cityFromFinalCart = '',
                    region: {
                        label: regionFromFinalCart = ''
                    } = {}
                } = {}
            } = selected_shipping_method || {};

            if (errorMessage && errorMessage.length) {
                if (orderId) {
                    setPayfortOrderFinished(orderId, false);
                }

                if (errorMessage === 'phone-invalid') {
                    setTimeout(() => {
                        showNotification(
                            'error', __('Phone number is invalid! Please correct the phone number and try again!')
                        );
                    // eslint-disable-next-line no-magic-numbers
                    }, 1500);

                    return;
                }

                applePayEventDispatcher({
                    error: {
                        message: errorMessage
                    },
                    orderId
                });

                return;
            }

            const magentoAddress = appleShippingContactToMagentoAddress(shippingContact);

            window.cachedOrder = {
                total: cartData,
                items,
                increment_id: orderId,
                shipping_address: {
                    ...magentoAddress,
                    city: cityFromFinalCart,
                    region: regionFromFinalCart
                },
                billing_address: {
                    ...magentoAddress,
                    city: cityFromFinalCart,
                    region: regionFromFinalCart
                },
                payment_methods: [{ name: title }],
                shipping_method: shippingMethod,
                delivery_date: deliveryDate
            };

            window.cachedPanReferenceCode = pan_reference_code;

            applePayEventDispatcher({
                orderId
            });
        } catch (error) {
            applePayEventDispatcher({ error });
        }
    };

    containerProps() {
        return {};
    }

    render() {
        return (
            <ApplePayExpressComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplePayExpressContainer);
