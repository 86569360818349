/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import './GoogleIcon.style';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/GoogleIcon/Component */
export class GoogleIconComponent extends PureComponent {
    render() {
        return (
            <svg block="GoogleIcon" width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22.5018 12.2307C22.5018 11.5757 22.4374 10.9427 22.3403 10.3337H12.7188V14.0927H18.2279C18.1085
                  14.6985 17.867 15.275 17.5175 15.7885C17.1681 16.302 16.7177 16.7423 16.1926 17.0837L19.4826
                  19.5837C21.4034 17.8427 22.5018 15.2677 22.5018 12.2297V12.2307Z"
                  fill="#4285F4"
                />
                <path
                  d="M12.7208 5.95802C14.2279 5.95802 15.5776 6.46702 16.6412 7.45802L19.5582 4.60401C17.7865 2.992
                  15.4754 2 12.7208 2C10.8273 1.99967 8.97101 2.5146 7.35926 3.48726C5.74751 4.45993 4.44379 5.85202
                  3.59375 7.50802L6.99099 10.088C7.79509 7.71702 10.0562 5.95802 12.7208 5.95802Z"
                  fill="#EA4335"
                />
                <path
                  d="M6.98334 13.9119C6.77119 13.2956 6.66149 12.6501 6.65843 11.9998C6.65843 11.3328 6.77287 10.6918
                  6.98232 10.0868L3.5861 7.50783C2.86981 8.90131 2.49779 10.4399 2.50001 11.9998C2.50001 13.6169 2.89133
                  15.1419 3.59019 16.4919L6.98232 13.9119H6.98334Z"
                  fill="#FBBC05"
                />
                <path
                  d="M12.7171 22.0007C15.4758 22.0007 17.7961 21.1137 19.482 19.5807L16.192 17.0807C15.2755 17.6837
                  14.0975 18.0387 12.7181 18.0387C10.0514 18.0387 7.79136 16.2797 6.98318 13.9087L3.58594
                  16.4887C5.27179 19.7587 8.7242 22.0007 12.7171 22.0007Z"
                  fill="#34A853"
                />
            </svg>
        );
    }
}

export default GoogleIconComponent;
