/**
 * MageAnts Sample Product compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addIsSampleField = (args, callback) => [
    ...callback(...args),
    'isSample'
];

export default {
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemProductsFields: addIsSampleField
        }
    }
};
