import PropTypes from 'prop-types';

import {
    FieldNumberWithControlsContainer as SourceFieldNumberWithControlsContainer
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.container';
import { EventsType, FieldAttrType } from 'Type/Field.type';

/** @namespace Scandipwa/Component/FieldNumberWithControls/Container */
export class FieldNumberWithControlsContainer extends SourceFieldNumberWithControlsContainer {
    static propTypes = {
        // Field attributes
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        setRef: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool.isRequired
    };
}

export default FieldNumberWithControlsContainer;
