import { FieldMultiSelectComponent } from 'Component/FieldMultiSelect/FieldMultiSelect.component';
import { FieldSelectContainer } from 'Component/FieldSelect/FieldSelect.container';

/** @namespace Scandipwa/Component/FieldMultiSelect/Container */
export class FieldMultiSelectContainer extends FieldSelectContainer {
    state = {
        ...this.state,
        selectedOptions: {}
    };

    containerProps() {
        const { selectedOptions } = this.state;

        return {
            ...super.containerProps(),
            selectedOptions
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedOptionIndex: prevSelectedOptionIndex } = prevState;

        const {
            isDisabled: prevIsDisabled,
            attr: { triggerExpand: prevTriggerExpand }
        } = prevProps;

        const {
            selectedOptionIndex,
            filter,
            isExpanded,
            isFieldSelectOptionSelected,
            selectedOptions
        } = this.state;

        const {
            attr: { triggerExpand },
            isSearch,
            isDisabled,
            events: { updateSelectedValue }
        } = this.props;

        const options = this.getOptions();

        /**
         * Sometimes after reloading page, selectedOptionIndex is 0, then changes to -1.
         * Thus, it passes (prevSelectedOptionIndex !== selectedOptionIndex) condition and onChange event is triggered.
         * As workaround, add isFieldSelectOptionSelected condition
         * to disable onChange event to be triggered before changing field value.
         */
        if (prevSelectedOptionIndex !== selectedOptionIndex && isFieldSelectOptionSelected) {
            const { value } = options[selectedOptionIndex] || {};

            this.triggerOnChange(value);
        }

        const stateUpdate = {};

        // if element changes disabled state, reset selected option to placeholder option
        if (prevIsDisabled !== isDisabled) {
            stateUpdate.selectedOptionIndex = 0;
            stateUpdate.selectedOptionLabel = options[0].label;
            stateUpdate.selectedOptionValue = options[0].value;
        }

        if (triggerExpand !== prevTriggerExpand) {
            stateUpdate.isExpanded = triggerExpand;
        }

        if (!isSearch && !Object.keys(stateUpdate).length) {
            return;
        }

        if (!isSearch) {
            this.setState(stateUpdate);

            return;
        }

        if (Object.values(selectedOptions)?.find((value) => value !== false)) {
            const selected = Object.values(selectedOptions)
                .filter((value) => value !== false);
            const selectedStr = selected.map(({ value }) => (value)).join(',');

            stateUpdate.selectedOptionIndex = 1;
            stateUpdate.selectedOptionLabel = selectedStr;
            stateUpdate.selectedOptionValue = selectedStr;

            updateSelectedValue(selectedStr);

            this.setState(stateUpdate);

            return;
        }

        const matchedOptionIndex = FieldSelectContainer.getOptionIndexByLabel(
            options,
            filter
        );

        if (matchedOptionIndex !== -1) {
            const matchedOption = options[matchedOptionIndex];
            const { label } = matchedOption;

            this.setState({
                ...stateUpdate,
                filter: label,
                selectedOptionIndex: matchedOptionIndex
            });

            return;
        }

        if (isExpanded) {
            this.setState({
                ...stateUpdate,
                selectedOptionIndex: matchedOptionIndex
            });

            return;
        }

        this.setState({
            ...stateUpdate,
            filter: '',
            selectedOptionIndex: matchedOptionIndex
        });
    }

    handleSelectListOptionClick(option) {
        return () => {
            const { changeValueOnDoubleClick } = this.props;
            const {
                value,
                target: {
                    value: targetValue
                } = {},
                label,
                id
            } = option;
            const { selectedOptions } = this.state;

            if (selectedOptions[id] !== undefined && selectedOptions[id] !== false) {
                selectedOptions[id] = false;
            } else {
                selectedOptions[id] = option;
            }

            const {
                selectedOptionIndex,
                isFieldSelectOptionSelected
            } = this.state;

            const options = this.getOptions();

            const {
                value: selectedOptionValue
            } = options[selectedOptionIndex] || {};

            const fieldValue = value || targetValue || '';
            const selectedOptionLabel = label || value;

            if (!isFieldSelectOptionSelected) {
                this.setState({ isFieldSelectOptionSelected: true });
            }

            if (changeValueOnDoubleClick && selectedOptionValue === fieldValue) {
                this.setState({
                    filter: '',
                    selectedOptionLabel: '',
                    selectedOptionIndex: -1
                });

                return;
            }

            this.setState({
                selectedOptionIndex: FieldMultiSelectContainer.getOptionIndexByValue(options, value),
                selectedOptionLabel
            });
        };
    }

    render() {
        return (
            <FieldMultiSelectComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldMultiSelectContainer;
