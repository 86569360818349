export const ORDER_MAXIMUM_PAGE_SIZE = 999999;
export const ORDER_PAGE_SIZE_DESKTOP = 10;
export const ORDER_PAGE_SIZE_MOBILE = 5;

export const DATE_RANGE_FILTER_FIELD_SELECT_OPTIONS = [
    {
        id: '1month',
        label: __('Last month'),
        value: '1month'
    },
    {
        id: '3month',
        label: __('Last 3 month'),
        value: '3month'
    },
    {
        id: '6month',
        label: __('Last 6 month'),
        value: '6month'
    },
    {
        id: '1year',
        label: __('Last 12 month'),
        value: '1year'
    },
    {
        id: 'previous_year',
        label: (() => `${new Date().getFullYear() - 2}-${new Date().getFullYear() - 1}`)(),
        value: 'previous_year'
    }
];

// export const ORDER_COUNT_PER_CLICK = 10;
// export const ORDER_PAGE_SIZE = 10;
// export const TOTAL_PREVIOUS_MONTH_AGO_TO_LAST_MONTH = 1;
// export const TOTAL_PREVIOUS_MONTH_AGO_TO_LAST_3_MONTHS = 3;
// export const TOTAL_PREVIOUS_MONTH_AGO_TO_LAST_6_MONTHS = 6;
// export const TOTAL_PREVIOUS_MONTH_AGO_TO_LAST_12_MONTHS = 12;
// export const FIRST_HOUR_OF_DAY = 0;
// export const FIRST_MINUTE_OF_HOUR = 0;
// export const FIRST_MS_OF_SECOND = 0;
// export const FIRST_SECOND_OF_MINUTE = 0;
// export const LAST_MONTH_INDEX = 11;
// export const LAST_DATE_OF_LAST_MONTH = 31;
// export const LAST_HOUR_OF_DAY = 23;
// export const LAST_MINUTE_OF_HOUR = 59;
// export const LAST_SECOND_OF_MINUTE = 59;
// export const LAST_MS_OF_SECOND = 999;
// export const DATE_RANGE_FILTER_FIELD_SELECT_OPTIONS = [
//     {
//         id: 1,
//         label: 'Last month',
//         value: 1
//     },
//     {
//         id: 2,
//         label: 'Last 3 month',
//         value: 2
//     },
//     {
//         id: 3,
//         label: 'Last 6 month',
//         value: 3
//     },
//     {
//         id: 4,
//         label: 'Last 12 month',
//         value: 4
//     }
// ];
// export const NEXT_ID_OF_DATE_RANGE_FILTER_FIELD_SELECT_OPTIONS = 5;
