import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';

import './CheckoutSlotsButton.style';

/** @namespace Scandipwa/Component/CheckoutSlotsButton/Component */
export class CheckoutSlotsButtonComponent extends PureComponent {
    static propTypes = {
        onClickHandler: PropTypes.func.isRequired,
        children: ChildrenType.isRequired,
        block: PropTypes.string,
        elem: PropTypes.string,
        isSelected: PropTypes.bool,
        isDisabled: PropTypes.bool
    };

    static defaultProps = {
        block: '',
        elem: '',
        isSelected: false,
        isDisabled: false
    };

    render() {
        const {
            onClickHandler,
            children,
            block,
            elem,
            isSelected,
            isDisabled
        } = this.props;

        return (
            <div
              block="CheckoutSlotsButton"
              mods={ {
                  isDisabled
              } }
              mix={ {
                  block,
                  elem,
                  mods: {
                      isSelected,
                      isDisabled
                  }
              } }
              onClick={ onClickHandler }
              onKeyDown={ onClickHandler }
              tabIndex="0"
              role="button"
            >
                { children }
            </div>
        );
    }
}

export default CheckoutSlotsButtonComponent;
