import {
    FieldGroup as SourceFieldGroup
} from 'SourceComponent/FieldGroup/FieldGroup.component';

/**
 * @namespace Scandipwa/Component/FieldGroup/Component */
export class FieldGroupComponent extends SourceFieldGroup {
    // Renders validation error messages under group
    renderErrorMessage(message) {
        return <div block="Field" key={ message } elem="ErrorMessage">{ __(message) }</div>;
    }
}

export default FieldGroupComponent;
