import {
    EditIcon as SourceEditIcon
} from 'SourceComponent/EditIcon/EditIcon.component';

import './EditIcon.override.style';

/** @namespace Scandipwa/Component/EditIcon/Component */
export class EditIconComponent extends SourceEditIcon {
    /**
     * Overridden to match design
     */
    render() {
        return (
            <svg
              block="EditIcon"
              viewBox="0 0 12 13"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  d="M8.48533 4.95739L7.54267 4.01472L1.33333 10.2241V11.1667H2.276L8.48533 4.95739ZM9.428
                    4.01472L10.3707 3.07206L9.428 2.12939L8.48533 3.07206L9.428 4.01472ZM2.828 12.5001H0V9.67139L8.95667
                    0.714722C9.08169 0.589742 9.25122 0.519531 9.428 0.519531C9.60478 0.519531 9.77432 0.589742
                    9.89933 0.714722L11.7853 2.60072C11.9103 2.72574 11.9805 2.89528 11.9805 3.07206C11.9805 3.24883
                    11.9103 3.41837 11.7853 3.54339L2.82867 12.5001H2.828Z"
                />
            </svg>

        );
    }
}

export default EditIconComponent;
