import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MAXIMUM_TOTAL_SLIDE_ITEM_PER_SLIDE, SLOT_WIDTH, TIMES } from 'Component/CheckoutSlots/CheckoutSlots.config';
import CheckoutSlotsButton from 'Component/CheckoutSlotsButton';
import Loader from 'Component/Loader';
import SliderPerSlide from 'Component/SliderPerSlide';

import './CheckoutSlots.style';

/** @namespace Scandipwa/Component/CheckoutSlots/Component */
export class CheckoutSlotsComponent extends PureComponent {
    static propTypes = {
        dates: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired,
        selectedDateIndex: PropTypes.number.isRequired,
        selectedTimeIndex: PropTypes.number.isRequired,
        onDateClickHandler: PropTypes.func.isRequired,
        onTimeClickHandler: PropTypes.func.isRequired,
        estimatedDeliveryTime: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isHideTimeSelection: PropTypes.bool.isRequired
    };

    renderEstimatedDelivery() {
        const { estimatedDeliveryTime } = this.props;

        return (
            <p block="CheckoutSlots" elem="EstimatedDelivery" mix={ { block: 'small-caption' } }>
                { __('Estimated delivery date:') }
                <span>
                    { ` ${estimatedDeliveryTime}` }
                </span>
            </p>
        );
    }

    renderDateSlots() {
        const {
            dates,
            isMobile
        } = this.props;

        if (!dates.length) {
            return null;
        }

        return (
            <div block="CheckoutSlots" elem="DateSlotsWrapper">
                <p block="CheckoutSlots" elem="DateCaption" mix={ { block: 'small-caption' } }>
                     <strong>{ __('Choose the best delivery date') }</strong>
                </p>
                <div block="CheckoutSlots" elem="DateSlots">
                    <SliderPerSlide
                      hasArrowBorder
                      hasArrowBackground
                      showArrows
                      maximumTotalSlideItemPerSlide={ MAXIMUM_TOTAL_SLIDE_ITEM_PER_SLIDE }
                      slideItemWidth={ { desktop: SLOT_WIDTH, mobile: SLOT_WIDTH } }
                      isInteractionDisabled={ !isMobile }
                      isDateSlots
                    >
                        { dates.map(this.renderDateSlot.bind(this)) }
                    </SliderPerSlide>
                </div>
            </div>
        );
    }

    renderDateSlot(date, index) {
        const { onDateClickHandler, selectedDateIndex } = this.props;
        const {
            day,
            month,
            dayOfTheWeek,
            times = []
        } = date;

        return (
            <CheckoutSlotsButton
              block="CheckoutSlots"
              elem="Date"
              isSelected={ index === selectedDateIndex }
              isDisabled={ !times.length }
              onClick={ onDateClickHandler }
              key={ index }
              index={ index }
            >
                <p block="CheckoutSlots" elem="DayMonth" mix={ { block: 'small-caption' } }>
                    <strong>{ `${day} ${month}` }</strong>
                </p>
                <p block="CheckoutSlots" elem="DayOfTheWeek" mix={ { block: 'small-caption' } }>
                    { dayOfTheWeek }
                </p>
            </CheckoutSlotsButton>
        );
    }

    renderTimeSlots() {
        const { dates, isHideTimeSelection } = this.props;

        if (!dates.length) {
            return null;
        }

        return (
            <div>
                <p
                  block="CheckoutSlots"
                  elem="TimeCaption"
                  mix={ { block: 'small-caption' } }
                  mods={ { isHideTimeSelection } }
                >
                    <strong>{ __('Pick convenient time') }</strong>
                </p>
                <div
                  block="CheckoutSlots"
                  elem="TimeSlots"
                  mods={ { isHideTimeSelection } }
                >
                    { TIMES.map(this.renderTimeSlot.bind(this)) }
                </div>
            </div>
        );
    }

    renderTimeSlot(time, index) {
        const {
            onTimeClickHandler, selectedTimeIndex, selectedDateIndex, dates
        } = this.props;
        const { times = [] } = dates[selectedDateIndex];
        const timeIndex = times.indexOf(time);

        return (
            <CheckoutSlotsButton
              block="CheckoutSlots"
              elem="Time"
              isSelected={ timeIndex === selectedTimeIndex }
              isDisabled={ timeIndex === -1 }
              onClick={ onTimeClickHandler }
              index={ timeIndex }
              key={ index }
            >
                { time }
            </CheckoutSlotsButton>
        );
    }

    renderLoader() {
        const {
            isLoading
        } = this.props;

        return <Loader isLoading={ isLoading } />;
    }

    render() {
        const {
            dates,
            isLoading
        } = this.props;

        if (!isLoading && !dates.length) {
            return null;
        }

        return (
            <div block="CheckoutSlots">
                { this.renderLoader() }
                { this.renderEstimatedDelivery() }
                { this.renderDateSlots() }
                { this.renderTimeSlots() }
            </div>
        );
    }
}

export default CheckoutSlotsComponent;
