/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CART_URL } from 'Route/CartPage/CartPage.config';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getCartId } from 'Util/Cart/Token';
import history from 'Util/History';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';
import { appendWithStoreCode } from 'Util/Url';

import {
    CARD_CHUNK_LENGTH,
    MIN_DIGITS_FOR_CARD_NUMBER,
    PAYFORT_CVV_MAX,
    PAYFORT_CVV_MIN,
    PAYFORT_TOKEN_CVV_PREFIX,
    PAYFORT_TOKEN_NAME,
    PayfortEvents,
    PayfortLocalStorage
} from '../Payfort.config';
import PayfortOrderCancelQuery from '../query/PayfortOrderCancel.query';

/* eslint-disable no-param-reassign */
/* eslint-disable no-magic-numbers */

export const CartDispatcher = import(
    /* webpackMode: "eager"*/
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/camelToSnakeCase */
export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/setPayfortOrderFinished */
export const setPayfortOrderFinished = (orderId, finished) => {
    BrowserDatabase.setItem(
        {
            orderId,
            finished
        },
        PayfortLocalStorage
    );
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/cancelPayfortOrder */
export const cancelPayfortOrder = async (orderId) => {
    const { dispatch } = getStore();
    const cartDispatcher = (await CartDispatcher).default;

    if (history.location.pathname.includes('thankyou')) {
        setPayfortOrderFinished(orderId, true);
        return;
    }

    try {
        await fetchMutation(
            PayfortOrderCancelQuery.getPayfortOrderCancelField(orderId, getCartId())
        );
        dispatch(showNotification('success', __('Payment canceled.')));

        if (!orderId) {
            cartDispatcher.updateInitialCartData(dispatch);
            history.push(appendWithStoreCode(CART_URL));
        }
    } catch (error) {
        // vvv This will happen if orderId was not found on server, or something else
        console.error(error);
        dispatch(showNotification(
            'error',
            __('There has been error on canceling payment: %s', error)
        ));
    }

    setPayfortOrderFinished(orderId, true);
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/getPayfortOrderFinished */
export const getPayfortOrderFinished = () => BrowserDatabase.getItem(PayfortLocalStorage);

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/redirectToCart */
export const redirectToCart = () => {
    const {
        orderId,
        finished
    } = getPayfortOrderFinished() || {};

    // vvv Check if cached orderId is present and if order wasn't finished
    if (orderId && !finished) {
        cancelPayfortOrder(orderId);
    }

    history.push(appendWithStoreCode(CART_URL));
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/extractTokenFromFormFields */
export const extractTokenFromFormFields = (fields) => {
    // vvv find selected token by name and value = true
    const tokenField = fields.find(
        (field) => field.name === PAYFORT_TOKEN_NAME && field.value
    );

    if (!tokenField) {
        return null;
    }

    // vvv extract token's hash from data-token attribute
    const public_hash = tokenField.field.dataset.token;

    if (!public_hash) {
        return null;
    }

    // vvv find cvv field by appending 'cvv' + public_hash, this will be its name
    const cvvField = fields.find(
        (field) => field.name === `${PAYFORT_TOKEN_CVV_PREFIX}${public_hash}`
    );

    return {
        cvv: cvvField.value,
        public_hash
    };
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/getCardType */
export const getCardType = (cardNumber) => {
    switch (cardNumber[0]) {
    case '4':
        return 'VISA';
    case '5':
        return 'MASTERCARD';
    case '3':
        return 'AMEX';
    default:
        return 'CARD';
    }
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/getDefaultToken */
export const getDefaultToken = (tokens) => tokens.reduce((acc, token) => (token.isDefault ? token : acc), tokens[0]);

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/splitStringEvenly */
export const splitStringEvenly = (string, chunkLength) => (
    string.match(new RegExp(`.{1,${chunkLength}}`, 'g')).join(' ')
);

// Get focus on click an input field to select the value to have opportunity to quick delete it
/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/focusAndSelectCardNumber */
export const focusAndSelectCardNumber = (e) => {
    e.target.focus();
    e.target.select();
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/formatCardNumber */
export const formatCardNumber = (e) => {
    const { value } = e.target;

    if (!value) {
        return;
    }

    const numericValue = value.replace(/[^\d]/g, '');

    if (numericValue.length < 4) {
        e.target.value = numericValue;

        return;
    }

    if (value.search(/\s/g) === -1) {
        e.target.value = splitStringEvenly(numericValue, CARD_CHUNK_LENGTH);
        return;
    }

    const segments = value
        .replace(/[^\d\s]/g, '')
        .split(/\s/g)
        .slice(0, 5)
        .map((segment) => segment.slice(0, 4));

    if (segments.length < 5 && segments.slice(-1)[0].length === 4) {
        e.target.value = `${segments.join(' ').trim()} `;
        return;
    }

    if (segments.length === 5) {
        segments[4] = segments[4].slice(0, 3);
    }

    e.target.value = segments.join(' ').trim();
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/formatExpirationDate */
export const formatExpirationDate = (setExpirationDateState, e) => {
    const value = e.target.value.replace(/[^\d/]/g, '');
    if (value.search('/') >= 0) {
        const [month, year] = value.split('/');

        if (!year) {
            setExpirationDateState(value);
            return;
        }

        const targetValue = `${month.slice(0, 2)} / ${year.slice(0, 2)}`;
        setExpirationDateState(targetValue);
        e.target.value = targetValue;
        return;
    }

    const month = value.slice(0, 2);
    const year = value.slice(2, 4);
    const slash = month.length === 2 && year.length > 0 ? '/' : '';
    const targetValue = `${month} ${slash} ${year || ''}`.trim();
    setExpirationDateState(targetValue);
    e.target.value = targetValue;
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/validateExpirationDate */
export const validateExpirationDate = (isRequired, value) => {
    const numericValue = value.replace(/[^\d]/g, '');

    if (!numericValue && !isRequired) {
        return true;
    }

    if (!numericValue && isRequired) {
        return false;
    }

    const month = parseInt(numericValue.slice(0, 2), 10) || 0;
    const year = parseInt(numericValue.slice(2, 4), 10) || 0;
    const date = new Date();
    const currentYear = parseInt(date.getFullYear().toString().slice(-2), 10);
    const currentMonth = date.getMonth() + 1;

    if (
        (year > currentYear && month <= 12 && month >= 1)
        || (year === currentYear && month <= 12 && month >= currentMonth)
    ) {
        return true;
    }

    return false;
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/validateCardNumber */
export const validateCardNumber = (value) => value.replace(/[^\d]/g, '').length >= MIN_DIGITS_FOR_CARD_NUMBER;

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/formatCVC */
export const formatCVC = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    e.target.value = value.slice(0, 4);
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/validateCVC */
export const validateCVC = (value) => value.length >= PAYFORT_CVV_MIN && value.length <= PAYFORT_CVV_MAX;

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/formatExpiryDateForRequest */
export const formatExpiryDateForRequest = (form_expiry_date) => {
    const [month, year] = form_expiry_date.replace(/\s/g, '').split('/');
    return year + month;
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/getOptions */
export const getOptions = ({ data, valueKey, labelKey }) => {
    const options = data.map((record) => ({
        label:
            typeof labelKey === 'function'
                ? labelKey(record)
                : record[labelKey || 'label'],
        value: record[valueKey]
    }));

    return options;
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/getObjectFromArray */
export const getObjectFromArray = (array, key, isUnique = false) => array.reduce((acc, record) => {
    if (isUnique) {
        acc[record[key]] = record;
        return acc;
    }

    if (!acc[record[key]]) {
        acc[record[key]] = [record];
        return acc;
    }

    acc[record[key]].push(record);

    return acc;
}, {});

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/appleLineItem */
/**
 * @param {string} label
 * @param {float} amount
 * @returns {{
 *  type: 'final'
 *  label: string
 *  amount: float
 * }}
 */
export const appleLineItem = (label, amount) => ({
    type: 'final',
    label,
    amount
});

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/applePayEventDispatcher */
/**
 * @param {{
 *  cancel: Boolean
 *  orderId: number
 *  error: Error
 * }} detail
 * @description Dispatches custom ApplePay event with data to be handled by CheckoutContainer's listeners
 */
export const applePayEventDispatcher = (detail) => {
    window.document.dispatchEvent(
        new CustomEvent(PayfortEvents.APPLE_EVENT, { detail })
    );
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/payfortFailEventDispatcher */
export const payfortFailEventDispatcher = (error) => {
    window.document.dispatchEvent(
        new CustomEvent(PayfortEvents.PAY_FAIL_EVENT, { error })
    );
};

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/getLineItem */
export const getLineItem = (findLabel, lineItems) => lineItems.find(({ label }) => label === findLabel);

/** @namespace Scandiweb/AmazonPayfort/Util/Payfort/Util/appleShippingContactToMagentoAddress */
export const appleShippingContactToMagentoAddress = (appleShippingContact) => {
    const {
        addressLines,
        administrativeArea,
        countryCode,
        emailAddress,
        familyName,
        givenName,
        locality,
        phoneNumber,
        postalCode,
        subAdministrativeArea,
        subLocality
    } = appleShippingContact;

    const magentoAddress = {
        firstname: givenName,
        lastname: familyName,
        company: '',
        street: addressLines,
        city: locality,
        region: administrativeArea || subAdministrativeArea || subLocality,
        postcode: postalCode || '',
        country_id: countryCode,
        telephone: phoneNumber,
        email: emailAddress
    };

    return magentoAddress;
};
