/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';
import { MixType, RefType } from 'Type/Common.type';
import {
    EventsType, FieldAttrType, FieldOptionsType, LabelType, ValidationRuleType
} from 'Type/Field.type';
import { validate } from 'Util/Validator';

import { FIELD_TYPE } from './Field.config';

/** @namespace Scandipwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    /*
     * Receiving identifier of CMS block to be rendered instead of checkbox label
     */
    static propTypes = {
        // Field attributes
        type: PropTypes.oneOf(Object.values(FIELD_TYPE)),
        attr: FieldAttrType,
        events: EventsType,
        isDisabled: PropTypes.bool,
        mix: MixType,
        options: FieldOptionsType,
        elemRef: RefType,
        changeValueOnDoubleClick: PropTypes.bool,
        isSortSelect: PropTypes.bool,
        updateSelectedValues: PropTypes.func,
        resetFieldValue: PropTypes.func,

        // Validation
        validationRule: ValidationRuleType,
        validateOn: PropTypes.arrayOf(PropTypes.string),
        showErrorAsLabel: PropTypes.bool,

        // Labels
        label: LabelType,
        subLabel: PropTypes.string,
        addRequiredTag: PropTypes.bool,
        triggerOnlyOnClick: PropTypes.bool,
        cmsLabelId: PropTypes.string,
        isCheckout: PropTypes.bool,
        isShowHtmlOptions: PropTypes.bool,
        isRemoveUnavailable: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        cmsLabelId: '',
        isCheckout: false,
        triggerOnlyOnClick: false,
        isShowHtmlOptions: false,
        isRemoveUnavailable: false
    };

    state = {
        ...this.state,
        showPassword: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        onClickShowPassword: this.onClickShowPassword.bind(this)
    };

    componentWillUnmount() {
        const { validationRule } = this.props;

        if (this.fieldRef.current) {
            this.fieldRef.current?.removeEventListener('resetField', this.resetField.bind(this));

            if (validationRule && Object.keys(validationRule).length > 0) {
                this.fieldRef.current?.removeEventListener('validate', this.validate.bind(this));
            }
        }
    }

    onClickShowPassword() {
        this.setState(({ showPassword }) => ({ showPassword: !showPassword }));
    }

    containerProps() {
        const {
            cmsLabelId,
            isSearch,
            triggerOnlyOnClick,
            isShowHtmlOptions,
            isCheckout,
            isDisableResetOnOptionsChange,
            isSubmitted,
            isRemoveUnavailable
        } = this.props;
        const { showPassword } = this.state;

        return {
            ...super.containerProps(),
            cmsLabelId,
            showPassword,
            isSearch,
            triggerOnlyOnClick,
            isShowHtmlOptions,
            isCheckout,
            isDisableResetOnOptionsChange,
            isSubmitted,
            isRemoveUnavailable
        };
    }

    /**
     * Overridden to pass fieldRef to validate function
     */
    validate(data) {
        const {
            validationRule: {
                range: {
                    max: maxValidLength = 0
                } = {}
            },
            type,
            attr: {
                name
            } = {}
        } = this.props;
        const { showLengthError } = this.state;
        const newValidationRule = {
            ...this.handleShowLengthError(),
            fieldRef: this.fieldRef
        };
        const value = type === FIELD_TYPE.checkbox || type === FIELD_TYPE.radio
            ? !!this.fieldRef.checked
            : this.fieldRef.value;
        const toSetValue = type === FIELD_TYPE.file
            ? value !== '' ? value.toLowerCase() : this.fieldRef.fileData
            : value;
        const response = validate(toSetValue, newValidationRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && response !== true) {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (maxValidLength && value.length > maxValidLength && !showLengthError) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('Please enter no more than %s characters.', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }

        this.setState({ validationResponse: output });

        return output;
    }
}

export default FieldContainer;
