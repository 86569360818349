/**
 * Amasty FAQ & Product Questions compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_AMASTY_FAQ_STORE } from './AmastyFaq.config';

/** @namespace Scandiweb/AmastyFaq/Store/AmastyFaq/Reducer/getInitialState */
export const getInitialState = () => ({
    tags: [],
    selectedTag: {},
    questions: {},
    categories: {}
});

/** @namespace Scandiweb/AmastyFaq/Store/AmastyFaq/Reducer/AmastyFaqReducer */
export const AmastyFaqReducer = (state = getInitialState(), action) => {
    const { type, state: newState } = action;

    if (type === UPDATE_AMASTY_FAQ_STORE) {
        return {
            ...state,
            ...newState
        };
    }

    return state;
};

export default AmastyFaqReducer;
