/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { Suspense } from 'react';

import CheckmarkIcon from 'Component/CheckmarkIcon';
import CmsBlock from 'Component/CmsBlock';
import EyeIcon from 'Component/EyeIcon/EyeIcon.component';
import FieldMultiSelect from 'Component/FieldMultiSelect';
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';
import { MixType } from 'Type/Common.type';
import {
    EventsType,
    FieldAttrType,
    LabelType,
    OptionType
} from 'Type/Field.type';
import { noopFn } from 'Util/Common';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { FIELD_TYPE, NEWSLETTER_CHECKBOX_INPUT, NEWSLETTER_EMAIL_INPUT } from './Field.config';

import './Field.override.style';

export const TelephoneField = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "telephone-field" */
    'Component/TelephoneField'
));
export const FieldSelectContainer = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "field-select" */
    'Component/FieldSelect/FieldSelect.container'
));

/** @namespace Scandipwa/Component/Field/Component */
export class FieldComponent extends SourceField {
    /*
     * Receiving identifier of CMS block to be rendered instead of checkbox label
     */
    static propTypes = {
        // Field attributes
        type: PropTypes.oneOf(Object.values(FIELD_TYPE)).isRequired,
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        setRef: PropTypes.func.isRequired,
        mix: MixType.isRequired,
        options: PropTypes.arrayOf(OptionType).isRequired,
        changeValueOnDoubleClick: PropTypes.bool,
        isSortSelect: PropTypes.bool,
        resetFieldValue: PropTypes.func.isRequired,

        // Validation
        validate: PropTypes.func.isRequired,
        showErrorAsLabel: PropTypes.bool.isRequired,
        validationResponse: (props, propName, componentName) => {
            const propValue = props[propName];

            if (propValue === null) {
                return;
            }

            if (typeof propValue === 'boolean') {
                return;
            }

            if (typeof propValue === 'object' && !Object.keys(propValue).includes('errorMessages')) {
                throw new Error(
                    // eslint-disable-next-line max-len
                    `${componentName} only accepts null, bool or object of "errorMessages" as "validationResponse", received "${JSON.stringify(propValue)}"`
                );
            }
        },

        // Labels
        label: LabelType.isRequired,
        subLabel: PropTypes.string.isRequired,
        addRequiredTag: PropTypes.bool.isRequired,
        cmsLabelId: PropTypes.string,
        showPassword: PropTypes.bool,
        onClickShowPassword: PropTypes.func,
        isCheckout: PropTypes.bool.isRequired,
        isRemoveUnavailable: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        cmsLabelId: '',
        showPassword: false
    };

    renderMap = {
        ...this.renderMap,
        [FIELD_TYPE.multiselect]: this.renderMultiSelect.bind(this)
    };

    /*
     * New array to render values
     */
    shouldRenderCheckmarkIconArray = [
        FIELD_TYPE.email,
        FIELD_TYPE.text,
        FIELD_TYPE.password,
        FIELD_TYPE.tel,
        FIELD_TYPE.number
    ];

    /**
     * Overridden to add condition for newsletter inputs
     */
    renderErrorMessages() {
        const {
            showErrorAsLabel,
            validationResponse,
            attr: { name },
            isSubmitted = true
        } = this.props;

        if (!showErrorAsLabel || !validationResponse || validationResponse === true) {
            return null;
        }

        const { errorMessages = [], value = '' } = validationResponse;

        if (!errorMessages
            || (name === NEWSLETTER_EMAIL_INPUT && !value && !isSubmitted)
            || (name === NEWSLETTER_CHECKBOX_INPUT && !isSubmitted)
        ) {
            return null;
        }

        return (
            <div block="Field" elem="ErrorMessages">
                { errorMessages.map((message, index) => this.renderErrorMessage.call(this, message, name + index)) }
            </div>
        );
    }

    // Renders validation error messages under field
    renderErrorMessage(message, key) {
        return <div block="Field" elem="ErrorMessage" key={ key }>{ __(message) }</div>;
    }

    /**
     * New method to render the password's show/hide button
     */
    renderPasswordEyeIcon(type) {
        const {
            showPassword, onClickShowPassword
        } = this.props;

        if (type === FIELD_TYPE.password) {
            return (
                <button
                  block="Field"
                  elem="PasswordEyeIcon"
                  type="button"
                  onClick={ onClickShowPassword }
                >
                    <EyeIcon isShown={ showPassword } isPassword />
                </button>
            );
        }

        return null;
    }

    /**
     * Overridden to add the shows/hide password icon for password fields only
     */
    renderDefaultInput() {
        const {
            type,
            setRef,
            attr,
            events,
            isDisabled,
            showPassword,
            isCheckout
        } = this.props;

        const shown = showPassword ? FIELD_TYPE.text : FIELD_TYPE.password;

        if (type === FIELD_TYPE.tel) {
            const toPassProps = {
                type,
                setRef,
                attr,
                events,
                isDisabled,
                isCheckout
            };

            return (
                <Suspense fallback={ null }>
                    <TelephoneField { ...toPassProps } />
                </Suspense>
            );
        }

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ type === FIELD_TYPE.password ? shown : type }
                  { ...attr }
                  { ...events }
                />
                { this.renderPasswordEyeIcon(type) }
            </>
        );
    }

    renderMultiSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            isSortSelect,
            isSearch,
            isShowHtmlOptions
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <FieldMultiSelect
                  attr={ attr }
                  events={ events }
                  options={ options }
                  setRef={ setRef }
                  isDisabled={ isDisabled }
                  isSortSelect={ isSortSelect }
                  changeValueOnDoubleClick={ changeValueOnDoubleClick }
                  isSearch={ isSearch }
                  isShowHtmlOptions={ isShowHtmlOptions }
                />
            </Suspense>
        );
    }

    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            isSortSelect,
            isSearch,
            isShowHtmlOptions,
            isDisableResetOnOptionsChange,
            triggerOnlyOnClick,
            isRemoveUnavailable
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <FieldSelectContainer
                  attr={ attr }
                  events={ events }
                  options={ options }
                  setRef={ setRef }
                  isDisabled={ isDisabled }
                  isSortSelect={ isSortSelect }
                  changeValueOnDoubleClick={ changeValueOnDoubleClick }
                  isSearch={ isSearch }
                  triggerOnlyOnClick={ triggerOnlyOnClick }
                  isShowHtmlOptions={ isShowHtmlOptions }
                  isDisableResetOnOptionsChange={ isDisableResetOnOptionsChange }
                  isRemoveUnavailable={ isRemoveUnavailable }
                />
            </Suspense>
        );
    }

    /**
     * Overridden to conditionally render a CMS block instead of checkbox label
     */
    renderCheckboxOrRadio() {
        const { cmsLabelId } = this.props;

        if (cmsLabelId) {
            return this.renderCheckboxOrRadioCmsLabel();
        }

        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label
        } = this.props;
        const { id = '', checked, value = '' } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };
        // if button value is "none" do not disable
        const isButtonDisabled = (!value.match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isButtonDisabled ? isDisabled : false }
                  type={ type }
                  { ...newAttr }
                  { ...inputEvents }
                    // shipping options have checked attr assigned so prioritize its value
                  defaultChecked={ isChecked }
                />
                <div block="input-control" disabled={ isDisabled } />
                { label }
            </label>
        );
    }

    /**
     * Renders CMS block instead of checkbox label
     */
    renderCheckboxOrRadioCmsLabel() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            cmsLabelId
        } = this.props;
        const { checked, value = '' } = newAttr;
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };
        // if button value is "none" do not disable
        const isButtonDisabled = (!value.match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <div block="Field" elem="CmsContainer">
                <div block="Field" elem="CmsInputElement">
                    <input
                      ref={ (elem) => setRef(elem) }
                      disabled={ isButtonDisabled ? isDisabled : false }
                      type={ type }
                      { ...newAttr }
                      { ...inputEvents }
                        // shipping options have checked attr assigned so prioritize its value
                      defaultChecked={ isChecked }
                    />
                    <div block="input-control" disabled={ isDisabled } />
                </div>
                <div block="Field" elem="CmsLabelElement">
                    <CmsBlock identifier={ cmsLabelId } />
                </div>
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getErrorConditions() {
        const { validationResponse, attr: { name }, isSubmitted } = this.props;

        if (!validationResponse) {
            return null;
        }

        if (name === 'newsletterEmail') {
            return !!validationResponse.value || isSubmitted;
        }

        return validationResponse !== true && Object.keys(validationResponse || {}).length !== 0;
    }

    /*
     * Overridden to render checkmarkicon
     */
    render() {
        const {
            type, validationResponse, mix
        } = this.props;
        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);
        const { mods: { hasError = false } = {} } = mix;
        const shouldRenderCheckmarkIcon = this.shouldRenderCheckmarkIconArray.includes(type);

        return (
            <div block="Field" elem="Wrapper" mods={ { type } }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: !hasError && validationResponse === true,
                      hasError: this.getErrorConditions()
                  } }
                  mix={ mix }
                >
                    { type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel() }
                    { shouldRenderCheckmarkIcon && <CheckmarkIcon /> }
                    { inputRenderer && inputRenderer() }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}

export default FieldComponent;
