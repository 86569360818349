/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const cancelOrderBeforeUpdating = async (args, callback) => {
    const { cancelPayfortOrder, getPayfortOrderFinished } = await import('../../util/Payfort.util');

    const { orderId, finished } = getPayfortOrderFinished() || {};

    // vvv Check if cached orderId is present and if order wasn't finished
    if (orderId && !finished) {
        // vvv This will also update localstorage to indicate order is finished
        await cancelPayfortOrder(orderId);
    }

    return callback(...args);
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            updateInitialCartData: cancelOrderBeforeUpdating
        }
    }
};
