import PropTypes from 'prop-types';

import {
    FieldNumberWithControls as SourceFieldNumberWithControls
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import { EventsType, FieldAttrType } from 'Type/Field.type';

import './FieldNumberWithControls.override.style';

/** @namespace Scandipwa/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends SourceFieldNumberWithControls {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        setRef: PropTypes.func.isRequired,
        handleValueChange: PropTypes.func.isRequired,
        stateValue: PropTypes.number.isRequired,
        isDisabled: PropTypes.bool.isRequired
    };
}

export default FieldNumberWithControlsComponent;
