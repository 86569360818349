import { getCountryCodeFromPhone } from 'Util/Address';

import {
    VALIDATION_INPUT_TYPE,
    VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_MESSAGES,
    VALIDATION_RULES
} from './Config';

export * from 'SourceUtil/Validator/Validator.js';

/**
 * Overridden to add the way to get validationRule when inputType === VALIDATION_INPUT_TYPE.phone
 */
/** @namespace Scandipwa/Util/Validator/validate */
export const validate = (value, rule) => {
    const {
        isRequired,
        inputType,
        match,
        range,
        fileExtension,
        customErrorMessages: {
            onRequirementFail,
            onInputTypeFail,
            onMatchFail,
            onRangeFailMin,
            onRangeFailMax,
            onExtensionFail
        } = {}
    } = rule;

    const output = {
        value,
        errorMessages: []
    };

    // #region IS REQUIRED
    if (isRequired && !value) {
        output.errorMessages.push(onRequirementFail || VALIDATION_MESSAGES.isRequired);
    }
    // #endregion

    // #region INPUT TYPE
    if (inputType && value) {
        if (inputType === VALIDATION_INPUT_TYPE.phone) {
            /* eslint-disable no-magic-numbers */
            const selectedCountryCode = getCountryCodeFromPhone(value);
            const remainingDigits = value.substring(4);

            const validationRule = VALIDATION_RULES[inputType][selectedCountryCode];

            if (!remainingDigits.match(validationRule)) {
                output.errorMessages.push(onInputTypeFail || VALIDATION_MESSAGES[inputType]);
            }
        } else if (!value.match(VALIDATION_RULES[inputType])) {
            output.errorMessages.push(onInputTypeFail || VALIDATION_MESSAGES[inputType]);
        }
    }
    // #endregion

    // #region MATCH
    if (typeof match === 'function') {
        const response = match(value);

        if (response !== true) {
            output.errorMessages.push(response === false ? onMatchFail || VALIDATION_MESSAGES.match : response);
        }
    } else if (match && !value.match(match)) {
        output.errorMessages.push(onMatchFail || VALIDATION_MESSAGES.match);
    }
    // #endregion

    // #region RANGE
    if (range) {
        const { min, max, showLengthError } = range;
        const isNumber = !!VALIDATION_INPUT_TYPE_NUMBER[inputType];

        if (isNumber) {
            if (min && +value < min) {
                output.errorMessages.push(onRangeFailMin || __('Minimal value is %s!', min));
            }

            if (max && +value > max) {
                output.errorMessages.push(onRangeFailMax || __('Maximum value is %s!', max));
            }
        } else {
            if (min && value.length < min && value.length > 0) {
                output.errorMessages.push(onRangeFailMin || __('Minimum %s characters!', min));
            }

            if (max && value.length > max) {
                const tooMany = value.length - max;

                output.errorMessages.push(onRangeFailMax || __('Maximum %s characters (%s too many)', max, tooMany));

                if (showLengthError) {
                    output.errorMessages.unshift(__('Please enter no more than %s characters.', max));
                }
            }
        }
    }

    if (fileExtension && value !== '') {
        const { accept } = fileExtension;
        const acceptedExtensions = accept.split(', ');
        const currentFileExtension = value.split('.').pop();

        if (!acceptedExtensions.includes(currentFileExtension)) {
            output.errorMessages.push(onExtensionFail || VALIDATION_MESSAGES.fileExtension);
        }
    }
    // #endregion

    const { errorMessages } = output;

    return errorMessages.length === 0 ? true : output;
};

export default validate;
