/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const addAmastyAutomaticRelatedProductsConfigToMapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        amastyARPConfig: state.ConfigReducer?.amAutomaticRelatedProducts
    };
};

export const addAmastyAutomaticRelatedProductsToContainerProps = (args, callback, instance) => {
    const { amastyARPConfig } = instance.props;

    return {
        ...callback(...args),
        amastyARPConfig
    };
};

export default {
    'Route/ProductPage/Container/mapStateToProps': {
        function: addAmastyAutomaticRelatedProductsConfigToMapStateToProps
    },
    'Route/ProductPage/Container': {
        'member-function': {
            containerProps: addAmastyAutomaticRelatedProductsToContainerProps
        }
    },
    'Component/Menu/Container/mapStateToProps': {
        function: addAmastyAutomaticRelatedProductsConfigToMapStateToProps
    },
    'Component/Menu/Container': {
        'member-function': {
            containerProps: addAmastyAutomaticRelatedProductsToContainerProps
        }
    },
    'Route/CartPage/Container/mapStateToProps': {
        function: addAmastyAutomaticRelatedProductsConfigToMapStateToProps
    },
    'Route/CartPage/Container': {
        'member-function': {
            containerProps: addAmastyAutomaticRelatedProductsToContainerProps
        }
    },
    'Route/CategoryPage/Container/mapStateToProps': {
        function: addAmastyAutomaticRelatedProductsConfigToMapStateToProps
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            containerProps: addAmastyAutomaticRelatedProductsToContainerProps
        }
    },
    'Component/ProductLinks/Container/mapStateToProps': {
        function: addAmastyAutomaticRelatedProductsConfigToMapStateToProps
    },
    'Component/ProductLinks/Container': {
        'member-function': {
            containerProps: addAmastyAutomaticRelatedProductsToContainerProps
        }
    }
};
