/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    Children,
    cloneElement
} from 'react';

import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';

import AmastyRmaInCart from '../../component/AmastyRmaInCart';

export const addAmastyReturnInformationToRender = (args, callback, instance) => {
    const {
        totals: {
            items = []
        },
        totals,
        checkoutStep,
        isMobile
    } = instance.props;
    const Component = callback(...args);

    if (
        !items.length
        || checkoutStep !== SHIPPING_STEP
        || !isMobile
    ) {
        return Component;
    }

    return cloneElement(
        Component,
        Component.props,
        Children.map(
            Component.props.children,
            (child, i) => {
                if (i === 0) {
                    return (
                        <>
                            { child }
                            <AmastyRmaInCart totals={ totals } />
                        </>
                    );
                }

                return child;
            }
        )
    );
};

export default {
    'Route/Checkout/Component': {
        'member-function': {
            render: addAmastyReturnInformationToRender
        }
    }
};
