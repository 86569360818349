/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import './FacebookIcon.style';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/FacebookIcon/Component */
export class FacebookIconComponent extends PureComponent {
    render() {
        return (
          <svg block="FacebookIcon" width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.5 2.00005C6.978 2.00005 2.5 6.50453 2.5 12.0604C2.501 17.081 6.157 21.2424 10.938
                22V14.9692H8.398V12.0604H10.938V9.8439C10.938 7.3205 12.431 5.92901 14.714 5.92901C15.808
                5.92901 16.954 6.12521 16.954 6.12521V8.5993H15.69C14.45 8.5993 14.062 9.37604 14.062
                10.1719V12.0584H16.833L16.39 14.9671H14.062V21.998C18.843 21.2444 22.499 17.082 22.499
                12.0604C22.499 6.50453 18.022 2.00005 12.5 2.00005Z"
              fill="#1877F2"
            />
          </svg>
        );
    }
}

export default FacebookIconComponent;
