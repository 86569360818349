/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    ClickOutside as SourceClickOutside
} from 'SourceComponent/ClickOutside/ClickOutside.component';

export * from 'SourceComponent/ClickOutside/ClickOutside.component';

/** @namespace Scandipwa/Component/ClickOutside/Component */
export class ClickOutsideComponent extends SourceClickOutside {
    componentDidMount() {
        setTimeout(() => {
            document.addEventListener('click', this.handleClick);
        }, 0);
    }

    handleClick({ target }) {
        const { onClick } = this.props;

        if (this.childrenRefs.every(
            (ref) => {
                const elementRef = ref.current?.overlayRef?.current || ref.current;

                return !elementRef?.contains(target);
            }
        )) {
            onClick();
        }
    }
}

export default ClickOutsideComponent;
