Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/amazon-payfort/src/plugin/PayfortPayments/AddPayfortPayments.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amazon-payfort/src/plugin/PayfortPayments/AddPayfortPayments.plugin.js')]);
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ExpressCheckoutMethodsList from './ExpressCheckoutMethodsList.component';

/** @namespace Scandipwa/Component/ExpressCheckoutMethodsList/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandipwa/Component/ExpressCheckoutMethodsList/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandipwa/Component/ExpressCheckoutMethodsList/Container */
export class ExpressCheckoutMethodsListContainer extends PureComponent {
    /**
    * Payment methods should plug-in here to be rendered.
    * Renderer object looks so:
    * @type {
    *  getIsApplicable: () => Boolean,
    *  position: Number,
    *  render: () => ReactElement
    * }
    */
    expressPaymentRenderMap = {};

    containerFunctions = { };

    filterAndSortApplicableMethods() {
        return Object.values(this.expressPaymentRenderMap)
            /**
             * Filter out any methods which are not applicable
             */
            .filter(({ getIsApplicable }) => getIsApplicable(this))
            /**
             * Sort methods by position attribute
             */
            .sort((prev, next) => prev.position - next.position)
            /**
             * Return renderer (it will be called in component)
             */
            .map(({ render }) => render);
    }

    containerProps() {
        return {
            expressPaymentRenderMap: this.expressPaymentRenderMap,
            methodRenderers: this.filterAndSortApplicableMethods()
        };
    }

    render() {
        return (
            <ExpressCheckoutMethodsList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpressCheckoutMethodsListContainer);
