/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CATEGORY_WIDGET, Widget } from '../../component/Widgets/Widgets';

const renderClerkWidget = (args, callback, instance) => {
    const { clerkConfig: { isCategoryEnabled } = {} } = instance.props;
    const isSearchPage = window.location.pathname.includes('/search');

    if (isSearchPage || !isCategoryEnabled) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <Widget type={ CATEGORY_WIDGET } />
        </>
    );
};

export const addClerkConfigState = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        clerkConfig: state.ConfigReducer?.clerkConfig
    };
};

export const addClerkConfigToContainerProps = (args, callback, instance) => {
    const { clerkConfig } = instance.props;

    return {
        ...callback(...args),
        clerkConfig
    };
};

export default {
    'Route/CategoryPage/Container/mapStateToProps': {
        function: addClerkConfigState
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            containerProps: addClerkConfigToContainerProps
        }
    },
    'Route/CategoryPage/Component': {
        'member-function': {
            render: renderClerkWidget
        }
    }
};
