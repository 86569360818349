/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import SocialLoginButtons from '../component/SocialLoginButtons';

const addSocialButtonsToRender = (args, callback, _instance) => (
    <>
        { callback(...args) }
        <SocialLoginButtons />
    </>
);

export default {
    'Component/MyAccountSignIn/Component': {
        'member-function': {
            render: addSocialButtonsToRender
        }
    },
    'Route/LoginAccount/Component': {
        'member-function': {
            renderSpecial: addSocialButtonsToRender
        }
    },
    'Scandipwa/Route/CheckoutLoginPage/Component': {
        'member-function': {
            renderContinueAsGuestButton: addSocialButtonsToRender
        }
    }
};
