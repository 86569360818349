import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { RESET_PASSWORD_POPUP_ID } from 'Component/ResetPasswordPopup/ResetPasswordPopup.config';
import { BILLING_URL, SHIPPING_URL } from 'Route/Checkout/Checkout.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import { showPopup } from 'Store/Popup/Popup.action';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import { FORGET_PASSWORD_POPUP_ID } from './MyAccountSignIn.config';

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isCurrentUserMigratedWithoutPasswordSet: state.MyAccountReducer.isCurrentUserMigratedWithoutPasswordSet
});

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showForgetPasswordPopup: (payload) => dispatch(showPopup(FORGET_PASSWORD_POPUP_ID, payload)),
    showPopup: (id, payload) => dispatch(showPopup(id, payload))
});

/** @namespace Scandipwa/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        ...this.propTypes,
        showPopup: PropTypes.func.isRequired,
        showForgetPasswordPopup: PropTypes.func.isRequired,
        isCurrentUserMigratedWithoutPasswordSet: PropTypes.bool.isRequired
    };

    /**
     * New method to handle no password customer
     */
    handleNoPasswordCustomer(fields) {
        const { showPopup } = this.props;
        const customerEmailField = fields.find((field) => {
            const { name } = field;

            return name === 'email';
        });
        const { value: customerEmail } = customerEmailField;

        this.setState({ isSignIn: false });
        showPopup(
            RESET_PASSWORD_POPUP_ID,
            {
                title: __('Get password link'),
                customerEmail
            }
        );
    }

    containerFunctions = {
        ...this.containerFunctions,
        handleForgetPasswordPopupClick: this.handleForgetPasswordPopupClick.bind(this)
    };

    /**
     * Overridden to:
     * - Set isSignIn state to false and disable onSignIn usage if signInResult is false
     * - Move redirecting to checkout page functionality to condition where signInResult and (is_virtual && isCheckout) is true
     * and no error on signIn
     */
    async onSignInSuccess(form, fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState,
            totals: { is_virtual },
            isCheckout
        } = this.props;
        const {
            isSignIn
        } = this.state;

        setLoadingState(true);

        if (!isSignIn) {
            const fieldPairs = transformToNameValuePair(fields);

            this.setState({ isSignIn: true });

            try {
                const signInResult = await signIn(fieldPairs);

                if (!signInResult) {
                    this.handleNoPasswordCustomer(fields);
                } else {
                    onSignIn();

                    if (is_virtual && isCheckout) {
                        history.push({ pathname: appendWithStoreCode(BILLING_URL) });
                    } else if (!is_virtual && isCheckout) {
                        history.push({ pathname: appendWithStoreCode(SHIPPING_URL) });
                    }
                }
            } catch (error) {
                showNotification('error', getErrorMessage(error));
                this.setState({ isSignIn: false });
            } finally {
                setLoadingState(false);
            }
        }

        setLoadingState(false);
    }

    /**
     * New method to open forget password popup
     */
    handleForgetPasswordPopupClick(e) {
        const { showForgetPasswordPopup } = this.props;

        e.preventDefault();
        e.stopPropagation();

        showForgetPasswordPopup({});
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
