import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import PopupStandard from 'Component/PopupStandard';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { RESET_PASSWORD_POPUP_ID } from './ResetPasswordPopup.config';

import './ResetPasswordPopup.style';

/** @namespace Scandipwa/Component/ResetPasswordPopup/Component */
export class ResetPasswordPopupComponent extends PureComponent {
    static propTypes = {
        handleResetPasswordFormValidationSuccess: PropTypes.func.isRequired,
        handleResetPasswordFormValidationError: PropTypes.func.isRequired,
        customerEmail: PropTypes.string.isRequired,
        handleCustomerEmailChange: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    /**
     * New method to render reset password text
     */
    renderResetPasswordText() {
        return (
            <div block="ResetPasswordPopup" elem="ResetPasswordText">
                <span>
                    { __('Welcome to our new site! ') }
                </span>
                <span>
                    { __('Please reset your password by clicking the send button below.') }
                </span>
            </div>
        );
    }

    /**
     * New method to render content
     */
    renderContent() {
        const { isLoading } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading } />
                { this.renderResetPasswordText() }
                { this.renderResetPasswordForm() }
            </>
        );
    }

    /**
     * New method to render reset password form
     */
    renderResetPasswordForm() {
        const {
            handleResetPasswordFormValidationSuccess,
            handleResetPasswordFormValidationError,
            customerEmail,
            handleCustomerEmailChange,
            isMobile
        } = this.props;

        return (
            <Form
              key="reset-password"
              onSubmit={ handleResetPasswordFormValidationSuccess }
              onError={ handleResetPasswordFormValidationError }
            >
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      class: 'ResetPassword-Input_type_email',
                      placeholder: __('Your email address'),
                      autocomplete: 'email',
                      value: customerEmail
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                  events={ {
                      onChange: handleCustomerEmailChange
                  } }
                />
                <button
                  block="ResetPasswordPopup"
                  elem="ResetPasswordButton"
                  type="submit"
                  mix={ {
                      block: 'Button',
                      mods: { isMedium: isMobile }
                  } }
                >
                    { __('Send reset link') }
                </button>
            </Form>
        );
    }

    render() {
        return (
            <Popup
              id={ RESET_PASSWORD_POPUP_ID }
              mix={ { block: 'ResetPasswordPopup' } }
            >
                <PopupStandard>
                    { this.renderContent() }
                </PopupStandard>
            </Popup>
        );
    }
}

export default ResetPasswordPopupComponent;
