import TextPlaceholder from 'Component/TextPlaceholder';
import history from 'Util/History';

const renderCategoryText = (args, callback, instance) => {
    const { location: { state: { categoryTitle } = {} } } = history;
    const {
        actionName: {
            name: preloadedName
        }
    } = window;

    const {
        category: { name, category_seo_name },
        isCurrentCategoryLoaded,
        useCategorySeoName
    } = instance.props;

    const categoryName = useCategorySeoName ? category_seo_name || name : name;

    if (categoryTitle || isCurrentCategoryLoaded || preloadedName) {
        return (
            <TextPlaceholder content={ categoryTitle || categoryName || preloadedName } />
        );
    }

    return (
        <TextPlaceholder />
    );
};

export default {
    'Component/CategoryDetails/Component': {
        'member-function': {
            renderCategoryText
        }
    }
};
