/**
 * MageAnts Sample Product compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { getCartId } from 'Util/Cart';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import AddSampleProductQuery from '../query/AddSampleProduct.query';

/** @namespace Scandiweb/MageantsSampleProduct/Util/AddSampleToCart/addSampleToCart */
export const addSampleToCart = async (userCartId, products, dispatch, instance) => {
    const cartId = userCartId || getCartId();

    if (!Array.isArray(products) || products.length === 0) {
        dispatch(showNotification('error', __('No product data!')));

        return Promise.reject();
    }

    try {
        if (!cartId) {
            return Promise.reject();
        }
        // vvv Plugin was created to change this query
        const { addSampleProductToCart: { user_errors: errors = [] } = {} } = await fetchMutation(
            AddSampleProductQuery.getSampleProductToCartMutation(cartId, products)
        );

        if (Array.isArray(errors) && errors.length > 0) {
            errors.forEach((error) => {
                dispatch(showNotification('error', getErrorMessage(error)));
            });

            return Promise.reject();
        }

        await instance.updateInitialCartData(dispatch);
        dispatch(showNotification('success', __('Product was added to cart!')));
    } catch (error) {
        if (!navigator.onLine) {
            dispatch(showNotification('error', __('Not possible to fetch while offline')));

            return Promise.reject();
        }

        dispatch(showNotification('error', getErrorMessage(error)));

        return Promise.reject();
    }

    return Promise.resolve();
};
