/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const addSendCartEvent = async (args, callback) => {
    const [cartData] = args;
    const { items } = cartData;

    if (!items) {
        callback(...args);
        return;
    }

    const iIds = items.map(({ id }) => id).sort((a, b) => a - b);

    const { sendCartEvent } = await import('../../util/tracking');

    sendCartEvent({ productIds: iIds });
    callback(...args);
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData: addSendCartEvent
        }
    }
};
