import PropTypes from 'prop-types';
import { createRef, lazy, Suspense } from 'react';

import ChevronIcon from 'Component/ChevronIcon/ChevronIcon.component';
import {
    BOTTOM, TOP
} from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside/ClickOutside.component';
import CloseIcon from 'Component/CloseIcon';
import FIELD_TYPE from 'Component/Field/Field.config';
import { FieldSelectComponent } from 'Component/FieldSelect/FieldSelect.component';

import './FieldMultiSelect.override.style';

export const Field = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "field" */
    'Component/Field'
));

/** @namespace Scandipwa/Component/FieldMultiSelect/Component */
export class FieldMultiSelectComponent extends FieldSelectComponent {
    static propTypes = {
        ...this.propTypes,
        selectedOptions: PropTypes.objectOf({
            id: PropTypes.string,
            value: PropTypes.string,
            label: PropTypes.string
        }).isRequired
    };

    scrollRef = createRef();

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    scrollLeft() {
        if (this.scrollRef.current) {
            this.scrollRef.current.scrollLeft -= 50;
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    scrollRight() {
        if (this.scrollRef.current) {
            this.scrollRef.current.scrollLeft += 50;
        }
    }

    scrollLeft = this.scrollLeft.bind(this);

    scrollRight = this.scrollRight.bind(this);

    renderSelectedOption(option) {
        if (option === false) {
            return null;
        }

        const { value, id } = option;
        const {
            handleSelectListOptionClick
        } = this.props;

        return (
            <div block="FieldMultiSelect" elem="SelectedOption" key={ id }>
                { value }
                <button
                  onMouseDown={ handleSelectListOptionClick(option) }
                  onKeyPress={ handleSelectListOptionClick(option) }
                >
                    <CloseIcon />
                </button>
            </div>
        );
    }

    renderSearch() {
        const {
            isSearch,
            isDisabled,
            isExpanded,
            filter,
            handleSearchChange,
            focusSelect,
            handleSearchBlur,
            handleSearchFocus,
            placeholder,
            attr: { defaultValue },
            selectedOptions = {}
        } = this.props;

        if (!isSearch) {
            return null;
        }

        const selectedOptionsArray = Object.values(selectedOptions) || [];

        return (
            <div block="FieldSelect" elem="SelectInputContainer">
                <div
                  block="FieldSelect"
                  elem="Input"
                  mix={ { block: 'FieldMultiSelect', elem: 'Input' } }
                >
                    { selectedOptionsArray.map(this.renderSelectedOption.bind(this)) }
                    <div block="FieldMultiSelect" elem="InputOption">
                        <input
                          disabled={ isDisabled }
                          type="text"
                          defaultValue={ defaultValue }
                          value={ filter }
                          onChange={ handleSearchChange }
                          onClick={ focusSelect }
                          onBlur={ handleSearchBlur }
                          onFocus={ handleSearchFocus }
                          placeholder={ placeholder }
                        />
                    </div>
                </div>
                <div block="FieldMultiSelect" elem="ChevronContainer">
                    <ChevronIcon direction={ isExpanded ? TOP : BOTTOM } />
                </div>
            </div>

        );
    }

    renderOption(option) {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            isShowHtmlOptions,
            selectedOptions = {}
        } = this.props;

        if (isPlaceholder) {
            return (
                <li
                  block="FieldSelect"
                  elem="Option"
                  mods={ {
                      isExpanded,
                      isPlaceholder,
                      isShowOnMobile: isShowHtmlOptions
                  } }
                  key={ id }
                  id={ `o${id}` }
                  role="menuitem"
                  onMouseDown={ handleSelectListOptionClick(option) }
                  onKeyPress={ handleSelectListOptionClick(option) }
                  tabIndex={ isExpanded ? '0' : '-1' }
                >
                    <span block="FieldSelect" elem="PlaceHolderSpan">
                        { label }
                    </span>
                </li>
            );
        }

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mix={ { block: 'FieldMultiSelect', elem: 'Option' } }
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered,
                  isShowOnMobile: isShowHtmlOptions
              } }
              key={ id }
              id={ `o${id}` }
              role="menuitem"
              onMouseDown={ handleSelectListOptionClick(option) }
              onKeyPress={ handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                <Suspense fallback={ null }>
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      mix={ { block: 'FieldMultiSelect', elem: 'Checkbox' } }
                      attr={ { checked: selectedOptions[`${id}`] !== undefined && selectedOptions[`${id}`] !== false } }
                    />
                </Suspense>
                { label }
                { subLabel && <strong>{ ` ${subLabel}` }</strong> }
            </li>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled,
            isSearch
        } = this.props;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${id}_wrapper` }
                  block="FieldSelect"
                  mods={ {
                      isExpanded,
                      isSearch,
                      isDisabled
                  } }
                  mix={ { block: 'FieldMultiSelect' } }
                  onClick={ !isDisabled && handleSelectExpand }
                  onKeyPress={ !isDisabled && handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderSortSelect() }
                        { this.renderNativeSelect() }
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldMultiSelectComponent;
