import Html from 'Component/Html';
import {
    CmsBlock as SourceCmsBlock
} from 'SourceComponent/CmsBlock/CmsBlock.component';

import './CmsBlock.override.style';

export * from 'SourceComponent/CmsBlock/CmsBlock.component';

/** @namespace Scandipwa/Component/CmsBlock/Component */
export class CmsBlockComponent extends SourceCmsBlock {
    renderPlaceholder() {
        const {
            children,
            fallback
        } = this.props;

        if (fallback) {
            return fallback();
        }

        if (children.length) {
            return children;
        }

        return null;
    }

    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled
            },
            isMenuImagesBlock,
            blockType,
            fallback,
            onImageLoad
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
              mods={ { type: blockType } }
            >
                <Html
                  content={ content }
                  isMenuImagesBlock={ isMenuImagesBlock }
                  fallback={ fallback }
                  onImageLoad={ isMenuImagesBlock ? onImageLoad : null }
                />
            </div>
        );
    }
}

export default CmsBlockComponent;
