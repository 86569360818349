import InventoryQuery from 'Query/Inventory.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { prepareQuery } from 'Util/Query';
import {
    executeGet
} from 'Util/Request';

import {
    selectPickupStore,
    selectPickupStoreInCheckout,
    setPickupStores
} from './ClickAndCollect.action';

export const ONE_MONTH_IN_SECONDS = 2592000;

/** @namespace Scandipwa/Store/ClickAndCollect/Dispatcher */
export class ClickAndCollectDispatcher {
    async getPickupStores(dispatch) {
        try {
            const {
                getAnyStores: {
                    items
                }
            } = await executeGet(
                prepareQuery(InventoryQuery.getAnyStores({
                    productsInfo: [],
                    isPickupLocation: true
                })),
                'PickupStores',
                ONE_MONTH_IN_SECONDS
            );

            dispatch(setPickupStores(items));
        } catch (e) {
            dispatch(showNotification('error', __('Error fetching pickup stores')));
        }
    }

    selectPickupStore(dispatch, store) {
        dispatch(selectPickupStore(store));
        dispatch(selectPickupStoreInCheckout(store));
    }

    selectPickupStoreInCheckout(dispatch, store) {
        dispatch(selectPickupStoreInCheckout(store));
    }
}

export default new ClickAndCollectDispatcher();
