/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import PopupStandard from 'Component/PopupStandard';

import {
    Payfort3DUrlReplacement,
    PayfortElements,
    PayfortMerchantPageEvents
} from '../../Payfort.config';
import { ElementRefType, PayfortData, TokenParams } from '../../type/Payfort.type';
import { camelToSnakeCase } from '../../util/Payfort.util';

import './MerchantPage.style';

/** @namespace Scandiweb/AmazonPayfort/Component/MerchantPage/Component */
export class MerchantPageComponent extends PureComponent {
    static propTypes = {
        formData: PropTypes.oneOfType([
            PayfortData,
            TokenParams
        ]).isRequired,
        formUrl: PropTypes.string.isRequired,
        formRef: ElementRefType.isRequired,
        setLoadingToFalse: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        event: PropTypes.string.isRequired
    };

    renderDataForm() {
        const {
            formData = {},
            formUrl,
            formRef,
            event
        } = this.props;

        return (
            <form
              ref={ formRef }
              method="post"
              action={ formUrl }
              style={ { display: 'none' } }
              target={ event === PayfortMerchantPageEvents.NEW_TOKEN ? '_blank' : PayfortElements.MERCHANT_PAGE }
            >
                { Object.keys(formData).map((dataName) => {
                    const dataValue = formData[dataName];
                    if (!dataValue) {
                        return null;
                    }

                    const name = dataName === Payfort3DUrlReplacement.OLD
                        ? Payfort3DUrlReplacement.NEW
                        : camelToSnakeCase(dataName);

                    return (
                        <input
                          type="hidden"
                          name={ name }
                          value={ dataValue }
                          key={ dataName }
                        />
                    );
                }) }
            </form>

        );
    }

    renderIframe() {
        const {
            setLoadingToFalse,
            event
        } = this.props;

        if (event === PayfortMerchantPageEvents.NEW_TOKEN) {
            return null;
        }

        return (
            <iframe
              block="MerchantPage"
              elem="Iframe"
              title={ PayfortElements.MERCHANT_PAGE }
              name={ PayfortElements.MERCHANT_PAGE }
              id={ PayfortElements.MERCHANT_PAGE }
              frameBorder="0"
              onLoad={ setLoadingToFalse }
            />
        );
    }

    render() {
        const {
            handleCancel,
            isLoading
        } = this.props;

        return (
            <Popup
              id={ PayfortElements.PAYMENT_POPUP }
              mix={ { block: 'PayfortWindowPopup' } }
              isCloseOnOutsideClick={ false }
              onClose={ handleCancel }
            >
                <PopupStandard>
                    <div block="MerchantPage">
                        <Loader isLoading={ isLoading } />
                        { this.renderDataForm() }
                        { this.renderIframe() }
                    </div>
                </PopupStandard>
            </Popup>
        );
    }
}

export default MerchantPageComponent;
