import PropTypes from 'prop-types';
import { memo } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    static propTypes = {
        ...this.propTypes,
        pdpHeading: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        pdpHeading: null,
        isGoBackOnHide: true,
        isFreezeScrollOnMobile: false
    };

    componentDidUpdate() {
        const openedPopup = document.querySelector('.Popup_isVisible');

        if (openedPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }

    /**
     * New method to add resetScrollToTop usage
     */
    componentWillUnmount() {
        if (parseInt(document.body.style.marginTop, 10)) {
            this.resetScrollToTop();
        }
    }

    containerProps() {
        const {
            pdpHeading,
            isGoBackOnHide,
            isFreezeScrollOnMobile
        } = this.props;

        return {
            ...super.containerProps(),
            titleIcon: this._getPopupTitleIcon(),
            isGoBackOnHide,
            pdpHeading,
            isFreezeScrollOnMobile
        };
    }

    /**
     * New method to get pop-up title icon
     */
    _getPopupTitleIcon() {
        const { payload, activeOverlay } = this.props;
        const { titleIcon } = payload[activeOverlay] || {};

        return titleIcon;
    }

    /**
     * New method to reset scroll to top, to reset freezeScroll effect executed on Overlay.container file
     */
    resetScrollToTop() {
        document.body.style.marginTop = '0px';
    }
}

export const MemoizedPopupContainer = memo(PopupContainer, (prevProps, nextProps) => {
    const {
        mix,
        ...restProps
    } = prevProps;

    const {
        mix: nextMix,
        ...restNextProps
    } = nextProps;

    if (
        JSON.stringify(mix) !== JSON.stringify(nextMix)
    ) {
        return false;
    }

    const restPropsAreNotEqual = Object.keys(nextProps).some(
        (key) => (typeof restProps[key] === 'function' ? false : restProps[key] !== restNextProps[key])
    );

    return !restPropsAreNotEqual;
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedPopupContainer);
