/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Pan Emirates
 * @author      Ruslan Peka <ruslan.peka@scandiweb.com>
 */

export const UPDATE_CATEGORY_DROPDOWN_STATE = 'UPDATE_CATEGORY_DROPDOWN_STATE';

/** @namespace Scandipwa/Store/CategoryDropdown/Action/updateCategoryDropdownState */
export const updateCategoryDropdownState = (isCategoryDropdownExpanded) => ({
    type: UPDATE_CATEGORY_DROPDOWN_STATE,
    isCategoryDropdownExpanded
});
