import { FIELD_TYPE_ATTR } from 'Component/DateSelect/DateSelect.config';
import FIELD_TYPE from 'Component/Field/Field.config';
import { FIELD_DATE_TYPE } from 'Component/FieldDate/FieldDate.config';
import { TELEPHONE_COUNTRY_CODE_FIELD_NAME } from 'Component/TelephoneField/TelephoneField.config';
import {
    getDateValue,
    groupDateFieldsData,
    transformDateFieldsData
} from 'SourceUtil/Form/Extract';
import { changePhoneNumber } from 'Util/Address';

export * from 'SourceUtil/Form/Extract';

/**
 * Overridden to add changePhoneNumber usage when field type === FIELD_TYPE.tel
 */
/** @namespace Scandipwa/Util/Form/Extract/getFieldsData */
export const getFieldsData = (DOM, excludeEmpty = false, ignoreTypes = [], asObject = false) => {
    const fields = DOM.querySelectorAll('input, textarea, select');
    const output = [];
    const dateFieldsGrouped = groupDateFieldsData(fields);

    output.push(...transformDateFieldsData(dateFieldsGrouped));

    fields.forEach((field) => {
        if (Object.values(FIELD_DATE_TYPE).includes(field.getAttribute(FIELD_TYPE_ATTR))) {
            return;
        }

        const { tagName } = field;
        const tag = tagName.toLowerCase();
        const type = tag === FIELD_TYPE.textarea || tag === FIELD_TYPE.select || tag === FIELD_TYPE.button
            ? tag : field.type;

        if (ignoreTypes.some((ignoreType) => ignoreType === type)) {
            return;
        }

        // Disabled ESLint fp/no-let rule to use 'let'
        /* eslint-disable fp/no-let */
        let value = null;
        /* eslint-enable fp/no-let */

        if (type === FIELD_TYPE.checkbox || type === FIELD_TYPE.radio) {
            const { checked, value: fieldValue } = field;

            if (checked) {
                if (fieldValue === 'on') {
                    value = true;
                } else {
                    value = fieldValue;
                }
            } else {
                value = false;
            }
        } else if (type === FIELD_TYPE.file) {
            const { fileData } = field;

            value = fileData;
        } else if (type === FIELD_TYPE.tel) {
            const { value: fieldValue } = field;
            const selectCountryCodeElement = DOM.querySelector(
                `select[name="${TELEPHONE_COUNTRY_CODE_FIELD_NAME}"]`
            );
            const { value: selectedCountryCode } = selectCountryCodeElement || {};
            const changePhoneNumberOptions = {
                telephoneCountryCode: selectedCountryCode
            };
            const changedPhoneNumber = changePhoneNumber(fieldValue, changePhoneNumberOptions);

            value = changedPhoneNumber;
        } else {
            const { value: fieldValue } = field;

            value = fieldValue;
        }

        const dateValue = field.getAttribute('data-date');
        const formattedValue = dateValue ? getDateValue(dateValue) : value;

        if (!excludeEmpty || value) {
            output.push({
                name: field.name,
                type,
                value: formattedValue,
                field
            });
        }
    });

    if (asObject) {
        const objectOutput = {};

        output.forEach((field) => {
            const { name } = field;

            objectOutput[name] = field;
        });

        return objectOutput;
    }

    return output;
};

/** @namespace Scandipwa/Util/Form/Extract/getIsvalidPhone */
export const getIsvalidPhone = (phoneNumber) => {
    const toNumbers = +phoneNumber;
    const storeCode = window?.storeConfig?.store_code || '';

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(toNumbers)) {
        return false;
    }

    const phoneLength = toNumbers?.toString()?.length;
    // eslint-disable-next-line no-magic-numbers
    const validAmount = storeCode.indexOf('oman') !== -1 || storeCode.indexOf('qatar') !== -1 ? 8 : 9;

    // eslint-disable-next-line no-magic-numbers
    if (phoneLength === validAmount) {
        return true;
    }

    return false;
};

export default getFieldsData;
