/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import {
    LocationType
} from '../../types/ReCaptcha.types';

import './ReCaptcha.style';

/** @namespace Scandiweb/GoogleRecaptcha/Component/ReCaptcha/Component */
export class ReCaptchaComponent extends PureComponent {
    static propTypes = {
        location: LocationType
    };

    static defaultProps = {
        location: {}
    };

    render() {
        const {
            location,
            location: {
                id = '',
                type = ''
            } = {}
        } = this.props;

        if (!location) {
            return null;
        }

        if (type.indexOf('INVISIBLE') !== -1) {
            return (
                <div
                  block="ReCaptcha"
                  elem="Invisible"
                  id={ id }
                />
            );
        }

        return (
            <div
              block="ReCaptcha"
              id={ id }
            />
        );
    }
}

export default ReCaptchaComponent;
