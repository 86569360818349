import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import { CART_OVERLAY } from 'Component/Header/Header.config';
import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';
import { toggleScroll } from 'Util/Browser';

import './Overlay.override.style';

/** @namespace Scandipwa/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    static propTypes = {
        ...this.propTypes,
        isFreezeScrollOnMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        isFreezeScrollOnMobile: false
    };

    /**
     * Overridden to remove isMobile condition in order to freeze scroll when overlay isVisible
     * Disabled eslint error to override a non rendering methods
     */
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    onVisible() {
        const { onVisible, isStatic } = this.props;

        if (isStatic) {
            return;
        }

        this.freezeScroll();

        if (this.overlayRef.current) {
            this.overlayRef.current.focus();
        }

        onVisible();
    }
    /* eslint-enable @scandipwa/scandipwa-guidelines/only-render-in-component */

    /**
     * Overridden to remove isMobile condition in order to freeze scroll when overlay isVisible
     * Disabled eslint error to override a non rendering methods
     */
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    onHide() {
        const { onHide, isStatic } = this.props;

        if (isStatic) {
            return;
        }
        this.unfreezeScroll();

        onHide();
    }
    /* eslint-enable @scandipwa/scandipwa-guidelines/only-render-in-component */

    /**
     * Created portal for rendering background layer div inside Header.
     */
    renderLayerPortal(content) {
        return createPortal(content, document.querySelector('.Header'));
    }

    /**
     * Created to add background when overlay is visible
     */
    renderBackgroundLayer() {
        const { id } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible || id !== CART_OVERLAY) {
            return false;
        }

        return this.renderLayerPortal(<div block="Overlay" elem="BgLayer" />);
    }

    /**
     * Overridden to prevent freezeScroll on mobile view by default
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    freezeScroll() {
        const { isFreezeScrollOnMobile } = this.props;

        if (super.freezeScroll && isFreezeScrollOnMobile) {
            super.freezeScroll();
        }
    }

    /**
     * Overridden to fix YoffsetWhenScrollDisabled when it's undefined
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    unfreezeScroll() {
        const { isFreezeScrollOnMobile } = this.props;

        if (!isFreezeScrollOnMobile) {
            return;
        }

        toggleScroll(true);
        document.body.style.marginTop = 0;

        if (!this.YoffsetWhenScrollDisabled) {
            this.YoffsetWhenScrollDisabled = window.scrollY;
        }

        window.scrollTo(0, this.YoffsetWhenScrollDisabled);
    }

    /**
     * Overridden to add background layer.
     */
    render() {
        return (
            <>
                { this.renderBackgroundLayer() }
                { super.render() }
            </>
        );
    }
}

export default OverlayComponent;
