import AreasQuery from 'Query/Areas.query';
import { updateAreas } from 'Store/Areas/Areas.action';
import { QueryDispatcher } from 'Util/Request';
import { getCountryAreaCodeByWebsiteCode } from 'Util/StoreHelper';

/** @namespace Scandipwa/Store/Areas/Dispatcher */
export class AreasDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct('Areas');
    }

    onSuccess(data, dispatch) {
        const { PanGetAreas = [] } = data;

        dispatch(updateAreas(PanGetAreas));
    }

    onError(error, dispatch) {
        dispatch(updateAreas([]));

        return error;
    }

    prepareRequest() {
        return AreasQuery.getAreas({ countryCode: getCountryAreaCodeByWebsiteCode() });
    }
}

export default new AreasDispatcher();
