/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CartQuery from 'Query/Cart.query';
import OrderQuery from 'Query/Order.query';
import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmazonPayfort/Query/PayfortSendAppleCartDataToAps/Query */
export class PayfortSendAppleCartDataToApsQuery {
    getPayfortSendAppleCartDataToAps({
        cart_id,
        shippingContact,
        token
    }) {
        return new Field('payfortSendAppleCartDataToAps')
            .addArgument('input', 'PayfortSendAppleCartDataToApsInput!', {
                cart_id,
                shippingContact,
                paymentTokenData: token
            })
            .addFieldList(this.getAppleCartDataTransactionFields());
    }

    getAppleCartDataTransactionFields() {
        return [
            'orderId',
            'errorMessage',
            'shippingMethod',
            'deliveryDate',
            CartQuery._getCart(),
            // vvv BE for pan_reference_code is in Panemirates/ErpId
            'pan_reference_code'
        ];
    }

    getOrderField() {
        return new Field('order')
            .addFieldList(this.getOrderFields());
    }

    getOrderFields() {
        return OrderQuery._getOrderItemsFields(true);
    }
}

export default new PayfortSendAppleCartDataToApsQuery();
