import { PICKUP_STORE_KEY } from 'Component/Pickup/Pickup.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import { UPDATE_SELECTED_SLOT, UPDATE_SELECTED_STORE_ADDRESS } from './CheckoutDelivery.config';

/** @namespace Scandipwa/Store/CheckoutDelivery/Reducer/getInitialState */
export const getInitialState = () => ({
    selectedStoreAddress: BrowserDatabase.getItem(PICKUP_STORE_KEY),
    selectedSlot: null
});

/** @namespace Scandipwa/Store/CheckoutDelivery/Reducer/CheckoutDeliveryReducer */
export const CheckoutDeliveryReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
    case UPDATE_SELECTED_STORE_ADDRESS:
        return {
            ...state,
            selectedStoreAddress: action.address
        };
    case UPDATE_SELECTED_SLOT:
        return {
            ...state,
            selectedSlot: action.slot
        };
    default:
        return state;
    }
};

export default CheckoutDeliveryReducer;
