/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmazonPayfort/Query/PayfortOrderCancel/Query */
export class PayfortOrderCancelQuery {
    getPayfortOrderCancelField(orderId, cartId = null) {
        if (!orderId) {
            return new Field('payfortOrderCancel')
                .addArgument('input', 'PayfortOrderCancelInput', { cartId })
                .addFieldList(this.getPayformOrderCancelFields());
        }

        return new Field('payfortOrderCancel')
            .addArgument('input', 'PayfortOrderCancelInput', { orderId })
            .addFieldList(this.getPayformOrderCancelFields());
    }

    getPayformOrderCancelFields() {
        return [
            'cartId'
        ];
    }
}

export default new PayfortOrderCancelQuery();
