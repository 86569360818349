import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ClickAndCollectReducer from 'Store/ClickAndCollect/ClickAndCollect.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { StoreType } from 'Type/Checkout.type';
import { TotalsType } from 'Type/MiniCart.type';
import { getAllCartItemsSku } from 'Util/Cart';
import { withReducers } from 'Util/DynamicReducer';

import DeliveryOptionPickup from './DeliveryOptionPickup.component';

export const ClickAndCollectDispatcher = import(
    /* webpackMode: "eager"*/
    'Store/ClickAndCollect/ClickAndCollect.dispatcher'
);

/** @namespace Scandipwa/Component/DeliveryOptionPickup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    pickupStores: state.ClickAndCollectReducer.pickupStores,
    totals: state.CartReducer.cartTotals,
    selectedStore: state.ClickAndCollectReducer.selectedPickupStore,
    shippingFields: state.CheckoutReducer?.shippingFields
});

/** @namespace Scandipwa/Component/DeliveryOptionPickup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getPickupStores: (items) => ClickAndCollectDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getPickupStores(dispatch, items)
    ),
    selectPickupStore: (store) => ClickAndCollectDispatcher.then(
        ({ default: dispatcher }) => dispatcher.selectPickupStore(dispatch, store)
    )
});

/** @namespace Scandipwa/Component/DeliveryOptionPickup/Container */
export class DeliveryOptionPickupContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        getPickupStores: PropTypes.func.isRequired,
        pickupStores: PropTypes.arrayOf(StoreType).isRequired,
        totals: TotalsType.isRequired,
        selectedStore: StoreType.isRequired,
        selectPickupStore: PropTypes.func.isRequired,
        shippingFields: StoreType.isRequired
    };

    containerFunctions = {
        onPickupStoreSelect: this.onPickupStoreSelect.bind(this)
    };

    state = {
        isPlaceholder: true
    };

    componentDidMount() {
        this.getPickupStores();
    }

    async getPickupStores() {
        const {
            totals: {
                items
            },
            getPickupStores
        } = this.props;

        await getPickupStores(getAllCartItemsSku(items));
    }

    setCustomerDataOnStore(store) {
        const {
            shippingFields: {
                firstname = '',
                telephone = ''
            } = {}
        } = this.props;

        const updatedStore = store;
        updatedStore.storeName = (store?.storeName || store.name)?.trim();
        updatedStore.name = firstname?.trim();
        updatedStore.phone = telephone;

        return updatedStore;
    }

    containerProps() {
        const {
            pickupStores = [],
            selectedStore
        } = this.props;
        const { isPlaceholder } = this.state;

        return {
            pickupStores: pickupStores ? pickupStores.map(this.setCustomerDataOnStore.bind(this)) : null,
            selectedStore: selectedStore ? this.setCustomerDataOnStore(selectedStore) : null,
            isPlaceholder
        };
    }

    onPickupStoreSelect(code) {
        const {
            selectPickupStore,
            pickupStores
        } = this.props;

        selectPickupStore(pickupStores?.find(
            ({ pickup_location_code }) => pickup_location_code === code
        ));
    }

    render() {
        return (
            <DeliveryOptionPickup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    ClickAndCollectReducer
})(connect(mapStateToProps, mapDispatchToProps)(DeliveryOptionPickupContainer));
