import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { PDP } from 'Component/Header/Header.config';
import { SORT_DIRECTION_TYPE } from 'Route/CategoryPage/CategoryPage.config';
import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import CategoryReducer from 'Store/Category/Category.reducer';
import MenuReducer from 'Store/Menu/Menu.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import { MenuType } from 'Type/Menu.type';
import { BreadcrumbsType } from 'Type/ProductList.type';
import { scrollToTop } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { getMenuItem } from 'Util/MenuHelper/MenuHelper';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import { getQueryParam, setQueryParams } from 'Util/Url';

import {
    DEFAULT_SORT_KEY,
    GRID_LAYOUT,
    GRID_LAYOUT_MULTIPLE_COLUMNS,
    GRID_LAYOUT_ONE_COLUMN,
    GRID_LAYOUT_TOTAL_COLUMN_KEY,
    HEADER_HEIGHT,
    LAYOUT_KEY,
    LIST_LAYOUT,
    SHOULD_USE_STATE_FILTERS,
    TOP_LEVEL_CATEGORY
} from './CategoryPage.config';

export {
    mapDispatchToProps
};

/** @namespace Scandipwa/Route/CategoryPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    menu: state.MenuReducer.menu,
    pages: state.ProductListReducer.pages,
    baseUrl: state.ConfigReducer.base_url
});

/** @namespace Scandipwa/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    state = {
        ...this.state,
        isMenuOpen: false,
        isCategoryButtonClick: false,
        activeCategory: {},
        shouldUseStateFilters: true,
        afterStickyEntry: null,
        beforeStickyEntry: null,
        afterInView: false,
        beforeInView: false,
        canShowSeoBlock: false,
        isLayoutLoading: false
    };

    lastScrollTop = 0;

    containerFunctions = {
        ...this.containerFunctions,
        openMenu: this.openMenu.bind(this),
        closeMenu: this.closeMenu.bind(this),
        clearButtonClick: this.clearButtonClick.bind(this),
        onStickyAfterEntryChange: this.onStickyAfterEntryChange.bind(this),
        onStickyBeforeEntryChange: this.onStickyBeforeEntryChange.bind(this),
        showSeoBlock: this.showSeoBlock.bind(this),
        setDefaultLayout: this.setDefaultLayout.bind(this)
    };

    static propTypes = {
        ...SourceCategoryPageContainer.propTypes,
        contentAfterProductList: PropTypes.string,
        contentBeforeProductList: PropTypes.string,
        contentInsideProductList: PropTypes.string,
        seoText: PropTypes.string,
        menu: MenuType,
        breadcrumbs: BreadcrumbsType,
        baseUrl: PropTypes.string.isRequired,
        storeCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        menu: {}
    };

    sortKeyAndDirectionMapper = {
        position: SORT_DIRECTION_TYPE.asc,
        saving: SORT_DIRECTION_TYPE.desc,
        bestsellers: SORT_DIRECTION_TYPE.asc,
        new: SORT_DIRECTION_TYPE.desc,
        none: SORT_DIRECTION_TYPE.asc,
        price_desc: SORT_DIRECTION_TYPE.desc
    };

    /**
     * New state:
     * - gridLayoutTotalColumn: grid layout total column on mobile view
     */
    state = {
        ...this.state,
        gridLayoutTotalColumn: GRID_LAYOUT_ONE_COLUMN
    };

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const { location: { pathname: prevPathname, search: prevSearch } } = prevProps;
        const { location: { pathname, search } } = this.props;

        if (pathname !== prevPathname) {
            BrowserDatabase.deleteItem('LastSort');
            this.resetFilterPosition();

            if (this.getIsThirdLevelCategory()) {
                scrollToTop();
            }
        }

        if (pathname === prevPathname && search !== prevSearch) {
            this.shouldUseStateFilters(prevSearch);
        }

        this.handleSortUrlParams();

        const FilterContainerWrapperNode = document.getElementById('FilterContainerWrapperId');

        if (FilterContainerWrapperNode) {
            // Get the position of the "FilterContainerWrapper" element relative to the viewport
            const FilterContainerWrapperPosition = FilterContainerWrapperNode.getBoundingClientRect();

            const st = window.pageYOffset || document.documentElement.scrollTop;

            // Reset filters when the filter container on sticky mode and we got scroll(0,0) triggered
            if (st === 0 && (FilterContainerWrapperPosition.y < 0
                || FilterContainerWrapperPosition.y === HEADER_HEIGHT)) {
                this.resetFilterPosition();
            }
        }
    }

    requestCategory() {
        const {
            categoryIds,
            isSearchPage,
            requestCategory
        } = this.props;

        const {
            currentCategoryIds
        } = this.state;

        /**
         * Prevent non-existent category from being requested
         */
        if (categoryIds === -1) {
            return;
        }

        /**
         * Do not request a category again! We are still waiting for
         * a requested category to load!
         */
        if (categoryIds === currentCategoryIds) {
            return;
        }

        /**
         * Update current category to track if it is loaded or not - useful,
         * to prevent category from requesting itself multiple times.
         */
        this.setState({
            currentCategoryIds: categoryIds,
            breadcrumbsWereUpdated: false
        });

        waitForPriorityLoad().then(
            /** @namespace Scandipwa/Route/CategoryPage/Container/CategoryPageContainer/requestCategory/waitForPriorityLoad/then/requestCategory */
            () => requestCategory({
                isSearchPage,
                categoryIds
            })
        );
    }

    handleSortUrlParams() {
        const { location } = this.props;

        const { sortDirection, sortKey } = this.getSelectedSortFromUrl() || {};
        const sortKeyParam = getQueryParam('sortKey', location);

        if (!sortKey || sortKeyParam || sortKey === DEFAULT_SORT_KEY) {
            return;
        }

        const newSortDirection = this.sortKeyAndDirectionMapper[sortKey] ?? sortDirection;

        setQueryParams({ sortKey }, location, history);
        setQueryParams({ sortDirection: newSortDirection }, location, history);
    }

    shouldUseStateFilters(prevSearch) {
        const { location: { search } } = this.props;

        const prevSearchFiltersLength = prevSearch.split(':').length;
        const searchFiltersLength = search.split(':').length;

        if (prevSearch.length && prevSearchFiltersLength > searchFiltersLength) {
            BrowserDatabase.setItem(false, SHOULD_USE_STATE_FILTERS);
        }
    }

    /**
     * Check if it is third category
     */
    getIsThirdLevelCategory() {
        const {
            location: {
                state: {
                    category
                } = {}
            } = {}
        } = history;

        const {
            category: {
                id: categoryId = category
            } = {},
            menu = {}
        } = this.props;

        const {
            actionName: {
                type,
                id,
                menuChildrenCount = 0
            } = {}
        } = window;

        if (categoryId === TOP_LEVEL_CATEGORY) {
            return false;
        }

        if (type === 'CATEGORY' && categoryId === id) {
            return menuChildrenCount === 0;
        }

        const [actualMenu] = Object.values(menu);

        if (!actualMenu) {
            return false;
        }

        const {
            menuItem: {
                children = []
            } = {}
        } = getMenuItem(actualMenu, category || categoryId, [], true) ?? {};

        return Object.keys(children).length === 0;
    }

    getActualMenuItem() {
        const {
            location: {
                state: {
                    category
                } = {}
            } = {}
        } = history;

        const {
            category: {
                id: propsCategoryId = category
            } = {},
            menu = {}
        } = this.props;

        const activeMenu = Object.values(menu)?.find(({ children }) => Object.values(children)?.length);

        if (!activeMenu) {
            return {};
        }

        const { children } = activeMenu;

        const menuItem = Object.values(children)?.find(
            ({ url: { state: { categoryId } } }) => categoryId === propsCategoryId
        ) || {};

        return menuItem;
    }

    // override to remove scroll to top
    componentDidMount() {
        const {
            categoryIds,
            category: {
                id
            }
        } = this.props;

        BrowserDatabase.setItem(true, SHOULD_USE_STATE_FILTERS);

        // scrollToTop();

        /**
         * Always make sure the navigation show / hide mode (on scroll)
         * is activated when entering the category page.
         * */
        this.updateNavigationState();

        /**
         * Always update the history, ensure the history contains category
         */
        this.updateHistory();

        /**
         * Make sure to update header state, if the category visited
         * was already loaded.
         */
        if (categoryIds === id) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
        } else {
            /**
             * Still update header and breadcrumbs, but ignore
             * the category data, as it is outdated
             */
            this.updateHeaderState(true);
            this.updateBreadcrumbs(true);
        }

        /**
         * Somehow the removeEventListener doesn't work inside this.controlView when we go to PDP
         * because this.controlView may got another reference which mean not the same function anymore
         */
        this.areaListener = new AbortController();
        window.addEventListener('scroll', this.controlView.bind(this), {
            signal: this.areaListener.signal,
            passive: true
        });

        const ProductListNode = document.getElementById('ProductListWrapperId');

        if (ProductListNode) {
            // Resize observer for the product list container
            this.productListResizeObserver = new ResizeObserver(this.handleProductListResize.bind(this));
            this.productListResizeObserver.observe(ProductListNode);
        }
    }

    componentWillUnmount() {
        if (this.observer && this.observer.disconnect) {
            this.observer.disconnect();
            this.observer = null;
        }
        if (this.productListResizeObserver && this.productListResizeObserver.disconnect) {
            this.productListResizeObserver.disconnect();
            this.productListResizeObserver = null;
        }
        this.areaListener?.abort();
        this.resetFilterPosition();
    }

    handleProductListResize(entries = []) {
        const FilterContainer = document.getElementById('FilterContainerId');
        const FilterContainerWrapperNode = document.getElementById('FilterContainerWrapperId');

        const productListContainerHeight = entries[0]?.contentRect?.height;
        const filterContainerHeight = FilterContainerWrapperNode?.offsetHeight;

        const shouldResize = productListContainerHeight >= filterContainerHeight;
        const shouldReset = filterContainerHeight >= productListContainerHeight;

        if (FilterContainer && shouldResize) {
            FilterContainer.style.setProperty('--filter-container-height', `${productListContainerHeight}px`);
        }

        if (window?.isViewAllEnabled && FilterContainerWrapperNode && shouldResize) {
            // Rest load more btn click
            window.isViewAllEnabled = null;
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'fixed');
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 0);
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', 'unset');
        }

        if (shouldReset) {
            this.resetFilterPosition();
        }
    }

    controlView() {
        const FilterContainerWrapperNode = document.getElementById('FilterContainerWrapperId');
        const FilterContainer = document.getElementById('FilterContainerId');

        // Remove events when transition to PDP
        if (window.currentPage === PDP) {
            this.areaListener?.abort();
            this.resetFilterPosition();
            if (this.observer && this.observer.disconnect) {
                this.observer.disconnect();
                this.observer = null;
            }

            return;
        }

        if (!FilterContainerWrapperNode || !FilterContainer) {
            return;
        }

        // Get the position of the "container" and "wrapper" element relative to the viewport
        const FilterContainerPosition = FilterContainer.getBoundingClientRect();
        const FilterContainerWrapperPosition = FilterContainerWrapperNode.getBoundingClientRect();

        const { afterInView, beforeInView } = this.state;

        // In case for old browser
        const innerHeight = 'innerHeight' in window
            ? window.innerHeight
            : document.documentElement.offsetHeight;

        const isFilterContainerBottomThreshold = innerHeight >= FilterContainerPosition.bottom;
        const isFilterContainerTopThreshold = !(FilterContainerWrapperPosition.top >= FilterContainerPosition.top);

        const st = window.pageYOffset || document.documentElement.scrollTop;

        const isDownScroll = st > this.lastScrollTop;
        const isUpscroll = st < this.lastScrollTop;

        const isSameHeight = Math.round(FilterContainer.clientHeight)
        === Math.round(FilterContainerWrapperNode.clientHeight)
        || Math.round(FilterContainerWrapperNode.clientHeight)
        > Math.round(FilterContainer.clientHeight);

        const FWCIsWithinFCAndInView = (innerHeight - HEADER_HEIGHT) >= Math.round(
            FilterContainerWrapperNode.clientHeight
        );

        // In case the wrapper full height is within container
        if (isSameHeight || st < HEADER_HEIGHT) {
            this.resetFilterPosition();
            return;
        }

        // Set last scroll top
        this.lastScrollTop = st <= 0 ? 0 : st;

        // FCW = FilterContainerWrapper
        const FCWRelativePositionTop = Math.round(
            Math.abs(FilterContainerPosition.top - FilterContainerWrapperPosition.top)
        );
        const FCWRelativePositionBottom = Math.round(
            Math.abs(FilterContainerPosition.bottom) - Math.abs(FilterContainerWrapperPosition.bottom)
        );

        if (afterInView && beforeInView) {
            if (isFilterContainerBottomThreshold && isDownScroll) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'absolute');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 0);
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', 'unset');
                return;
            } if (isFilterContainerTopThreshold) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'absolute');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', 0);
                return;
            } if (FCWRelativePositionTop === 0
                && HEADER_HEIGHT > FilterContainerWrapperPosition.top) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'fixed');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', `${HEADER_HEIGHT}px`);
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 'unset');
                return;
            }
            if (FCWRelativePositionBottom === 0
                && FilterContainerWrapperPosition.top > HEADER_HEIGHT) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'fixed');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', `${HEADER_HEIGHT}px`);
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 'unset');
                return;
            }
        }

        if (FWCIsWithinFCAndInView) {
            return;
        }

        if (afterInView && isDownScroll) {
            if (isFilterContainerBottomThreshold) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'absolute');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 0);
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', 'unset');
            } else {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'fixed');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 0);
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', 'unset');
            }
        } else if (afterInView && isUpscroll) {
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'absolute');
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', `${FCWRelativePositionBottom}px`);
        }

        if (beforeInView && isUpscroll) {
            if (isFilterContainerTopThreshold) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'absolute');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', 0);
            } else if (FCWRelativePositionTop !== 0
                && FilterContainerWrapperPosition.top >= HEADER_HEIGHT) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'fixed');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', `${HEADER_HEIGHT}px`);
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 'unset');
            }
        } else if (beforeInView && !afterInView && isDownScroll) {
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'absolute');
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', `${FCWRelativePositionTop}px`);
        }

        const isEdge = window.navigator.userAgent.indexOf('Edg') > -1;
        const edgeThreshold = 0.9999;
        const mainThreshold = 0.1;

        // Scroll Acceleration issue handler

        this.observer = new window.IntersectionObserver(([entry]) => {
            const FCWIsHidingTop = FilterContainerWrapperPosition.top < 0 && FilterContainerWrapperPosition.bottom < 0;
            const FCWIsHidingBottom = FilterContainerWrapperPosition.top > 0 && st > FilterContainerWrapperPosition.top;

            const FilterContainer = document.getElementById('FilterContainerId');
            const FilterContainerWrapperNode = document.getElementById('FilterContainerWrapperId');

            if (!FilterContainer) {
                return;
            }

            const isSameHeight = Math.round(FilterContainer.clientHeight)
            === Math.round(FilterContainerWrapperNode.clientHeight)
            || Math.round(FilterContainerWrapperNode.clientHeight)
            > Math.round(FilterContainer.clientHeight);

            if (!FilterContainerWrapperNode) {
                return;
            }

            const bottom = Math.max(0, Math.round(
                (Math.abs(FilterContainerPosition.bottom) - Math.abs(innerHeight)) - HEADER_HEIGHT
            ));

            const FWCIsWithinFCAndInView = (innerHeight - HEADER_HEIGHT) >= Math.round(
                FilterContainerWrapperNode.clientHeight
            );

            if (entry.isIntersecting || FWCIsWithinFCAndInView || isSameHeight) {
                return;
            }
            if (isDownScroll && FCWIsHidingBottom && !FCWIsHidingTop) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'absolute');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', 0);
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 'unset');
            } else if (isUpscroll && FCWIsHidingTop) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'absolute');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 0);
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', 'unset');
            } else if (FCWIsHidingTop) {
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'absolute');
                FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', `${bottom}px`);
            }
        }, {
            root: null,
            threshold: isEdge ? edgeThreshold : mainThreshold // set offset 0.1 means trigger if atleast 10% of element in viewport
        });

        this.observer.observe(FilterContainerWrapperNode);
    }

    resetFilterPosition() {
        const { isMobile } = this.props;
        const FilterContainerWrapperNode = document.getElementById('FilterContainerWrapperId');
        const FilterContainer = document.getElementById('FilterContainerId');
        const ProductListWrapper = document.getElementById('ProductListWrapperId');

        if (FilterContainerWrapperNode) {
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-position', 'relative');
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-top', 'unset');
            FilterContainerWrapperNode.style.setProperty('--filter-wrapper-bottom', 'unset');
        }

        if (FilterContainer) {
            FilterContainer.style.setProperty('--filter-container-height', '100%');
        }

        if (!isMobile && FilterContainerWrapperNode && ProductListWrapper) {
            const productListContainerHeight = ProductListWrapper.offsetHeight;
            const filterContainerWrapperHeight = FilterContainerWrapperNode.offsetHeight;

            if (productListContainerHeight < filterContainerWrapperHeight) {
                // eslint-disable-next-line no-magic-numbers
                ProductListWrapper.style.setProperty('min-height', `${filterContainerWrapperHeight - 90}px`);
            }
        }
    }

    onStickyAfterEntryChange(inView, entry) {
        this.setState({ afterInView: inView, afterStickyEntry: entry });
    }

    onStickyBeforeEntryChange(inView, entry) {
        this.setState({ beforeInView: inView, beforeStickyEntry: entry });
    }

    getSelectedSortFromUrl() {
        const {
            location,
            category: {
                default_sort_by
            }
        } = this.props;

        const lastSort = BrowserDatabase.getItem('LastSort');

        const {
            sortKey: lastSortKey,
            sortDirection: lastSortDirection
        } = lastSort || this.config;

        /**
         * Default SORT DIRECTION is taken from (sequentially):
         * - URL param "sortDirection"
         * - CategoryPage class property "config"
         * */
        const sortDirection = getQueryParam('sortDirection', location) || lastSortDirection;

        /**
         * Default SORT KEY is taken from (sequentially):
         * - URL param "sortKey"
         * - Category default sort key (Magento 2 configuration)
         * - CategoryPage class property "config"
         * */
        const defaultSortKey = default_sort_by || lastSortKey;
        const sortKey = getQueryParam('sortKey', location) || defaultSortKey;

        return {
            sortDirection,
            sortKey
        };
    }

    getIsMatchingListFilter() {
        const {
            // location,
            // location: {
            //     state: {
            //         locationCurrentPage
            //     } = {}
            // } = {},
            currentArgs: {
                // currentPage,
                sort,
                filter
            } = {}
        } = this.props;

        const sortKey = getQueryParam('sortKey', location);

        if (sortKey && sort) {
            BrowserDatabase.setItem(sort, 'LastSort');
        }

        /**
         * ? implementation bellow blinks, implementation with categoryIds check only does not show loading when selecting filters.
         * TODO: resolve it to be a combination of these two behavior
         */

        // Data used to request category matches current data
        return JSON.stringify(filter) === JSON.stringify(this.getFilter())
            && (JSON.stringify(sort) === JSON.stringify(this.getSelectedSortFromUrl()));
        // && (currentPage === +(getQueryParam('page', location) || 1));
        // || locationCurrentPage === +(getQueryParam('page', location) || 1));
    }

    getCategoryChildrenForSlider() {
        const { category: { children = [] } = {}, isMobile } = this.props;

        if (
            (this.getIsTopParentCategory())
            && isMobile
            && !this.getIsInAccessoriesCategories()
        ) {
            return this.getSliderChildrenForTopCategories();
        }

        return children.filter(({ product_count }) => product_count);
    }

    getIsTopParentCategory() {
        const { category: { name = '', url = '', menu_children = [] } = {} } = this.props;

        const actualMenuItem = this.getActualMenuItem();
        const isCorrectFirstChild = menu_children?.[0]?.name === name;

        if (menu_children?.[0]?.name === name && menu_children?.[0]?.url !== url) {
            menu_children[0].url = url;
        }

        return actualMenuItem?.parentCategoryId === TOP_LEVEL_CATEGORY && isCorrectFirstChild;
    }

    getIsInAccessoriesCategories() {
        const { category: { url = '' } = {} } = this.props;

        return url.indexOf('accessories') !== -1 || url.indexOf('house-hold-all') !== -1;
    }

    getSliderChildrenForTopCategories() {
        const { category: { menu_children = [], id } = {} } = this.props;

        if (id !== TOP_LEVEL_CATEGORY) {
            return menu_children.filter(({ product_count }) => product_count);
        }

        return this.getSliderChildrenForFurnitureCategory();
    }

    getSliderChildrenForFurnitureCategory() {
        const { menu, category: { children = [] } = {} } = this.props;

        const menuWithChildren = Object.values(menu).find(({ children }) => Object.values(children).length);

        const furnitureMenuChildren = menuWithChildren?.children
            ? Object.entries(menuWithChildren?.children).reduce((acc, [index, item]) => {
                const { parentCategoryId } = item;

                if (parentCategoryId !== TOP_LEVEL_CATEGORY) {
                    return acc;
                }

                return { ...acc, [index]: item };
            }, {})
            : {};

        const result = children.filter(({ id }) => Object.values(furnitureMenuChildren).some(
            ({ url: { state: { categoryId } } }) => categoryId === id
        ));

        return result;
    }

    containerProps() {
        const {
            isMenuOpen,
            isCategoryButtonClick,
            activeCategory,
            canShowSeoBlock,
            isLayoutLoading
        } = this.state;

        const {
            contentAfterProductList,
            contentBeforeProductList,
            contentInsideProductList,
            seoText,
            menu,
            isMobile,
            pages,
            baseUrl,
            location
        } = this.props;

        return {
            ...super.containerProps(),
            isThirdLevelCategory: this.getIsThirdLevelCategory(),
            gridLayoutTotalColumn: this.getGridLayoutTotalColumn(),
            isMenuOpen,
            contentAfterProductList,
            contentBeforeProductList,
            contentInsideProductList,
            seoText,
            isCategoryButtonClick,
            activeCategory,
            isMenuUpdateNeeded: !Object.keys(menu).length && isMobile,
            selectedSort: this.getSelectedSortFromUrl(),
            pages,
            canShowSeoBlock,
            baseUrl,
            isLayoutLoading,
            location,
            sliderChildren: this.getCategoryChildrenForSlider(),
            isMobile
        };
    }

    openMenu(menuItem) {
        this.setState({
            isMenuOpen: true,
            isCategoryButtonClick: true,
            activeCategory: menuItem
        });

        scrollToTop({ behavior: 'smooth' });
    }

    clearButtonClick() {
        this.setState({ isCategoryButtonClick: false });
    }

    closeMenu() {
        this.setState({
            isMenuOpen: false,
            isCategoryButtonClick: false,
            activeCategory: {}
        });
    }

    /**
     * Overridden to add action if isMobile is true
     */
    onGridButtonClick() {
        const { isMobile } = this.props;
        this.setState({ isLayoutLoading: true });

        setTimeout(() => {
            if (isMobile) {
                BrowserDatabase.setItem(GRID_LAYOUT_MULTIPLE_COLUMNS, GRID_LAYOUT_TOTAL_COLUMN_KEY);
                this.setState({ gridLayoutTotalColumn: GRID_LAYOUT_MULTIPLE_COLUMNS });
            }

            BrowserDatabase.setItem(GRID_LAYOUT, LAYOUT_KEY);
            this.setState({ selectedLayoutType: GRID_LAYOUT });
            this.setState({ isLayoutLoading: false });
        }, 0);
    }

    /**
     * Overridden to add action if isMobile is true
     */
    onListButtonClick() {
        const { isMobile } = this.props;
        this.setState({ isLayoutLoading: true });

        setTimeout(() => {
            if (isMobile) {
                BrowserDatabase.setItem(GRID_LAYOUT_ONE_COLUMN, GRID_LAYOUT_TOTAL_COLUMN_KEY);
                this.setState({ gridLayoutTotalColumn: GRID_LAYOUT_ONE_COLUMN });
            }

            BrowserDatabase.setItem(LIST_LAYOUT, LAYOUT_KEY);
            this.setState({ selectedLayoutType: LIST_LAYOUT });
            this.setState({ isLayoutLoading: false });
        }, 0);
    }

    /**
     * New method to get grid layout total column
     */
    getGridLayoutTotalColumn() {
        const { isMobile } = this.props;
        const { gridLayoutTotalColumn } = this.state;

        if (!isMobile) {
            return undefined;
        }

        return BrowserDatabase.getItem(GRID_LAYOUT_TOTAL_COLUMN_KEY)
            || gridLayoutTotalColumn;
    }

    showSeoBlock() {
        this.setState({ canShowSeoBlock: true });
    }

    setDefaultLayout() {
        const {
            isMobile,
            location: {
                pathname = ''
            } = {}
        } = this.props;

        const { gridLayoutTotalColumn } = this.state;

        const isAccessories = pathname?.indexOf('accessories') !== -1;

        if (
            isMobile
            && gridLayoutTotalColumn !== GRID_LAYOUT_MULTIPLE_COLUMNS
            && isAccessories
        ) {
            this.onGridButtonClick();
        }

        if (
            isMobile
            && !isAccessories
        ) {
            this.onListButtonClick();
        }
    }
}

export default withRouter(withReducers({
    CategoryReducer,
    ProductListInfoReducer,
    MenuReducer
})(connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer)));
