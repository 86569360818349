/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/prop-types */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Loader from 'Component/Loader';

import { AmRmaReturnProductInfo } from '../../type/AmastyRMA.type';

import './AmastyMoreInformation.style';

/** @namespace Scandiweb/AmastyRma/Component/AmastyMoreInformation/Component */
export class AmastyMoreInformationComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isCart: PropTypes.bool,
        isShowLoader: PropTypes.bool,
        returnPeriod: AmRmaReturnProductInfo.isRequired
    };

    static defaultProps = {
        isCart: false,
        isShowLoader: true
    };

    renderContent() {
        const { isCart, returnInfo } = this.props;
        const heading = __('Shipping and return');

        if (!returnInfo) {
            return null;
        }

        if (isCart) {
            const { value } = returnInfo.find((info) => info.label === 'Return period');

            return (
                <p block="RmaInfo" className="small-caption">
                    { `${heading}: ${value}` }
                </p>
            );
        }

        return (
            <ExpandableContent
              heading={ heading }
              mix={ { block: 'ProductInformation', elem: 'Rma' } }
              isExpandableOnDesktop
              isArrow
            >
                 { this.renderInfo() }
            </ExpandableContent>
        );
    }

    renderInfo() {
        const {
            returnInfo,
            returnPeriod
        } = this.props;

        const value = returnInfo[0]?.value;
        const heading = __('Shipping and return');
        const { value: returnPeriodValue = 0 } = returnPeriod.find((info) => info.label === 'Return period') || {};

        const newValue = parseInt(value, 10) > 0 ? __('This item can be returned') : value;

        return (
            <div block="ProductInformation" elem="RmaInfo">
                <p>
                    { newValue }
                </p>
                <p block="RmaInfo" className="small-caption">
                    { `${heading}: ${returnPeriodValue}` }
                </p>
            </div>

        );
    }

    render() {
        const {
            isLoading,
            isShowLoader
        } = this.props;

        if (isLoading && isShowLoader) {
            return <Loader isLoading />;
        }

        if (isLoading) {
            return null;
        }

        return (
            this.renderContent()
        );
    }
}

export default AmastyMoreInformationComponent;
