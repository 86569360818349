/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { CUSTOMER, ONE_MONTH_IN_SECONDS } from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {
    setAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
// import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import getStore from 'Util/Store';
import { setCartId } from 'Util/Cart';

export const MyAccountDispatcher = import(
    /* webpackMode: "eager"*/
    'Store/MyAccount/MyAccount.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "eager"*/
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "wishlist-dispatcher" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "eager"*/
    'Store/ProductCompare/ProductCompare.dispatcher'
);

export const SOCIAL_TOKEN = 'social_token';

/** @namespace Scandiweb/MageplazaSocialLogin/Util/Login/executeLoginFlow */
export const executeLoginFlow = async (token) => {
    const { dispatch } = getStore();

    setAuthorizationToken(token);

    // if customer is authorized, `createEmptyCart` mutation returns customer cart token
    const cartDispatcher = (await CartDispatcher).default;
    const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);
    // vvv Changed cardId for compatibility with ScandiPWA
    const guestCartToken = getStore().getState().CartReducer.cartTotals.id;
    if (guestCartToken && guestCartToken !== customerCartToken) {
        // merge guest cart id and customer cart id using magento capabilities
        await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
    }

    // setGuestQuoteId(customerCartToken);
    setCartId(customerCartToken);
    cartDispatcher.updateInitialCartData(dispatch);

    WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
    );

    ProductCompareDispatcher.then(
        ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
    );

    const myAccountDispatcher = (await MyAccountDispatcher).default;
    await myAccountDispatcher.requestCustomerData(dispatch);

    const isPrimaryPhoneAvailable = BrowserDatabase.getItem(CUSTOMER)?.primary_telephone;

    if (!isPrimaryPhoneAvailable) {
        BrowserDatabase.setItem(token, SOCIAL_TOKEN, ONE_MONTH_IN_SECONDS);
    }

    dispatch(updateCustomerSignInStatus(true));
    dispatch(updateIsLoading(false));
    dispatch(hideActiveOverlay());
    dispatch(showNotification('success', __('You are successfully logged in!')));
};
