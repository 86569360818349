/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

const giftCards = new Field('giftCards')
    .addFieldList(['code', 'amount']);

const giftData = new Field('giftData')
    .addFieldList([
        'giftAmount',
        giftCards
    ]);

const addGiftAmountField = (args, callback) => ([
    ...callback(...args),
    giftData
]
);

export default {
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemTotalFields: addGiftAmountField
        }
    }
};
