import { ORDER_PAGE_SIZE_DESKTOP } from 'Component/MyAccountMyOrders/MyAccountMyOrders.config';
import {
    OrderQuery as SourceOrderQuery
} from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    /**
     * New method to return order by masked cartId
     */
    getOrderByCartId(cartId) {
        return new Field('panMyAccountOrderInformation')
            .addArgument('input', 'PanMyAccountOrderInformationInput!', { cartId })
            .addFieldList(this._getOrderItemsFields(true));
    }

    getOrdersItemTotal() {
        return new Field('total')
            .addField(this._getOrderGrandTotalField());
    }

    /**
     * Overridden to add _getOrderItemsProductsId in order to have the number of items in order
     */
    _getOrderItemsFields(isSingleOrder) {
        const basicFields = [
            'id',
            'increment_id',
            'order_date',
            'status',
            'can_reorder',
            'rss_link',
            'thumbnail',
            'default_thumbnail',
            'delivery_date',
            'is_cancelable',
            'pan_reference_code',
            'delivery_additional_fees',
            'is_virtual',
            this._getOrderItemTotalField()
        ];

        if (isSingleOrder) {
            return [...basicFields, ...this._getSingleOrderFields()];
        }

        return [
            'id',
            'increment_id',
            'status',
            'thumbnail',
            'default_thumbnail',
            'is_cancelable',
            'order_date',
            'pan_reference_code',
            'items_count',
            this._getOrderInvoicesField(),
            this.getOrdersItemTotal()
        ];
    }

    _getSingleOrderFields() {
        return [
            ...super._getSingleOrderFields(),
            this._getOrderGiftMessageField(),
            'is_estimated_delivery_date'
        ];
    }

    _getOrderGiftMessageField() {
        return new Field('gift_message')
            .addFieldList(this._getOrderGiftMessageFields());
    }

    _getOrderGiftMessageFields() {
        return [
            'isGiftApplied',
            'message'
        ];
    }

    /**
     * Overridden to new information
     */
    _getOrderItemTotalFields() {
        return [
            ...super._getOrderItemTotalFields(),
            'gainedLoyaltyPoints',
            'redeemedLoyaltyPoints',
            this.getOrderStoreCreditField(),
            this.getOrderLookDiscount()
        ];
    }

    getOrderLookDiscount() {
        return new Field('lookDiscountAmount')
            .addFieldList(this._getOrderPriceFields());
    }

    getOrderStoreCreditField() {
        return new Field('storeCredit')
            .addFieldList(this._getOrderPriceFields());
    }

    /**
     * New method to get order items products
     */
    _getOrderItemsProductsId() {
        return new Field('items')
            .addFieldList(this._getOrderItemsProductsIds());
    }

    /**
     * New method to get only id and thumbnail
     */
    _getOrderItemsProductsIds() {
        return [
            'id',
            'thumbnail'
        ];
    }

    getOrderFilter(options) {
        const {
            incrementID,
            selectedDateRange,
            isVirtual
        } = options;
        const filter = {};

        if (incrementID) {
            filter.number = { eq: incrementID };
        }

        if (selectedDateRange) {
            filter.created_at = selectedDateRange;
        }

        if (isVirtual !== undefined) {
            filter.is_virtual = { eq: isVirtual ? 1 : 0 };
        }

        return filter;
    }

    /**
     * Overridden to get order by incrementID
     */
    _getOrdersField(options) {
        const {
            orderId,
            erpOrderNumber,
            page = 1,
            isWithCreatedAtDateFromAllOrdersResult = false,
            pageSize = ORDER_PAGE_SIZE_DESKTOP
        } = options || {};
        const ordersField = new Field('orders');

        if (orderId) {
            return ordersField
                .addArgument(
                    'filter',
                    'CustomerOrdersFilterInput',
                    { entity_id: { eq: orderId } }
                )
                .addFieldList(this._getOrdersFields(true));
        }

        if (erpOrderNumber) {
            return ordersField
                .addArgument(
                    'filter',
                    'CustomerOrdersFilterInput',
                    { pan_reference_code: { eq: erpOrderNumber } }
                )
                .addFieldList(this._getOrdersFields(true));
        }

        ordersField.addArgument('currentPage', 'Int', page);

        const filter = this.getOrderFilter(options);
        const isFilterPresent = Object.keys(filter).length > 0;

        if (isFilterPresent) {
            ordersField.addArgument(
                'filter',
                'CustomerOrdersFilterInput',
                filter
            );
        }

        if (isWithCreatedAtDateFromAllOrdersResult) {
            ordersField.addArgument(
                'isWithCreatedAtDateFromAllOrdersResult',
                'Boolean',
                isWithCreatedAtDateFromAllOrdersResult
            );
        }

        ordersField
            .addArgument(
                'pageSize',
                'Int',
                pageSize
            )
            .addFieldList(
                this._getOrdersFields(false, options)
            );

        return ordersField;
    }

    getOrderCount() {
        return new Field('customer')
            .addFieldList([this.getOrderCountFields()]);
    }

    getOrderCountFields() {
        return new Field('orders')
            .addFieldList(this.getOrderCountOrdersFields());
    }

    getOrderCountOrdersFields() {
        return ['total_count'];
    }

    /**
     * Overridden to add return value if isWithCreatedAtDateFromAllOrdersResult is true
     */
    _getOrdersFields(isSingleOrder = false, options = {}) {
        const { isWithCreatedAtDateFromAllOrdersResult = false } = options;
        const fields = [
            'total_count',
            this._getOrderItemsField(isSingleOrder),
            this._getSearchResultPageInfoField(),
            // TODO: Make this conditional vvv
            this.getErpOrdersField()
        ];

        if (!isWithCreatedAtDateFromAllOrdersResult) {
            return fields;
        }

        const fieldsWithCreatedAtDateFromAllOrdersResult = [
            ...fields,
            'order_created_at_list'
        ];

        return fieldsWithCreatedAtDateFromAllOrdersResult;
    }

    /**
     * Overridden to add getOrderProductOriginalPriceField usage and `thumbnail` field
     */
    _getOrderItemProductsFields() {
        return [
            'product_url_key',
            'quantity_ordered',
            'quantity_shipped',
            'quantity_refunded',
            'quantity_canceled',
            this._getOrderProductEnteredOptionsField(),
            this._getOrderProductSelectedOptionsField(),
            this._getOrderProductRowSubtotalField(),
            ...this._getBaseOrderItemProductsFields(),
            this.getOrderProductOriginalPriceField(),
            'thumbnail'
        ];
    }

    /**
     * New method to get product original price field
     */
    getOrderProductOriginalPriceField() {
        return new Field('original_price')
            .addFieldList(this._getOrderPriceFields());
    }

    getCancelOrderMutation(orderId) {
        return new Field('PanPostCancellationOrder')
            .addArgument('orderId', 'Int!', orderId);
    }

    getErpOrdersField() {
        return new Field('erpOrders')
            .addFieldList(this.getErpOrdersFields());
    }

    getErpOrdersFields() {
        return [
            'currentpage',
            'totalpages',
            'totalorders',
            this.getErpOrdersOrdersField()
        ];
    }

    getErpOrdersOrdersField() {
        return new Field('orders')
            .addFieldList(this.getErpOrdersOrdersFields());
    }

    getErpOrdersOrdersFields() {
        return [
            'order_no',
            'order_status',
            'erp_id',
            'order_date',
            'delivery_date',
            'currency',
            'gained_loyalty_points',
            'delivery_additional_fees',
            this.getErpOrdersYouSavedField(),
            this.getErpOrdersTotalField(),
            'delivery_method',
            this.getErpOrdersShippingAddressField(),
            this.getErpOrdersBillingAddressField(),
            'payment_methods',
            this.getErpOrdersConfirmedItemsField(),
            this.getErpOrdersUnavailableItemsField(),
            'default_thumbnail',
            'order_is_gift',
            'order_customer_comments'
        ];
    }

    getErpOrdersYouSavedField() {
        return new Field('you_saved')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersTotalField() {
        return new Field('total')
            .addFieldList(this.getErpOrdersTotalFields());
    }

    getErpOrdersTotalFields() {
        return [
            this.getErpOrdersGrandTotalField(),
            this.getErpOrdersBaseGrandTotalField(),
            this.getErpOrdersSubtotalField(),
            this.getErpOrdersTotalShippingField(),
            this.getErpOrdersTotalTaxField(),
            this._getOrderDiscountsField(),
            this.getErpOrdersStoreCreditField(),
            'loyalty_point'
        ];
    }

    getErpOrdersGrandTotalField() {
        return new Field('grnd_total')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersBaseGrandTotalField() {
        return new Field('base_grand_total')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersSubtotalField() {
        return new Field('subtotal')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersTotalShippingField() {
        return new Field('total_shipping')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersTotalTaxField() {
        return new Field('total_tax')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersStoreCreditField() {
        return new Field('store_credit')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersShippingAddressField() {
        return new Field('shpping_address')
            .addFieldList(this.getErpOrdersShippingAddressFields());
    }

    getErpOrdersBillingAddressField() {
        return new Field('billing_address')
            .addFieldList(this.getErpOrdersShippingAddressFields());
    }

    getErpOrdersShippingAddressFields() {
        return [
            'full_name',
            'phone_number',
            'street',
            'area',
            'City',
            'Country'
        ];
    }

    getErpOrdersConfirmedItemsField() {
        return new Field('confirmed_items')
            .addFieldList(this.getErpOrdersConfirmedItemsFields());
    }

    getErpOrdersUnavailableItemsField() {
        return new Field('unavailalbe_items')
            .addFieldList(this.getErpOrdersConfirmedItemsFields());
    }

    getErpOrdersConfirmedItemsFields() {
        return [
            'sku',
            'name',
            'qty',
            this.getErpOrdersConfirmedItemsPriceField(),
            this.getErpOrdersConfirmedItemsTaxField(),
            this.getErpOrdersConfirmedItemsDiscountField(),
            this.getErpOrdersConfirmedItemsVatAmountField()
        ];
    }

    getErpOrdersConfirmedItemsPriceField() {
        return new Field('price')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersConfirmedItemsTaxField() {
        return new Field('tax')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersConfirmedItemsDiscountField() {
        return new Field('discount')
            .addFieldList(this._getOrderPriceFields());
    }

    getErpOrdersConfirmedItemsVatAmountField() {
        return new Field('vat_amount')
            .addFieldList(this._getOrderPriceFields());
    }
}

export default new OrderQuery();
