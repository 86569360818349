/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Loader from 'Component/Loader';

import { IsReturnableType } from '../../type/AmastyRMA.type';
import AmastyMoreInformation from '../AmastyMoreInformation';

import './AmastyRmaInCart.style';

/** @namespace Scandiweb/AmastyRma/Component/AmastyRmaInCart/Component */
export class AmastyRmaInCartComponent extends PureComponent {
    static propTypes = {
        returnInfo: IsReturnableType.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    renderContent() {
        const { returnInfo } = this.props;
        const heading = __('Shipping and return');

        if (!returnInfo.length) {
            return null;
        }

        return (
            <ExpandableContent
              heading={ heading }
              isScrollOnExpand={ false }
              mix={ { block: 'CartRma', elem: 'Rma' } }
              isExpandableOnDesktop
              isArrow
            >
                <table>
                    { returnInfo.map((item, i) => this.renderInfo(item, i)) }
                </table>
            </ExpandableContent>
        );
    }

    renderInfo(item, i) {
        const { name, info } = item;

        return (
            <tr block="CartRma" elem="Product" key={ i }>
                <td block="CartRma" elem="ProductInfo">
                    <span>{ `${name}: ` }</span>
                </td>
                <td block="CartRma" elem="MoreInfo">
                    <AmastyMoreInformation
                      returnInfo={ info }
                      isCart
                      isShowLoader={ false }
                    />
                </td>
            </tr>
        );
    }

    render() {
        const {
            isLoading
        } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        return (
            this.renderContent()
        );
    }
}

export default AmastyRmaInCartComponent;
