import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    FieldFileContainer as SourceFieldFileContainer
} from 'SourceComponent/FieldFile/FieldFile.container';

/** @namespace Scandipwa/Component/FieldFile/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/FieldFile/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/FieldFile/Container */
export class FieldFileContainer extends SourceFieldFileContainer {
    static propTypes = {
        ...this.propTypes,
        isMobile: PropTypes.bool.isRequired
    };

    containerFunctions = {
        ...this.containerFunctions,
        getOnUploadImageClick: this.getOnUploadImageClick.bind(this)
    };

    containerProps() {
        const { isMobile } = this.props;

        return {
            ...super.containerProps(),
            isMobile
        };
    }

    /**
     * New method to handle image upload file click
     */
    onUploadFileClick(isCapture) {
        this.setState({
            value: '',
            fileName: '',
            isLoading: false
        });

        this.fieldRef.fileData = '';
        this.fieldRef.value = '';

        try {
            if (isCapture) {
                this.fieldRef.setAttribute('capture', 'environment');
            } else {
                this.fieldRef.removeAttribute('capture');
            }
            this.fieldRef.click();
        // eslint-disable-next-line no-empty
        } catch (e) {
        }
    }

    /**
     * New method to get upload file handle function
     */
    getOnUploadImageClick(param) {
        return () => {
            this.onUploadFileClick(param);
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldFileContainer);
