import { CategoryQuery as SourceCategoryQuery } from 'SourceQuery/Category.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Category/Query */
export class CategoryQuery extends SourceCategoryQuery {
    getQuery(options = {}) {
        this.options = options;

        return new Field('category')
            .addArgument(...this._getConditionalArguments())
            .addFieldList(this._getDefaultFields())
            .addField(this._getChildrenFields())
            .addField(this.getMenuChildrenField());
    }

    getMenuChildrenField() {
        return new Field('menu_children')
            .addFieldList(this._getDefaultFields());
    }

    /**
     * Overridden to add CMS content attribute fields
     */
    _getDefaultFields() {
        return [
            ...super._getDefaultFields(),
            'content_after_product_list',
            'content_before_product_list',
            'content_inside_product_list',
            'children_count',
            'seo_text',
            'is_banner_active',
            'banner_desktop_image',
            'banner_mobile_image'
        ];
    }

    /**
     * Overridden to add Breadcrumb category attribute fields
     */
    _getBreadcrumbFields() {
        return [
            ...super._getBreadcrumbFields(),
            'category_id',
            'category_image',
            'category_description',
            'children_count',
            'category_banner_enabled',
            'category_banner_desktop_image',
            'category_banner_mobile_image'
        ];
    }
}

export default new CategoryQuery();
