/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getGiftcardsCartId, isGiftCardsPage } from '../../util/Cart';

const addGiftCardsCartIdToPaymentMethodMutation = (args, callback) => {
    if (!isGiftCardsPage() || !args?.length) {
        return callback(...args);
    }

    const [input] = args;
    const newInput = {
        ...input,
        cart_id: getGiftcardsCartId()
    };

    const newArgs = args;

    newArgs[0] = newInput;

    return callback(...newArgs);
};

const addGiftCardsCartIdToPayfortPlaceTransaction = (args, callback) => {
    if (!isGiftCardsPage()) {
        return callback(...args);
    }

    const newArgs = args;

    newArgs[0] = getGiftcardsCartId();

    return callback(...newArgs);
};

const addGiftCardsCartIdToPayfortInstallmentsPlans = (args, callback) => {
    if (!isGiftCardsPage()) {
        return callback(...args);
    }

    const newArgs = args;

    newArgs[1] = getGiftcardsCartId();

    return callback(...newArgs);
};

const addGiftCardsCartTotalsToApplePayProps = (args, callback) => {
    const [state] = args;
    const stateMap = callback(...args);

    if (!isGiftCardsPage()) {
        return stateMap;
    }

    return {
        ...stateMap,
        totals: state.GiftCardsCartReducer?.cartTotals
    };
};

export default {
    'Query/Checkout/Query': {
        'member-function': {
            getSetPaymentMethodOnCartMutation: addGiftCardsCartIdToPaymentMethodMutation
        }
    },
    'Scandiweb/AmazonPayfort/Query/PayfortPlaceTransaction/Query': {
        'member-function': {
            getPayfortPlaceTransaction: addGiftCardsCartIdToPayfortPlaceTransaction
        }
    },
    'Scandiweb/AmazonPayfort/Query/PayfortInstallmentPlans/Query': {
        'member-function': {
            getPayfortInstallmentPlansField: addGiftCardsCartIdToPayfortInstallmentsPlans
        }
    },
    'Scandiweb/AmazonPayfort/Component/ApplePay/Container/mapStateToProps': {
        function: addGiftCardsCartTotalsToApplePayProps
    }
};
