/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const TokenType = PropTypes.shape({
    public_hash: PropTypes.string,
    type: PropTypes.string,
    typename: PropTypes.string,
    maskedCC: PropTypes.string,
    expirationDate: PropTypes.string
});

export const PayfortData = PropTypes.shape({
    merchantIdentifier: PropTypes.string,
    accessCode: PropTypes.string,
    merchantReference: PropTypes.string,
    language: PropTypes.string,
    serviceCommand: PropTypes.string,
    returnUrl: PropTypes.string,
    signature: PropTypes.string,
    currency: PropTypes.string,
    amount: PropTypes.string,
    installments: PropTypes.string
});

export const TokenParams = PropTypes.shape({
    amount: PropTypes.string,
    response_code: PropTypes.string,
    card_number: PropTypes.string,
    signature: PropTypes.string,
    merchant_identifier: PropTypes.string,
    access_code: PropTypes.string,
    payment_option: PropTypes.string,
    expiry_date: PropTypes.string,
    customer_ip: PropTypes.string,
    language: PropTypes.string,
    eci: PropTypes.string,
    fort_id: PropTypes.string,
    command: PropTypes.string,
    threeDs_url: PropTypes.string,
    response_message: PropTypes.string,
    merchant_reference: PropTypes.string,
    customer_email: PropTypes.string,
    currency: PropTypes.string,
    status: PropTypes.string
});

export const PayfortVaultData = PropTypes.shape({
    success: PropTypes.bool,
    error_message: PropTypes.string,
    order_id: PropTypes.string,
    TokenParams,
    url: PropTypes.string
});

export const MerchantPageDataType = PropTypes.shape({
    errorMessage: PropTypes.string,
    orderId: PropTypes.string,
    formUrl: PropTypes.string,
    PayfortData,
    PayfortVaultData
});

export const ElementRefType = PropTypes.shape({ current: PropTypes.instanceOf(Element) });

export const ApsFortCCType = PropTypes.shape({
    integrationType: PropTypes.string,
    redirectUrl: PropTypes.string,
    instructions: PropTypes.string,
    title: PropTypes.string,
    sort_order: PropTypes.number,
    mada: PropTypes.bool,
    meeza: PropTypes.bool,
    madabin: PropTypes.string,
    meezabin: PropTypes.string,
    getInstallmentPlans: PropTypes.string
});

export const ApsAppleType = PropTypes.shape({
    redirectUrl: PropTypes.string,
    instructions: PropTypes.string,
    title: PropTypes.string,
    sort_order: PropTypes.string,
    shippingconfig: PropTypes.string,
    shippingdisplayconfig: PropTypes.number,
    appleValidation: PropTypes.string,
    appleToAps: PropTypes.string,
    appleButtonClass: PropTypes.string,
    appleSupportedNetwork: PropTypes.string,
    storeName: PropTypes.string,
    storeCountryCode: PropTypes.string,
    cancelUrl: PropTypes.string
});

export const PayfortConfigType = PropTypes.shape({
    apsFortCC: ApsFortCCType,
    apsApple: ApsAppleType,
    tokenizationActive: PropTypes.bool
});

export const PayfortCountryType = PropTypes.shape({
    code: PropTypes.string,
    label: PropTypes.string
});

export const PayfortBankType = PropTypes.shape({
    country_code: PropTypes.string,
    issuer_code: PropTypes.string,
    issuer_name: PropTypes.string,
    terms_url: PropTypes.string,
    bins: PropTypes.arrayOf(PropTypes.string)
});

export const PayfortPlanType = PropTypes.shape({
    issuer_code: PropTypes.string,
    interest: PropTypes.number,
    interest_info: PropTypes.string,
    fees_amount: PropTypes.number,
    plan_type: PropTypes.string,
    amountPerMonth: PropTypes.string,
    plan_code: PropTypes.string,
    maximum_amount: PropTypes.string,
    minimum_amount: PropTypes.number,
    currency_code: PropTypes.string,
    rate_type: PropTypes.string,
    fees_type: PropTypes.string,
    number_of_installment: PropTypes.number,
    processing_fees_type: PropTypes.string,
    processing_fees_amount: PropTypes.number,
    fee_display_value: PropTypes.number,
    plan_merchant_type: PropTypes.string
});
