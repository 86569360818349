/**
 * MageAnts Sample Product compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { addSampleToCart } from '../../util/addSampleToCart';

// vvv Code copied from original class
const addSampleroductToCart = async (args, callback, instance) => {
    const [dispatch, options] = args;
    const {
        products = [],
        cartId: userCartId,
        isSample = false
    } = options;

    if (!isSample) {
        return callback(...args);
    }

    return addSampleToCart(userCartId, products, dispatch, instance);
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart: addSampleroductToCart
        }
    }
};
