/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_AMAZON_PAYFORT_STORE } from './AmazonPayfort.config';

/** @namespace Scandiweb/AmazonPayfort/Store/AmazonPayfort/Reducer/getInitialState */
export const getInitialState = () => ({
    selectedPaymentMethod: null,
    merchantPageData: {},
    merchantPageDispatched: false,
    selectedPlan: {},
    shippingMethod: null
});

/** @namespace Scandiweb/AmazonPayfort/Store/AmazonPayfort/Reducer/AmazonPayfortReducer */
export const AmazonPayfortReducer = (state = getInitialState(), action) => {
    const { type, state: newState } = action;

    if (type === UPDATE_AMAZON_PAYFORT_STORE) {
        return {
            ...state,
            ...newState
        };
    }

    return state;
};

export default AmazonPayfortReducer;
