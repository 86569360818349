/**
 * GA4MeasurementProtocol frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { isSignedIn } from 'Util/Auth';
import { fetchMutation, fetchQuery } from 'Util/Request';

import GaCookieQuery from '../query/GaCookie.query';

export const GUEST_QUOTE_ID = 'guest_quote_id';

export const getGaCid = () => {
    const [, result = ''] = document.cookie.match(/_ga=([^;]+);/) || [];

    return result;
};

export const getSessionId = (session_cookie) => {
    const regexPattern = new RegExp(`${session_cookie}=GS\\d\\.\\d\\.(.+?)(?:;|$)`);
    const [, result = ''] = document.cookie.match(regexPattern) || [];

    return result;
};

const getAdditionalData = () => {
    const { referrer = '', landingPageUrl = '' } = window.sessionStorage;

    return {
        referrer,
        landingPageUrl
    };
};

export const componentDidMount = async (args, callback, instance) => {
    try {
        const result = callback(...args);
        const {
            totals: {
                id: cartId
            } = {}
        } = instance.props;
        const gaCid = getGaCid();
        const guestCartId = isSignedIn() ? null : cartId;
        const { getGaSessionCookie: session_cookie } = await fetchQuery(GaCookieQuery.getGaSessionCookie());
        const session_id = getSessionId(session_cookie);
        const additionalData = getAdditionalData();

        fetchMutation(
            GaCookieQuery.setGaCookie(gaCid, session_id, guestCartId)
        );

        fetchMutation(
            GaCookieQuery.setGaAdditionalData(additionalData.referrer, additionalData.landingPageUrl, guestCartId)
        );

        return result;
    } catch (error) {
        return callback(...args);
    }
};

// Both Checkout and Cart plugins are needed because sometimes user
// can place an order without proceeding to checkout (ex. paypal)
export default {
    'Route/CartPage/Component': {
        'member-function': {
            componentDidMount
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            componentDidMount
        }
    }
};
