/* eslint-disable no-unreachable */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import { StoreType } from 'Type/Checkout.type';
import { pickupStoresToOptions } from 'Util/StoreInPickup/StoreInPickup';

import './DeliveryOptionPickup.style';
/** @namespace Scandipwa/Component/DeliveryOptionPickup/Component */
export class DeliveryOptionPickupComponent extends PureComponent {
    static propTypes = {
        onPickupStoreSelect: PropTypes.func.isRequired,
        pickupStores: PropTypes.arrayOf(StoreType).isRequired,
        selectedStore: StoreType.isRequired,
        isPlaceholder: PropTypes.bool.isRequired
    };

    renderStoreSelection() {
        const {
            onPickupStoreSelect,
            selectedStore,
            pickupStores,
            isPlaceholder
        } = this.props;

        const {
            pickup_location_code
        } = selectedStore || {};

        const filteredPickupStores = pickupStores?.map((store) => ({
            ...store,
            storeName: store?.storeName?.replace('[Outdated]', '')
        }));

        return (
            <Field
              type={ FIELD_TYPE.select }
              // label={ __('Select your store') }
              attr={ {
                  name: 'store',
                  defaultValue: isPlaceholder ? undefined : pickup_location_code,
                  selectPlaceholder: __('Select Your Desired Store')
              } }
              options={ pickupStoresToOptions(filteredPickupStores || [selectedStore]) }
              events={ {
                  onChange: onPickupStoreSelect
              } }
              addRequiredTag
              validateOn={ ['onChange'] }
              validationRule={ {
                  isRequired: true
              } }
              isSearch
            />
        );
    }

    render() {
        return (
            <div block="DeliveryOptionPickup">
                { this.renderStoreSelection() }
            </div>
        );
    }
}

export default DeliveryOptionPickupComponent;
