/** Dummy commit to change static content */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';
import { updateCategoryDropdownState } from 'Store/CategoryDropdown/CategoryDropdown.action';
import CategoryDropdownReducer from 'Store/CategoryDropdown/CategoryDropdown.reducer';
import { MixType } from 'Type/Common.type';
import { isCrawler, isSSR } from 'Util/Browser';
import { getFixedElementHeight } from 'Util/CSS';
import { withReducers } from 'Util/DynamicReducer';

import './ExpandableContent.override.style';

/** @namespace Scandipwa/Component/ExpandableContent/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    isCategoryDropdownExpanded: state.CategoryDropdownReducer.isCategoryDropdownExpanded
});

/** @namespace Scandipwa/Component/ExpandableContent/Component/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCategoryDropdownState: (isCategoryDropdownExpanded) => dispatch(
        updateCategoryDropdownState(isCategoryDropdownExpanded)
    )
});

/** @namespace Scandipwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static propTypes = {
        ...this.propTypes,
        isExpandableOnDesktop: PropTypes.bool,
        isMobileFilterOverlay: PropTypes.bool,
        renderHeading: PropTypes.func,
        onChange: PropTypes.func,
        isHeadingHidden: PropTypes.bool,
        isClosedOnClick: PropTypes.bool,
        defaultIsContentExpanded: PropTypes.bool,
        isScrollOnExpand: PropTypes.bool,
        heading: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        mix: MixType
    };

    static defaultProps = {
        ...this.defaultProps,
        defaultIsContentExpanded: null,
        isExpandableOnDesktop: false,
        isMobileFilterOverlay: false,
        renderHeading: null,
        onChange: null,
        isHeadingHidden: false,
        isClosedOnClick: false,
        isScrollOnExpand: true,
        mix: {}
    };

    expandableContentContentRef = createRef();

    expandableContentContentChildRef = createRef();

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps) {
        const { isFilterOnPlp, heading } = this.props;
        const { heading: prevHeading } = prevProps;

        if (!isFilterOnPlp
            && heading !== prevHeading
            && this.expandableContentContentRef?.current
        ) {
            this.expandableContentContentRef.current.setAttribute('style', '');
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);

        const { isContentExpanded } = this.state;
        const { defaultIsContentExpanded } = this.props;

        const isForceExpanded = isSSR() || isCrawler();

        this.state = {
            ...this.state,
            initialCartOverlayExpand: false,
            isContentExpanded: defaultIsContentExpanded ?? (isForceExpanded || isContentExpanded),
            prevIsContentExpanded: isContentExpanded,
            expandContentHeight: -1,
            wasContentHeightIncreased: isContentExpanded
        };
    }

    /**
     * Overridden to force expand OrderSummary when overlay is CartOverlay
     */
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    static getDerivedStateFromProps({
        isContentExpanded,
        isCartOverlay
    }, {
        prevIsContentExpanded,
        initialCartOverlayExpand
    }) {
        if (isCartOverlay && initialCartOverlayExpand) {
            return {
                initialCartOverlayExpand: false,
                isContentExpanded: false,
                prevIsContentExpanded: isContentExpanded
            };
        }

        if (isContentExpanded !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isContentExpanded,
                isContentExpanded
            };
        }

        if (isCartOverlay) {
            return {
                initialCartOverlayExpand: true
            };
        }

        return null;
    }

    /**
     * Overridden to account for Header And Search Field Height
     */
    scrollToExpandedContent() {
        const { device: { isMobile } } = this.props;

        // eslint-disable-next-line no-magic-numbers
        const headerHeight = isMobile ? 96 : 0;

        const elem = this.expandableContentRef && this.expandableContentRef.current;

        if (!elem) {
            return;
        }

        const elemToWindowTopDist = elem.getBoundingClientRect().top;
        const windowToPageTopDist = document.body.getBoundingClientRect().top;
        const topToElemDistance = elemToWindowTopDist - windowToPageTopDist;
        const {
            total: totalFixedElementHeight,
            bottom: bottomFixedElementHeight
        } = getFixedElementHeight();

        const elemMaxOffsetHeight = screen.height > elem.offsetHeight + bottomFixedElementHeight
            ? elem.offsetHeight
            : screen.height - totalFixedElementHeight;

        const scrollTo = topToElemDistance - (headerHeight
        + screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);

        // checking if button is in a view-port
        if (-windowToPageTopDist >= scrollTo) {
            return;
        }

        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }
    /* eslint-enable @scandipwa/scandipwa-guidelines/only-render-in-component */

    /**
     * Overridden to:
     * - Pass Expanding state out of component
     * - Only use scrollToExpandedContent method if isScrollOnExpand is true
     */
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    toggleExpand() {
        const {
            onClick,
            onChange,
            isClosedOnClick,
            isScrollOnExpand,
            isCategoryDropdown,
            updateCategoryDropdownState
        } = this.props;
        const { isContentExpanded } = this.state;

        const isCategoryDropdownExpanded = isContentExpanded && isCategoryDropdown;
        updateCategoryDropdownState(isCategoryDropdownExpanded);

        if (onChange) {
            // isContentExpanded still contains previous state. Then need to negate
            onChange(!isContentExpanded);
        }

        this.toggleExpandContent();

        if (onClick) {
            onClick();
        }

        if (onClick && !isClosedOnClick) {
            return;
        }

        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => {
                if (isScrollOnExpand) {
                    this.scrollToExpandedContent();
                }
            }
        );
    }
    /* eslint-enable @scandipwa/scandipwa-guidelines/only-render-in-component */

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    toggleExpandContent() {
        const {
            expandContentHeight,
            isContentExpanded
        } = this.state;

        if (isContentExpanded) {
            this.setContentHeight(0);
            this.setState({ wasContentHeightIncreased: false });
        } else {
            this.setContentHeight(expandContentHeight);
            this.setState({ wasContentHeightIncreased: true });
        }

        this.calculateNextContentHeight();
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    setContentHeight(height, closedByOthers = false) {
        const { expandContentHeight, isContentExpanded } = this.state;

        if (expandContentHeight < 0 && !isContentExpanded && !closedByOthers) {
            const newHeight = this.calculateNextContentHeight();

            if (newHeight > 0) {
                this.setStyleResetTimeout();
            }

            if (this.expandableContentContentRef?.current) {
                this.expandableContentContentRef.current.setAttribute(
                    'style',
                    `max-height: ${ newHeight }px`
                );
            }

            return;
        }

        if (height > 0) {
            this.setStyleResetTimeout();
        }

        if (this.expandableContentContentRef?.current) {
            this.expandableContentContentRef.current.setAttribute(
                'style',
                `max-height: ${ height }px`
            );
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    setStyleResetTimeout() {
        const styleResetDelay = 500;

        setTimeout(() => {
            if (this.expandableContentContentRef?.current) {
                this.expandableContentContentRef.current.setAttribute('style', 'max-height: 1500px');
            }
        }, styleResetDelay);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    calculateNextContentHeight() {
        const contentMargin = 30;
        const contentHeight = this.expandableContentContentChildRef?.current
            ? this.expandableContentContentChildRef.current.getBoundingClientRect().height
            : 0;
        const expandContentHeight = contentHeight + contentMargin;

        this.setState({ expandContentHeight });

        return expandContentHeight;
    }

    /**
     * Overridden so when "isExpandableOnDesktop" prop is passed the icon appears on desktop
     */
    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow, device: { isMobile }, isExpandableOnDesktop } = this.props;

        if (!isMobile) {
            if (isExpandableOnDesktop) {
                if (isArrow) {
                    return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
                }

                return this.renderTogglePlusMinus();
            }

            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    /**
     * Overridden so when "isExpandableOnDesktop" prop is passed the content is expandable on desktop
     */
    renderContent() {
        const {
            children,
            mix,
            isFilterOnPlp,
            moreFiltersHeight
        } = this.props;
        const {
            isContentExpanded,
            wasContentHeightIncreased
        } = this.state;
        const mods = { isContentExpanded };

        if (!this.expandableContentContentRef?.current) {
            this.expandableContentContentRef = createRef();
        }

        if (!this.expandableContentContentChildRef?.current) {
            this.expandableContentContentChildRef = createRef();
        }

        if (wasContentHeightIncreased && !isContentExpanded) {
            this.setContentHeight(0, true);
        }

        if (isFilterOnPlp && isContentExpanded && moreFiltersHeight) {
            this.setContentHeight(moreFiltersHeight);
        }

        return (
            <div
              block="ExpandableContent"
              elem="Content"
              mods={ mods }
              mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
              ref={ this.expandableContentContentRef }
            >
                <div ref={ this.expandableContentContentChildRef }>
                    { children }
                </div>
            </div>
        );
    }

    /**
     * New method to render heading
     */
    renderHeading() {
        const { isContentExpanded } = this.state;
        const {
            heading, isMobileFilterOverlay, renderHeading
        } = this.props;

        if (renderHeading) {
            return renderHeading();
        }

        if (isMobileFilterOverlay) {
            const { title, options } = heading;

            if (isContentExpanded) {
                return <h3>{ title }</h3>;
            }

            return (
                <>
                    <h3>{ title }</h3>
                    { options && <span>{ `${title}: ${options}` }</span> }
                </>
            );
        }

        if (typeof heading === 'string') {
            return <TextPlaceholder content={ heading } length="medium" />;
        }

        return heading;
    }

    /**
     * Overridden to render heading through renderHeading method
     */
    renderButton() {
        const { isContentExpanded } = this.state;
        const {
            mix, isHeadingHidden, isCategoryDropdown
        } = this.props;

        if (isHeadingHidden) {
            return null;
        }

        const isCategoryDropdownExpanded = isContentExpanded && isCategoryDropdown;

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded, isCategoryDropdownExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
              onKeyDown={ this.toggleExpand }
            >
                <div
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { this.renderHeading() }
                </div>
                { this.renderButtonIcon() }
            </div>
        );
    }
}

export default withReducers({
    CategoryDropdownReducer
})(connect(mapStateToProps, mapDispatchToProps)(ExpandableContentComponent));
