/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { fetchQuery } from 'Util/Request';

import AmastyReturnInformationQuery from '../../query/AmastyReturnInformation.query';
import { ProductType } from '../../type/AmastyRMA.type';
import AmastyMoreInformation from './AmastyMoreInformation.component';
import { RMA_ARRAY_LENGTH } from './AmastyMoreInformation.config';

/** @namespace Scandiweb/AmastyRma/Component/AmastyMoreInformation/Container */
export class AmastyMoreInformationContainer extends PureComponent {
    static propTypes = {
        productName: PropTypes.string,
        returnInfo: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired
        })),
        isCart: PropTypes.bool,
        isShowLoader: PropTypes.bool,
        product: ProductType.isRequired
    };

    static defaultProps = {
        productName: '',
        isCart: false,
        isShowLoader: true,
        returnInfo: null
    };

    containerFunctions = {
    };

    state = {
        isLoading: true,
        returnInfoState: []
    };

    static getDerivedStateFromProps(props) {
        const { returnInfo } = props;

        return { returnInfo };
    }

    componentDidMount() {
        this.fetchReturnInformation();
    }

    async fetchReturnInformation() {
        const { returnInfo } = this.props;

        this.setState({
            returnInfoState: returnInfo
        });

        if (returnInfo && returnInfo?.length) {
            this.setState({
                isLoading: false
            });

            return;
        }

        try {
            const { product: { name } } = this.props;

            if (!name) {
                return;
            }

            const {
                products: {
                    items = []
                } = {}
            } = await fetchQuery(
                AmastyReturnInformationQuery.getProductsField(name) || {},
            );

            const { amrma_return_info = null } = items.find(
                (item) => item.amrma_return_info.length === RMA_ARRAY_LENGTH
            ) || items.find(
                (item) => item.amrma_return_info.length === 1
            ) || {};

            this.setState({
                returnInfoState: amrma_return_info
            });
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    getReturnDates() {
        const { product } = this.props;
        if (!product) {
            return null;
        }

        const { type_id } = product;

        if (type_id === PRODUCT_TYPE.configurable) {
            return product.variants[0].amrma_return_info;
        }

        return product.amrma_return_info;
    }

    containerProps() {
        const {
            isLoading,
            returnInfoState
        } = this.state;

        const {
            isCart,
            isShowLoader
        } = this.props;

        return {
            isLoading,
            returnInfo: returnInfoState,
            isCart,
            isShowLoader,
            returnPeriod: this.getReturnDates()
        };
    }

    render() {
        return (
            <AmastyMoreInformation
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AmastyMoreInformationContainer;
