/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Pan Emirates
 * @author      Ruslan Peka <ruslan.peka@scandiweb.com>
 */

import { UPDATE_CATEGORY_DROPDOWN_STATE } from './CategoryDropdown.action';

export const initialState = {
    isCategoryDropdownExpanded: false
};

/** @namespace Scandipwa/Store/CategoryDropdown/Reducer/CategoryDropdownReducer */
export const CategoryDropdownReducer = (state = initialState, action) => {
    const { isCategoryDropdownExpanded } = action;

    switch (action.type) {
    case UPDATE_CATEGORY_DROPDOWN_STATE:
        return {
            ...state,
            isCategoryDropdownExpanded
        };
    default:
        return state;
    }
};

export default CategoryDropdownReducer;
