import PropTypes from 'prop-types';

import { FormContainer as SourceFormContainer } from 'SourceComponent/Form/Form.container';

/** @namespace Scandipwa/Component/Form/Container */
export class FormContainer extends SourceFormContainer {
    static propTypes = {
        ...SourceFormContainer.propTypes,
        shouldPreventNestedSubmission: PropTypes.bool
    };

    static defaultProps = {
        ...SourceFormContainer.defaultProps,
        shouldPreventNestedSubmission: false
    };

    async onSubmit(e) {
        SourceFormContainer.prototype.onSubmit.call(this, e);

        const { shouldPreventNestedSubmission } = this.props;

        if (shouldPreventNestedSubmission) {
            e.stopPropagation();
        }
    }
}

export default FormContainer;
