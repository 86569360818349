import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';

import CheckmarkIcon from 'Component/CheckmarkIcon';
import CheckoutSlots from 'Component/CheckoutSlots';
import DeliveryOptionPickup from 'Component/DeliveryOptionPickup';
import EditIcon from 'Component/EditIcon';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import { noopFn } from 'Util/Common';

import SourceCheckoutDeliveryOptions from './CheckoutDeliveryOptions.component.source';

import './CheckoutDeliveryOptions.override.style';

export const CheckoutDeliveryOption = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-delivery-options-option" */
    'Component/CheckoutDeliveryOption'
));

/** @namespace Scandipwa/Component/CheckoutDeliveryOptions/Component */
export class CheckoutDeliveryOptionsComponent extends SourceCheckoutDeliveryOptions {
    static propTypes = {
        ...this.propTypes,
        resetDelivery: PropTypes.func.isRequired,
        renderOrderTotal: PropTypes.func.isRequired,
        onSlotSelect: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
        isValid: PropTypes.bool,
        isMobile: PropTypes.bool.isRequired,
        isShowButtonsOnly: PropTypes.bool.isRequired,
        isShowPaymentButtonOnly: PropTypes.bool.isRequired,
        isDeliveryFieldsValidated: PropTypes.bool.isRequired
    };

    optionMap = {
        [STORE_IN_PICK_UP_METHOD_CODE]: () => <DeliveryOptionPickup />
    };

    static defaultProps = {
        ...this.defaultProps,
        isDisabled: false,
        isValid: false
    };

    /**
     * New method to render the edit button which resets shipping method and allow choosing another
     */
    renderEditButton() {
        const { resetDelivery } = this.props;

        return (
            <button
              type="button"
              block="CheckoutAddressBook"
              elem="EditButton"
              onClick={ resetDelivery }
            >
                <EditIcon />
                { __('Edit') }
            </button>
        );
    }

    /**
     * Overridden to change the block to this component's css block, also added the step number and edit button
     */
    renderHeading() {
        const { isValid, isShowButtonsOnly, isShowPaymentButtonOnly } = this.props;
        const stepNumber = isValid ? <CheckmarkIcon /> : 2;

        if (isShowButtonsOnly || isShowPaymentButtonOnly) {
            return null;
        }

        return (
            <div block="CheckoutDeliveryOptions" elem="Header">
                <div block="CheckoutDeliveryOptions" elem="Heading" mods={ { isValid } }>
                    <span>
                        { stepNumber }
                    </span>
                    <h2>
                        { __('Delivery method') }
                    </h2>
                </div>
                { isValid && this.renderEditButton() }
            </div>
        );
    }

    /**
     * New method to render proceed to payment button
     * Add the "isButtonDisabled" to disable only the button (unlike "isDisable" which disables the whole step)
     */
    renderProceedButton() {
        const {
            isValid,
            isDisabled,
            renderOrderTotal,
            isMobile,
            isSelectedMethodValid,
            isShowButtonsOnly,
            isDeliveryFieldsValidated
        } = this.props;

        if (isValid || isShowButtonsOnly || isDisabled) {
            return null;
        }

        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                { renderOrderTotal() }
                <button
                  type="submit"
                  block="CheckoutDeliveryOptions"
                  elem="Button"
                  disabled={ !isSelectedMethodValid || !isDeliveryFieldsValidated }
                  mix={ {
                      block: 'Button',
                      mods: { isMedium: isMobile }
                  } }
                >
                    { __('Proceed to payment') }
                </button>
            </div>
        );
    }

    /**
     * Overridden to remove the on click function and add isValidated prop to the selected method after validation
     */
    renderDeliveryOption(option) {
        const {
            selectShippingMethod,
            selectedShippingMethod: { method_code: selectedMethodCode },
            isValid,
            estimateAddress,
            onSlotSelect,
            setLoading,
            isShowButtonsOnly
        } = this.props;

        const { carrier_code, method_code } = option;
        const isSelected = selectedMethodCode === method_code;

        return (
            <Suspense fallback={ this.renderOptionFallback() }>
                <CheckoutDeliveryOption
                  key={ carrier_code }
                  isSelected={ isValid ? true : isSelected }
                  option={ option }
                  onClick={ isValid ? noopFn : selectShippingMethod }
                  onSlotSelect={ onSlotSelect }
                  isValidated={ isValid }
                  estimateAddress={ estimateAddress }
                  setLoading={ setLoading }
                  isShowButtonsOnly={ isShowButtonsOnly }
                />
            </Suspense>
        );
    }

    renderOptionFallback() {
        return (
            <li block="CheckoutDeliveryOption" elem="Fallback" />
        );
    }

    renderSlots() {
        const {
            selectedShippingMethod: { method_code: methodCode } = {},
            estimateAddress,
            onSlotSelect,
            setLoading
        } = this.props;

        return (
            <CheckoutSlots
              onSlotSelect={ onSlotSelect }
              estimateAddress={ estimateAddress }
              methodCode={ methodCode }
              setLoading={ setLoading }
            />
        );
    }

    /**
     * Overridden to show only the selected method if the method selection is finished
     */
    renderShippingMethods() {
        const {
            shippingMethods,
            selectedShippingMethod,
            isValid,
            isShowPaymentButtonOnly
        } = this.props;

        if (isShowPaymentButtonOnly && selectedShippingMethod.method_code === 'standartShipping') {
            return this.renderSlots();
        }

        if (isShowPaymentButtonOnly) {
            return null;
        }

        if (isValid) {
            return this.renderDeliveryOption(selectedShippingMethod);
        }

        if (!shippingMethods?.length) {
            return [{ available: true }, { available: true }].map(this.renderDeliveryOption.bind(this));
        }

        return shippingMethods.map(this.renderDeliveryOption.bind(this));
    }

    renderContent() {
        const {
            selectedShippingMethod,
            isShowButtonsOnly
        } = this.props;

        if (!isShowButtonsOnly) {
            return null;
        }

        const { method_code } = selectedShippingMethod || {};

        const optionRender = this.optionMap[method_code];

        return typeof selectedShippingMethod === 'object' && optionRender ? optionRender() : null;
    }

    /**
     * Overridden to add the proceed button
     */
    render() {
        const {
            isDisabled,
            isShowButtonsOnly,
            isValid
        } = this.props;

        return (
            <div block="CheckoutDeliveryOptions" mods={ { isDisabled, isShowButtonsOnly, isValid } }>
                { this.renderHeading() }
                <ul>
                    { this.renderShippingMethods() }
                </ul>
                { this.renderContent() }
                { this.renderProceedButton() }
            </div>
        );
    }
}

export default CheckoutDeliveryOptionsComponent;
