/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import { AvailableSocialsType } from '../../type/Social.type';
import SocialLoginButton from '../SocialLoginButton';

import './SocialLoginButtons.style';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButtons/Component */
export class SocialLoginButtonsComponent extends PureComponent {
    static propTypes = {
        availableSocials: AvailableSocialsType.isRequired
    };

    renderSocialButtons() {
        const { availableSocials } = this.props;

        const rows = this.refactorArray(availableSocials);

        return rows.map((row) => (
            <div block="SocialLoginButtons" elem="Row">
                { row.map((col) => (
                    this.renderColumns(col)
                )) }
            </div>
        ));
    }

    renderColumns(col) {
        return (
            <div block="SocialLoginButtons" elem="Column">
                <SocialLoginButton
                  key={ col.type }
                  social={ col }
                />
            </div>
        );
    }

    // Regular array [ 1, 2, 3, 4] to [[1, 2], [3, 4]] for rendering in two columns
    refactorArray = (array) => array.reduce((rows, key, index) => (index % 2 === 0 ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows, []);

    render() {
        return (
            <div block="SocialLoginButtons">
                { this.renderSocialButtons() }
            </div>
        );
    }
}

export default SocialLoginButtonsComponent;
