/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/prop-types */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getCartId } from 'Util/Cart';
import { withReducers } from 'Util/DynamicReducer';
import { fetchQuery } from 'Util/Request';

import {
    INSTALLMENT_CARD_BIN_LENGTH,
    MIN_DIGITS_FOR_CARD_NUMBER,
    PayfortIdType,
    PayfortIntegrationTypes
} from '../../Payfort.config';
import PayfortInstallmentPlansQuery from '../../query/PayfortInstallmentPlans.query';
import { updateAmazonPayfortStore } from '../../store/AmazonPayfort/AmazonPayfort.action';
import AmazonPayfortReducer from '../../store/AmazonPayfort/AmazonPayfort.reducer';
import InstallmentPaymentMethod from './InstallmentPaymentMethod.component';

/** @namespace Scandiweb/AmazonPayfort/Component/InstallmentPaymentMethod/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    integrationType: state.ConfigReducer.payfortConfig.apsInstallment.integrationType
});

/** @namespace Scandiweb/AmazonPayfort/Component/InstallmentPaymentMethod/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateSelectedPlan: (selectedPlan) => dispatch(updateAmazonPayfortStore({ selectedPlan }))
});

/** @namespace Scandiweb/AmazonPayfort/Component/InstallmentPaymentMethod/Container */
export class InstallmentPaymentMethodContainer extends PureComponent {
    static propTypes = {
        integrationType: PropTypes.string.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired,
        originalRender: PropTypes.element.isRequired,
        isSelected: PropTypes.bool.isRequired
    };

    containerFunctions = {
        changeSelectedCountry: this.changeSelectedCountry.bind(this),
        changeSelectedBank: this.changeSelectedBank.bind(this),
        changeSelectedPlan: this.changeSelectedPlan.bind(this),
        validateCardNumber: this.validateCardNumber.bind(this)
    };

    componentDidUpdate() {
        const { isSelected, integrationType } = this.props;
        const { plansFetched, isLoading } = this.state;

        if (
            integrationType === PayfortIntegrationTypes.HOSTED
            && isSelected
            && !plansFetched
            && !isLoading
        ) {
            this.fetchPlans().catch(this.setUnsuccessfulFetchState.bind(this));
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isEmpty: true,
            countries: [],
            banks: {},
            countriesBanks: {},
            banksPlans: {},
            plansByCode: {},
            selectedCountry: null,
            selectedBankCode: null,
            selectedBankTermsUrl: null,
            selectedBankBins: null,
            plansFetched: false,
            isPlansFetchSuccessful: false,
            isLoading: false,
            isCardApplicableWithSelectedBank: false
        };
    }

    async fetchPlans() {
        const { setOrderButtonEnableStatus } = this.props;

        this.setState({ isLoading: true });

        setOrderButtonEnableStatus(false);

        const query = await fetchQuery(
            PayfortInstallmentPlansQuery.getPayfortInstallmentPlansField(
                PayfortIdType.CART,
                getCartId()
            )
        );

        const {
            payfortInstallmentPlans: {
                success,
                isEmpty,
                countries = [],
                issuers = [],
                plans = []
            }
        } = query;

        if (!success) {
            this.setUnsuccessfulFetchState();

            return;
        }

        if (isEmpty) {
            this.setState({
                isEmpty,
                plansFetched: true,
                isPlansFetchSuccessful: true,
                isLoading: false
            });

            return;
        }

        const { getObjectFromArray } = await import('../../util/Payfort.util');

        this.setState({
            isEmpty,
            countries,
            banks: getObjectFromArray(issuers, 'issuer_code', true),
            countriesBanks: getObjectFromArray(issuers, 'country_code'),
            banksPlans: getObjectFromArray(plans, 'issuer_code'),
            plansByCode: getObjectFromArray(plans, 'plan_code', true),
            plansFetched: true,
            isPlansFetchSuccessful: true,
            isLoading: false
        });
    }

    setUnsuccessfulFetchState() {
        this.setState({
            plansFetched: true,
            isPlansFetchSuccessful: false,
            isLoading: false
        });
    }

    changeSelectedCountry(value) {
        this.setState({
            selectedCountry: value,
            selectedBankCode: null
        });
    }

    changeSelectedBank(selectedBankCode) {
        const { banks } = this.state;
        const selectedBank = banks[selectedBankCode];

        this.setState({
            selectedBankCode,
            selectedBankTermsUrl: selectedBank ? selectedBank.terms_url : '#',
            selectedBankBins: selectedBank ? new Set(selectedBank.bins) : new Set([])
        });
    }

    changeSelectedPlan(value) {
        const { updateSelectedPlan } = this.props;
        const { plansByCode } = this.state;

        updateSelectedPlan(plansByCode[value]);
    }

    validateCardNumber(value) {
        const { selectedBankBins } = this.state;
        const numericValue = value.replace(/[^\d]/g, '');

        if (numericValue.length < INSTALLMENT_CARD_BIN_LENGTH || !selectedBankBins) {
            return false;
        }

        const isCardApplicableWithSelectedBank = selectedBankBins.has(
            numericValue.slice(0, INSTALLMENT_CARD_BIN_LENGTH)
        );
        const isMinimumLength = numericValue.length >= MIN_DIGITS_FOR_CARD_NUMBER;

        this.setState({
            isCardApplicableWithSelectedBank
        });

        return isCardApplicableWithSelectedBank && isMinimumLength;
    }

    containerProps() {
        const {
            integrationType,
            isSelected,
            originalRender,
            setOrderButtonEnableStatus
        } = this.props;

        const {
            selectedBankTermsUrl,
            isPlansFetchSuccessful,
            isEmpty,
            countries,
            countriesBanks,
            banksPlans,
            selectedCountry,
            selectedBankCode,
            isLoading,
            isCardApplicableWithSelectedBank
        } = this.state;

        return {
            integrationType,
            isSelected,
            originalRender,
            countries,
            banks: countriesBanks[selectedCountry] || [],
            plans: banksPlans[selectedBankCode] || [],
            selectedCountry,
            selectedBankCode,
            selectedBankTermsUrl,
            isPlansFetchSuccessful,
            isEmpty,
            setOrderButtonEnableStatus,
            isLoading,
            isCardApplicableWithSelectedBank
        };
    }

    render() {
        return (
            <InstallmentPaymentMethod
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withReducers({
    AmazonPayfortReducer
})(connect(mapStateToProps, mapDispatchToProps)(InstallmentPaymentMethodContainer));
