import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';

import CheckoutDeliveryOptions from 'Component/CheckoutDeliveryOptions';
import ContentWrapper from 'Component/ContentWrapper';
import ExpressCheckoutMethodsList from 'Component/ExpressCheckoutMethodsList';
import Loader from 'Component/Loader';
import {
    BILLING_STEP,
    CHECKOUT_URL,
    CHECKOUT_URL_REGEX,
    DETAILS_STEP,
    SHIPPING_STEP
} from 'Route/Checkout/Checkout.config';
import { THANK_YOU_URL } from 'Route/ThankYouPage/ThankYouPage.config';
import {
    Checkout as SourceCheckout
} from 'SourceRoute/Checkout/Checkout.component';
import { Addresstype } from 'Type/Account.type';
import { SlotsType } from 'Type/Checkout.type';
import { RefType } from 'Type/Common.type';
import { scrollToTop } from 'Util/Browser';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { appendWithStoreCode } from 'Util/Url';

import './Checkout.override.style';

export const CheckoutOrderSummary = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-order-summary" */
    'Component/CheckoutOrderSummary'
));

export const CmsBlock = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CmsBlock'
));

export const CheckoutStage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-stage" */
    'Component/CheckoutStage'
));

export const ExpandableContent = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-expandable-content" */
    'Component/ExpandableContent'
));

export const CheckoutShipping = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-shipping" */
    'Component/CheckoutShipping'
));

export const CheckoutBilling = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-billing" */
    'Component/CheckoutBilling'
));

export const CheckoutSuccess = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-success" */
    'Component/CheckoutSuccess'
));

export const CartCoupon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-cart-coupon" */
    'Component/CartCoupon'
));

/** @namespace Scandipwa/Route/Checkout/Component */
export class CheckoutComponent extends SourceCheckout {
    static propTypes = {
        ...this.propTypes,
        finishedAddress: PropTypes.bool.isRequired,
        onFinishAddress: PropTypes.func.isRequired,
        isDeliveryFinished: PropTypes.bool.isRequired,
        resetAddress: PropTypes.func.isRequired,
        resetDelivery: PropTypes.func.isRequired,
        setSelectedPaymentMethod: PropTypes.func.isRequired,
        paymentMethod: PropTypes.string.isRequired,
        onSlotSelect: PropTypes.func.isRequired,
        selectedSlot: SlotsType.isRequired,
        isMobileOrderSummaryExpanded: PropTypes.bool.isRequired,
        handleToggleMobileOrderSummary: PropTypes.func.isRequired,
        updatePaymentMethods: PropTypes.func.isRequired,
        setCustomAddress: PropTypes.func.isRequired,
        paymentMethodAdditionalFee: PropTypes.number.isRequired,
        getTotal: PropTypes.func.isRequired,
        checkoutDeliveryFormRef: RefType.isRequired,
        checkoutPaymentFormRef: RefType.isRequired,
        customAddress: Addresstype.isRequired,
        configCode: PropTypes.string.isRequired,
        isDeliveryFieldsValidated: PropTypes.bool.isRequired
    };

    /**
     * Overridden to change the URL for shipping and billing step
     */
    stepMap = {
        ...this.stepMap,
        [SHIPPING_STEP]: {
            number: 1,
            title: __('Personal information'),
            url: '',
            render: this.renderShippingStep.bind(this),
            areTotalsVisible: true
        },
        [BILLING_STEP]: {
            number: 2,
            title: __('Payment'),
            url: '',
            render: this.renderBillingStep.bind(this),
            areTotalsVisible: true
        }
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        setLoadedFlag();
    }

    /**
     * Overridden to remove pick in store delivery step
     */
    renderStoreInPickUpMethod() {
        return null;
    }

    /**
     * Overridden to:
     * - Make ExpandableContent Expandable on desktop
     * - Change expandable content heading
     * - Render even if the checkout step is different than billing step
     * - Pass onCouponCodeUpdate to CartCoupon component
     */
    renderDiscountCode() {
        const {
            totals: {
                items = [],
                prices: {
                    coupon_code
                } = {}
            },
            updatePaymentMethods
        } = this.props;

        if (!items || items.length < 1) {
            return null;
        }

        return (
            <ExpandableContent
              heading={ __('Do you have coupon or voucher code?') }
              mix={ { block: 'Checkout', elem: 'Discount' } }
              isArrow
              isExpandableOnDesktop
            >
                <CartCoupon couponCode={ coupon_code } onCouponCodeUpdate={ updatePaymentMethods } />
            </ExpandableContent>
        );
    }

    /**
     * Overridden to pass props to:
     * - Detect and signal the end of a step.
     * - Reset the selection.
     * Overridden to pass handleToggleMobileOrderSummary prop.
     */
    renderShippingStep() {
        const {
            shippingMethods,
            onShippingEstimationFieldsChange,
            saveAddressInformation,
            isDeliveryOptionsLoading,
            onPasswordChange,
            onCreateUserChange,
            onEmailChange,
            isCreateUser,
            isGuestEmailSaved,
            estimateAddress,
            isPickInStoreMethodSelected,
            handleSelectDeliveryMethod,
            cartTotalSubPrice,
            onShippingMethodSelect,
            onStoreSelect,
            selectedStoreAddress,
            onChangeEmailRequired,
            finishedAddress,
            isDeliveryFinished,
            onFinishAddress,
            resetAddress,
            resetDelivery,
            onSlotSelect,
            isMobileOrderSummaryExpanded,
            handleToggleMobileOrderSummary,
            getTotal,
            checkoutDeliveryFormRef,
            customAddress,
            setCustomAddress,
            selectedShippingMethod,
            isDeliveryFieldsValidated
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> }>
                <CheckoutShipping
                  isLoading={ isDeliveryOptionsLoading }
                  shippingMethods={ shippingMethods }
                  cartTotalSubPrice={ cartTotalSubPrice }
                  saveAddressInformation={ saveAddressInformation }
                  onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                  onShippingMethodSelect={ onShippingMethodSelect }
                  onPasswordChange={ onPasswordChange }
                  onCreateUserChange={ onCreateUserChange }
                  onEmailChange={ onEmailChange }
                  isCreateUser={ isCreateUser }
                  isGuestEmailSaved={ isGuestEmailSaved }
                  estimateAddress={ estimateAddress }
                  handleSelectDeliveryMethod={ handleSelectDeliveryMethod }
                  isPickInStoreMethodSelected={ isPickInStoreMethodSelected }
                  onStoreSelect={ onStoreSelect }
                  selectedStoreAddress={ selectedStoreAddress }
                  onChangeEmailRequired={ onChangeEmailRequired }
                  finishedAddress={ finishedAddress }
                  isDeliveryFinished={ isDeliveryFinished }
                  onFinishAddress={ onFinishAddress }
                  resetAddress={ resetAddress }
                  resetDelivery={ resetDelivery }
                  onSlotSelect={ onSlotSelect }
                  isMobileOrderSummaryExpanded={ isMobileOrderSummaryExpanded }
                  handleToggleMobileOrderSummary={ handleToggleMobileOrderSummary }
                  getTotal={ getTotal }
                  checkoutDeliveryFormRef={ checkoutDeliveryFormRef }
                  customAddress={ customAddress }
                  setCustomAddress={ setCustomAddress }
                  selectedShippingMethod={ selectedShippingMethod }
                  isDeliveryFieldsValidated={ isDeliveryFieldsValidated }
                  isShowPaymentOnly
                />
            </Suspense>
        );
    }

    /**
     * Overridden to:
     * - Pass isDeliveryFinished to detect the end of the delivery selection
     * - Pass handleToggleMobileOrderSummary prop
     */
    renderBillingStep() {
        const {
            setLoading,
            setDetailsStep,
            shippingAddress,
            paymentMethods = [],
            savePaymentInformation,
            selectedShippingMethod,
            onChangeEmailRequired,
            isDeliveryFinished,
            setSelectedPaymentMethod,
            isMobileOrderSummaryExpanded,
            handleToggleMobileOrderSummary,
            updatePaymentMethods,
            getTotal,
            checkoutPaymentFormRef
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> }>
                <CheckoutBilling
                  setLoading={ setLoading }
                  paymentMethods={ paymentMethods }
                  setDetailsStep={ setDetailsStep }
                  shippingAddress={ shippingAddress }
                  savePaymentInformation={ savePaymentInformation }
                  selectedShippingMethod={ selectedShippingMethod }
                  onChangeEmailRequired={ onChangeEmailRequired }
                  isDeliveryFinished={ isDeliveryFinished }
                  setSelectedPaymentMethod={ setSelectedPaymentMethod }
                  isMobileOrderSummaryExpanded={ isMobileOrderSummaryExpanded }
                  handleToggleMobileOrderSummary={ handleToggleMobileOrderSummary }
                  updatePaymentMethods={ updatePaymentMethods }
                  getTotal={ getTotal }
                  checkoutPaymentFormRef={ checkoutPaymentFormRef }
                />
            </Suspense>
        );
    }

    /**
     * Overridden to:
     * - Pass discount into order summary
     * - Add isCartOverlay to get the same render method as cart overlay
     * - Disable the order summary rendering when showOnMobile and isMobile are true
     * and isMobileOrderSummaryExpanded is false
     * - Pass updatePaymentMethods prop to CheckoutOrderSummary
     */
    renderSummary(showOnMobile = false) {
        const {
            checkoutTotals,
            checkoutStep,
            paymentTotals,
            isMobile,
            updatePaymentMethods,
            isMobileOrderSummaryExpanded,
            selectedShippingMethod,
            shippingMethods,
            paymentMethodAdditionalFee
        } = this.props;

        const { areTotalsVisible } = this.stepMap[checkoutStep];
        const { renderPromo } = this.renderPromo(true) || {};

        if (
            !areTotalsVisible
            || (showOnMobile && !isMobile)
            || (!showOnMobile && isMobile)
            || (showOnMobile && isMobile && !isMobileOrderSummaryExpanded)
        ) {
            return null;
        }

        return (
            <Suspense fallback={ <Loader /> }>
                <CheckoutOrderSummary
                  checkoutStep={ checkoutStep }
                  totals={ checkoutTotals }
                  paymentTotals={ paymentTotals }
                  updatePaymentMethods={ updatePaymentMethods }
                  renderCmsBlock={ renderPromo }
                  shippingMethods={ shippingMethods }
                  selectedShippingMethod={ selectedShippingMethod }
                  paymentMethodAdditionalFee={ paymentMethodAdditionalFee }
                  showItems
                  isCartOverlay
                  isCheckout
                >
                    { this.renderDiscountCode() }
                </CheckoutOrderSummary>
            </Suspense>
        );
    }

    /**
     * Overridden to:
     * - Remove the progress of the steps
     * - Disable the rendering method when isMobile is true
     */
    renderTitle() {
        const {
            checkoutStep,
            totals: { is_virtual },
            isMobile
        } = this.props;
        const { title = '', number } = this.stepMap[checkoutStep];
        const isRenderHeaderRequired = (is_virtual || !number) && !isMobile;

        if (!isRenderHeaderRequired) {
            return null;
        }

        return (
            <div block="Checkout" elem="Header">
                <div block="Checkout" elem="Title">{ title }</div>
            </div>
        );
    }

    /**
     * Overridden to render shipping and billing steps together at the same time
     */
    renderStep() {
        const { checkoutStep } = this.props;
        const renderBillingStep = this.stepMap[BILLING_STEP].render;
        const renderShippingStep = this.stepMap[SHIPPING_STEP].render;

        if (renderBillingStep && renderShippingStep && checkoutStep !== DETAILS_STEP) {
            return (
                <>
                    { this.renderDelivery() }
                    { renderShippingStep() }
                    { renderBillingStep() }
                </>
            );
        }

        return null;
    }

    /**
     * Overridden to remove scrollToTop,
     * Disabled guideline to allow the use of this function inside the component
     */
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    updateStep() {
        this.updateStepURL();
    }
    /* eslint-enable @scandipwa/scandipwa-guidelines/only-render-in-component */

    /**
     * Overridden to redirect to a new page (thank you page) when in details step
     * Overridden to pass email to thank you page
     * Disabled guideline to allow the use of this function inside the component
     */
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    updateStepURL(isMounting = false) {
        const {
            checkoutStep,
            history,
            isCartLoading,
            orderID,
            shippingMethods,
            selectedShippingMethod,
            shippingAddress,
            billingAddress,
            totals,
            totals: {
                items
            },
            paymentMethod,
            paymentMethods,
            paymentMethodAdditionalFee,
            selectedSlot,
            email
        } = this.props;
        const { url } = this.stepMap[checkoutStep];
        const { pathname = '' } = location;

        if (checkoutStep === DETAILS_STEP) {
            scrollToTop();

            const selectedPaymentMethod = paymentMethods.find((p) => p.code === paymentMethod);

            const order = window.cachedOrder || {
                increment_id: orderID,
                shipping_method: shippingMethods
                    .find((s) => s.method_code === selectedShippingMethod)?.method_title,
                shipping_address: shippingAddress,
                billing_address: billingAddress,
                payment_methods: [{
                    name: selectedPaymentMethod?.title,
                    code: selectedPaymentMethod?.code
                }],
                total: totals,
                paymentMethodAdditionalFee,
                items,
                deliveryDate: selectedSlot,
                email
            };

            // Doesn't look sexy, but works for the purpose.
            order.total.pan_reference_code = window?.cachedPanReferenceCode || orderID;

            // vvv Cache not needed anymore after single use.
            window.cachedOrder = null;
            window.cachedPanReferenceCode = null;

            history.replace({
                pathname: appendWithStoreCode(THANK_YOU_URL),
                state: {
                    order
                }
            });
        } else if (!(isCartLoading && pathname.match(CHECKOUT_URL_REGEX))) {
            if (isMounting) {
                history.replace(appendWithStoreCode(`${ CHECKOUT_URL }${ url }`));
            } else {
                history.push(appendWithStoreCode(`${ CHECKOUT_URL }${ url }`));
            }
        }
    }

    renderExpressCheckoutMethods() {
        return <ExpressCheckoutMethodsList />;
    }

    renderStage() {
        const { checkoutStep, isMobile } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <Suspense fallback={ <div /> }>
                <CheckoutStage checkoutStep={ checkoutStep } />
            </Suspense>
        );
    }

    renderMobileStage() {
        const { checkoutStep, isMobile } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <Suspense fallback={ <div /> }>
                <CheckoutStage checkoutStep={ checkoutStep } />
            </Suspense>
        );
    }

    renderDelivery() {
        const {
            shippingMethods,
            onShippingMethodSelect,
            estimateAddress,
            onStoreSelect,
            handleSelectDeliveryMethod,
            selectedShippingMethod,
            isDeliveryFinished,
            resetDelivery,
            onSlotSelect,
            isMobileOrderSummaryExpanded,
            setLoading
        } = this.props;

        return (
            <CheckoutDeliveryOptions
              shippingMethods={ shippingMethods }
              onShippingMethodSelect={ onShippingMethodSelect }
              estimateAddress={ estimateAddress }
              onStoreSelect={ onStoreSelect }
              onSlotSelect={ onSlotSelect }
              handleSelectDeliveryMethod={ handleSelectDeliveryMethod }
              selectedShippingMethod={ selectedShippingMethod }
              isValid={ isDeliveryFinished }
              resetDelivery={ resetDelivery }
              /* eslint-enable react/jsx-no-bind */
              isMobileOrderSummaryExpanded={ isMobileOrderSummaryExpanded }
              setLoading={ setLoading }
              isShowButtonsOnly
            />
        );
    }

    /**
     * Overridden to remove renderGuestForm
     */
    render() {
        const { totals, checkoutStep } = this.props;

        if (totals.items.length < 1 && checkoutStep !== DETAILS_STEP) {
            return this.renderFullPageLoader();
        }

        return (
            <main block="Checkout">
                <ContentWrapper
                  wrapperMix={ { block: 'Checkout', elem: 'Wrapper' } }
                  label={ __('Checkout page') }
                >
                    { this.renderMobileStage() }
                    { this.renderSummary(true) }
                    <div>
                        { this.renderExpressCheckoutMethods() }
                        <div block="Checkout" elem="Step">
                            { this.renderTitle() }
                            { this.renderStoreInPickUpMethod() }
                            { this.renderStep() }
                            { this.renderLoader() }
                        </div>
                    </div>
                    <div>
                        <Suspense fallback={ <Loader /> }>
                            { this.renderStage() }
                            { this.renderSummary() }
                            { this.renderPromo() }
                        </Suspense>
                    </div>
                </ContentWrapper>
            </main>
        );
    }
}

export default CheckoutComponent;
