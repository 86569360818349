import PropTypes from 'prop-types';

export const SharedAreaTypes = {
    id: PropTypes.string,
    code: PropTypes.string,
    label: PropTypes.string
};

export const AreaMastersType = PropTypes.shape({
    ...SharedAreaTypes,
    cluster: PropTypes.string
});

export const AreaSubCountriesType = PropTypes.shape({
    ...SharedAreaTypes,
    areaMasters: PropTypes.arrayOf(AreaMastersType)
});

export const AreaCountriesType = PropTypes.shape({
    ...SharedAreaTypes,
    subCountries: PropTypes.arrayOf(AreaSubCountriesType)
});

export const AreasType = PropTypes.arrayOf(AreaCountriesType);
