/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_HOUR_IN_SECONDS } from 'Util/Date';

import { GIFTCARDS_CART_TOTALS } from '../config';
import { isGiftCardsPage } from '../util/Cart';

const setGiftCardsCachedCart = (args, callback) => {
    if (!isGiftCardsPage()) {
        return callback(...args);
    }

    const cartTotals = BrowserDatabase.getItem(GIFTCARDS_CART_TOTALS);
    return BrowserDatabase.setItem(cartTotals, 'tabbyOrderCartData', ONE_HOUR_IN_SECONDS);
};

export default {
    'Scandiweb/AmazonPayfort/Component/RedirectionPage/Container': {
        'member-function': {
            setCachedCart: setGiftCardsCachedCart
        }
    }
};
