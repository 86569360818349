export const SET_PICKUP_STORES = 'SET_PICKUP_STORES';
export const SELECT_PICKUP_STORE = 'SELECT_PICKUP_STORE';
export const SELECT_PICKUP_STORE_IN_CHECKOUT = 'SELECT_PICKUP_STORE_IN_CHECKOUT';

/** @namespace Scandipwa/Store/ClickAndCollect/Action/setPickupStores */
export const setPickupStores = (pickupStores) => ({
    type: SET_PICKUP_STORES,
    pickupStores
});

/** @namespace Scandipwa/Store/ClickAndCollect/Action/selectPickupStore */
export const selectPickupStore = (selectedPickupStore) => ({
    type: SELECT_PICKUP_STORE,
    selectedPickupStore
});

/** @namespace Scandipwa/Store/ClickAndCollect/Action/selectPickupStoreInCheckout */
export const selectPickupStoreInCheckout = (selectedPickupStoreInCheckout) => ({
    type: SELECT_PICKUP_STORE_IN_CHECKOUT,
    selectedPickupStoreInCheckout
});
