/* eslint-disable max-lines */
import { getFiltersCount } from 'Util/Category';
import { getQueryParam } from 'Util/Url';

import { X_DEFAULT } from './CmsPageContainer.plugin';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurationData: state.ConfigReducer.seoConfigurationData
    };
};

export class CategoryPageContainerPlugin {
    addHreflangTags = (hreflangs) => {
        if (hreflangs) {
            const indexOfArAe = hreflangs.findIndex(({ code }) => (code === 'ar-AE'));
            if (indexOfArAe !== -1) {
                const {
                    url
                } = hreflangs[indexOfArAe];

                if (!url.includes('uae_ar')) {
                    const passHttps = 8;
                    const indexOf = url.indexOf('/', passHttps);
                    // eslint-disable-next-line no-param-reassign
                    hreflangs[indexOfArAe].url = `${url.slice(0, indexOf + 1) }uae_ar/${ url.slice(indexOf + 1)}`;
                }
            }
            hreflangs.forEach((data) => {
                if (data?.url.indexOf(X_DEFAULT) !== -1) {
                    const hreflangTag = document.createElement('link');
                    hreflangTag.href = data.url !== '' ? data.url : '/';
                    hreflangTag.rel = 'alternate';
                    hreflangTag.hreflang = 'x-default';

                    if (!document.querySelector(`[rel="alternate"][hreflang="x-default"][href="${data.url}"]`)) {
                        document.getElementsByTagName('head')[0].appendChild(hreflangTag);
                    }
                }

                const hreflangTag = document.createElement('link');
                hreflangTag.href = data.url;
                hreflangTag.rel = 'alternate';
                hreflangTag.hreflang = data.code;

                if (!document.querySelector(`[rel="alternate"][hreflang="${data.code}"][href="${data.url}"]`)) {
                    document.getElementsByTagName('head')[0].appendChild(hreflangTag);
                }
            });
        }
    };

    containerProps = (args, callback, instance) => {
        const { seoConfigurationData } = instance.props;

        return {
            ...callback(...args),
            seoConfigurationData
        };
    };

    componentDidMount = (args, callback, instance) => {
        const { isSearchPage } = instance.props;
        callback(...args);

        if (isSearchPage) {
            return;
        }

        const {
            category: {
                mw_hreflangs = {}
            },
            history: {
                location: {
                    pathname
                }
            }
        } = instance.props;

        if (mw_hreflangs && 'items' in mw_hreflangs && !pathname.includes('search')) {
            this.addHreflangTags(mw_hreflangs.items);
        }
    };

    componentDidUpdate = (args, cb, instance) => {
        const { isSearchPage } = instance.props;

        if (isSearchPage) {
            cb(...args);
            return;
        }

        const {
            category: {
                id,
                mw_hreflangs = {}
            },
            history: {
                location: {
                    pathname
                }
            }
        } = instance.props;

        const [prevProps] = args;
        const {
            category: {
                id: prevId,
                mw_hreflangs: prevMwHreflangs
            }
        } = prevProps;

        if (id !== prevId && id !== undefined && prevId !== undefined && prevMwHreflangs && prevMwHreflangs.items) {
            prevMwHreflangs.items.forEach((data) => {
                const previousHreflang = document.querySelector(
                    `[rel="alternate"][hreflang="${data.code}"][href="${data.url}"]`
                );

                if (previousHreflang) {
                    previousHreflang.remove();
                }

                const previousHreflangDefault = document.querySelector(
                    `[rel="alternate"][hreflang="x-default"][href="${data.url}"]`
                );

                if (previousHreflangDefault) {
                    previousHreflangDefault.remove();
                }
            });
        }

        if (Object.keys(mw_hreflangs).length > 0 && mw_hreflangs !== prevMwHreflangs && !pathname.includes('search')) {
            this.addHreflangTags(mw_hreflangs.items);
        }

        cb(...args);
    };

    constructRobotsTag = (props) => {
        const {
            seoConfigurationData,
            selectedInfoFilter: { customFilters = {} } = {}
        } = props || {};

        const {
            noindex_for_ln_count,
            category_ln_robots,
            robots_for_ln_combinations,
            noindex_for_multiple_values
        } = seoConfigurationData || {};

        const selectedFiltersCount = getFiltersCount(customFilters);

        if (!selectedFiltersCount) {
            return 'index, follow';
        }

        // eslint-disable-next-line fp/no-let
        let robots = selectedFiltersCount ? category_ln_robots : '';

        if (noindex_for_ln_count && selectedFiltersCount >= noindex_for_ln_count) {
            robots = 'noindex, follow';
        }

        if (noindex_for_multiple_values) {
            Object.values(customFilters).forEach((element) => {
                robots = element.length > 1 ? 'noindex, follow' : robots;
            });
        }

        if (selectedFiltersCount && robots_for_ln_combinations.length) {
            robots_for_ln_combinations.forEach((element) => {
                const attributeNames = element.attribute.split('+');

                // eslint-disable-next-line fp/no-let
                let customFiltersApplied = true;

                attributeNames.forEach((attributeName) => {
                    if (!customFilters[attributeName]) {
                        customFiltersApplied = false;
                    }
                });

                robots = customFiltersApplied ? element.robots : robots;
            });
        }

        return robots || 'index, follow';
    };

    updateMeta = (args, callback, instance) => {
        const { isSearchPage } = instance.props;

        if (isSearchPage) {
            callback(...args);

            return;
        }

        const {
            updateMetaFromCategory, category,
            category: {
                mw_canonical_url: { url: canonical_url },
                meta_description: description,
                meta_title: metaTitle
            } = {},
            history: {
                location
            },
            seoConfigurationData: {
                use_pager_for_canonicals: usePager,
                crop_meta_title: cropMetaTitle,
                crop_meta_description: cropMetaDescription
            } = {}
        } = instance.props;

        const updatedDescription = description && cropMetaDescription
            ? description.substring(0, cropMetaDescription) : description;
        const updatedTitle = metaTitle && cropMetaTitle ? metaTitle.substring(0, cropMetaTitle) : metaTitle;

        const pageNumber = getQueryParam('page', location);
        const meta_robots = this.constructRobotsTag(instance.props);

        if (pageNumber && usePager) {
            const updatedUrl = canonical_url.concat(`?page=${pageNumber}`);

            updateMetaFromCategory({
                ...category,
                meta_robots,
                canonical_url: updatedUrl,
                meta_description: updatedDescription,
                meta_title: updatedTitle
            });

            return;
        }

        updateMetaFromCategory({
            ...category,
            meta_robots,
            canonical_url,
            description: updatedDescription,
            meta_title: updatedTitle
        });
    };

    componentWillUnmount = (_args, _callback, instance) => {
        const { isSearchPage } = instance.props;

        if (isSearchPage) {
            _callback(..._args);
            return;
        }

        const {
            category
        } = instance.props;

        if ('mw_hreflangs' in category && 'items' in category.mw_hreflangs) {
            const {
                mw_hreflangs: {
                    items
                }
            } = category;

            if (items) {
                items.forEach((data) => {
                    const previousHreflang = document.querySelector(
                        `[rel="alternate"][hreflang="${data.code}"][href="${data.url}"]`
                    );

                    if (previousHreflang) {
                        previousHreflang.remove();
                    }

                    const previousHreflangDefault = document.querySelector(
                        `[rel="alternate"][hreflang="x-default"][href="${data.url}"]`
                    );

                    if (previousHreflangDefault) {
                        previousHreflangDefault.remove();
                    }
                });
            }
        }
    };
}

const {
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount,
    updateMeta,
    containerProps
} = new CategoryPageContainerPlugin();

export default {
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidUpdate,
            componentWillUnmount,
            componentDidMount,
            updateMeta,
            containerProps
        }
    },
    'Route/CategoryPage/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps
            }
        ]
    }
};
