/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ProductListQuery from 'Query/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Scandiweb/Gtm/Query/Product/Query */
export class ProductQuery {
    getQuery(productIDs) {
        return new Field('getProduct')
            .addArgument('productIDs', '[Int]', productIDs)
            .addFieldList(this.getFields());
    }

    getFields() {
        return [
            this.getProductsFields(),
            'categoryNames'
        ];
    }

    getProductsFields() {
        return new Field('products')
            .addFieldList(ProductListQuery._getProductInterfaceFields());
    }

    setProductGaCategories(categories, itemIds, guestCartId) {
        return new Field('setGaQuoteItemsCategories')
            .addArgument('categories', 'String', categories)
            .addArgument('itemIds', '[String]', itemIds)
            .addArgument('guestCartId', 'String', guestCartId);
    }

    getProductGaCategories(itemIds, guestCartId) {
        return new Field('getGaQuoteItemsCategories')
            .addArgument('itemIds', '[String]', itemIds)
            .addArgument('guestCartId', 'String', guestCartId);
    }
}

export default new ProductQuery();
