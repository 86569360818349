import blogIcon from 'Style/icons/logos/blog.svg';
import emailIcon from 'Style/icons/logos/email.svg';
import facebookIcon from 'Style/icons/logos/facebook.svg';
import linkedinIcon from 'Style/icons/logos/in.svg';
import instaIcon from 'Style/icons/logos/insta.svg';
import locationIcon from 'Style/icons/logos/location.svg';
import madaIcon from 'Style/icons/logos/mada.svg';
import mastercardIcon from 'Style/icons/logos/mastercard.svg';
import paypalIcon from 'Style/icons/logos/paypal.svg';
import phoneIcon from 'Style/icons/logos/phone.svg';
import pinterestIcon from 'Style/icons/logos/pinterest.svg';
import twitterIcon from 'Style/icons/logos/twitter.svg';
import visaIcon from 'Style/icons/logos/visa.svg';
import youtubeIcon from 'Style/icons/logos/youtube.svg';

export const PROMO_BENEFITS_CMS_BLOCK = 'promo-benefits';
export const FOOTER_CONTENT_CMS_BLOCK = 'footer-accordion-columns';
export const RENDER_CONTACTS = 'render_contacts';
export const RENDER_PAYMENT_ICONS = 'render_payment_icons';
export * from 'SourceComponent/Footer/Footer.config';

export const RENDER_NEWSLETTER = 'render_newsletter';

export const COLUMN_MAP = [
    {
        title: __('Shop by room'),
        items: [
            {
                href: '/furniture/living-room',
                title: __('Living Room')
            },
            {
                href: '/furniture/bedroom',
                title: __('Bedroom')
            },
            {
                href: '/furniture/dining-room',
                title: __('Dining Room')
            },
            {
                href: '/furniture/kids-room',
                title: __('Kids Room')
            },
            {
                href: '/furniture/outdoor',
                title: __('Outdoor')
            },
            {
                href: '/furniture/home-office',
                title: __('Home Office')
            }
        ]
    },
    {
        title: __('Departments'),
        items: [
            {
                href: '/accessories/soft-furnishing',
                title: __('Soft Furnishing')
            },
            {
                href: '/accessories/home-decor',
                title: __('Home Decor')
            },
            {
                href: '/accessories/kitchen',
                title: __('Kitchen')
            },
            {
                href: '/accessories/bathroom',
                title: __('Bathroom')
            },
            {
                href: '/accessories/lighting',
                title: __('Lighting')
            },
            {
                href: '/accessories/storage-and-utility',
                title: __('Storage & Utility')
            }
        ]
    },
    {
        title: __('Company'),
        items: [
            {
                href: '/about-us',
                title: __('About Us')
            },
            {
                href: '/faqs/privacy-policy',
                title: __('Privacy Policy')
            },
            {
                href: '/stores',
                title: __('Store Locator')
            },
            {
                href: '/faqs/home',
                title: __('FAQs')
            },
            {
                href: '/contact',
                title: __('Contact Us')
            },
            {
                href: '/blog',
                title: __('Blog')
            }
        ]
    },
    {
        title: __('Information'),
        items: [
            {
                href: '/faqs/payment',
                title: __('Payment')
            },
            {
                href: '/faqs/click-and-collect',
                title: __('Click & Collect')
            },
            {
                href: '/faqs/shipping',
                title: __('Shipping')
            },
            {
                href: '/faqs/return',
                title: __('Returns & refunds')
            },
            {
                href: '/faqs/warranty',
                title: __('Warranty')
            },
            {
                href: '/faqs/faq-installment',
                title: __('Installment')
            },
            {
                href: '/faqs/buy-a-gift-card',
                title: __('Buy a Gift card')
            },
            {
                href: '/faqs/join-loyalty',
                title: __('Join Loyalty')
            }
        ]
    }
];

export const CONTACTS_MAP = [
    {
        title: __('Get in touch'),
        mobileTitle: __('CONNECT WITH US'),
        mods: { contacts: true, permanent: true },
        isNonExpandable: true,
        items: [
            {
                href: 'mailto:panem@panemirates.ae',
                src: emailIcon,
                title: 'panem@panemirates.ae',
                altTitle: 'panem@panemirates.ae',
                render: 'render_contacts'
            },
            {
                href: 'tel:800726',
                src: phoneIcon,
                title: '800 726',
                altTitle: 'telephone: 800 726',
                render: 'render_contacts'
            },
            {
                href: '/stores',
                src: locationIcon,
                title: __('Store Locator'),
                altTitle: __('Store Locator'),
                render: 'render_contacts'
            }
        ],
        mobileItems: [
            {
                href: 'tel:800726',
                src: phoneIcon,
                title: '800 726',
                altTitle: 'telephone: 800 726',
                render: 'render_contacts'
            },
            {
                href: 'mailto:panem@panemirates.ae',
                src: emailIcon,
                title: 'panem@panemirates.ae',
                altTitle: 'panem@panemirates.ae',
                render: 'render_contacts'
            },
            {
                href: '/stores',
                src: locationIcon,
                title: __('Store Locator'),
                altTitle: __('Store Locator'),
                render: 'render_contacts'
            }
        ]
    },
    {
        title: __('Follow us'),
        mods: { socials: true, permanent: true },
        isItemsHorizontal: true,
        isNonExpandable: true,
        items: [
            {
                href: 'https://www.facebook.com/PANEmirates.uae/',
                src: facebookIcon,
                title: '',
                altTitle: 'Facebook',
                render: 'render_contacts'
            },
            {
                href: 'https://twitter.com/panemirates',
                src: twitterIcon,
                title: '',
                altTitle: 'Twitter',
                render: 'render_contacts'
            },
            {
                href: 'https://www.instagram.com/panemirates/',
                src: instaIcon,
                title: '',
                altTitle: 'Instagram',
                render: 'render_contacts'
            },
            {
                href: 'https://www.youtube.com/channel/UCqgoZ7AtyTwmZ8B1psLNO2A',
                src: youtubeIcon,
                title: '',
                altTitle: 'YouTube',
                render: 'render_contacts'
            },
            {
                href: 'https://www.pinterest.com/panemirates/',
                src: pinterestIcon,
                title: '',
                altTitle: 'Pinterest',
                render: 'render_contacts'
            },
            {
                href: 'https://ae.linkedin.com/company/pan-emirates/',
                src: linkedinIcon,
                title: '',
                altTitle: 'Linkedin',
                render: 'render_contacts'
            },
            {
                href: '/blog',
                src: blogIcon,
                title: '',
                altTitle: 'Blog',
                render: 'render_contacts'
            }
        ]
    }
];

export const PAYMENT_MAP = [
    {
        title: __('Payment methods'),
        mods: { payment: true },
        isItemsHorizontal: true,
        isNonExpandable: true,
        items: [
            {
                src: visaIcon,
                title: '',
                altTitle: 'Visa',
                render: 'render_payment_icons'
            },
            {
                src: mastercardIcon,
                title: '',
                altTitle: 'Mastercard',
                render: 'render_payment_icons'
            },
            {
                src: paypalIcon,
                title: '',
                altTitle: 'Paypal',
                render: 'render_payment_icons'
            },
            {
                src: madaIcon,
                title: '',
                altTitle: 'Mada',
                render: 'render_payment_icons'
            }
        ]
    }
];
