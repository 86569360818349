import { Field } from 'Util/Query';

/**
 * New queries for Panemirates_Inventory module
 */
/** @namespace Scandipwa/Query/Inventory/Query */
export class InventoryQuery {
    /**
     * Gets inventory_sources joined with scandiweb_store_finder_stores
     * for additional data like workdays.
     * - Providing productsInfo (product skus) will return stocks common to those products.
     */
    getAnyStores({
        productsInfo,
        search = '',
        country = '',
        isPickupLocation,
        isEnabled
    }) {
        return new Field('getAnyStores')
            .addArgument('input', 'GetAnyStoresInput!', {
                search,
                country,
                productsInfo,
                isPickupLocation,
                isEnabled
            })
            .addField(this.getStoreField());
    }

    /**
     * Gets stock status { inStock: boolean } for specific product
     */
    getStockStatus({ sku, sourceCode }) {
        return new Field('getStockStatus')
            .addArgument('input', 'GetStockStatusInput!', {
                sku,
                sourceCode
            })
            .addFieldList(this.getStockStatusField());
    }

    getStockStatusField() {
        return [
            'inStock'
        ];
    }

    getStoreField() {
        return new Field('items')
            .addFieldList(this.getStoreFields());
    }

    getStoreFields() {
        return [
            'city',
            'country',
            'description',
            'name',
            'phone',
            'pickup_location_code',
            'longitude',
            'latitude',
            'postcode',
            'region',
            'street',
            'countryCode',
            'deliveryTime',
            'workingDays',
            'storeHours',
            'sourceCode',
            'area',
            'area_code'
        ];
    }
}

export default new InventoryQuery();
