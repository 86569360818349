/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_MAGEFAN_BLOG_STORE } from './MagefanBlog.config';

/** @namespace Scandiweb/MagefanBlog/Store/MagefanBlog/Reducer/getInitialState */
export const getInitialState = () => ({
    isSidebarLoading: true,
    isSidebarItemsLoading: true,
    sidebarArchive: [],
    sidebarCategories: [],
    sidebarFeaturedPosts: [],
    sidebarRecentPosts: [],
    sidebarPopularPosts: [],
    sidebarTags: [],
    pageType: null,
    featuredImage: 'CMS_BLOCK',
    postDetails: { categories: null, publishDate: null }
});

/** @namespace Scandiweb/MagefanBlog/Store/MagefanBlog/Reducer/MagefanBlogReducer */
export const MagefanBlogReducer = (state = getInitialState(), action) => {
    const { type, state: newState } = action;

    if (type === UPDATE_MAGEFAN_BLOG_STORE) {
        return {
            ...state,
            ...newState
        };
    }

    return state;
};

export default MagefanBlogReducer;
