/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ApplePayExpress.style';

/** @namespace Scandiweb/AmazonPayfort/Component/ApplePayExpress/Component */
export class ApplePayExpressComponent extends PureComponent {
    static propTypes = {
        placeOrder: PropTypes.func.isRequired
    };

    render() {
        const {
            placeOrder
        } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              block="ApplePayExpress"
              type="submit"
              id="applePayExpress"
              onClick={ placeOrder }
            />
        );
    }
}
