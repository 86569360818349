import { UPDATE_SELECTED_SLOT, UPDATE_SELECTED_STORE_ADDRESS } from './CheckoutDelivery.config';

/** @namespace Scandipwa/Store/CheckoutDelivery/Action/updateSelectedStoreAddress */
export const updateSelectedStoreAddress = (address) => ({
    type: UPDATE_SELECTED_STORE_ADDRESS,
    address
});

/** @namespace Scandipwa/Store/CheckoutDelivery/Action/updateSelectedSlot */
export const updateSelectedSlot = (slot) => ({
    type: UPDATE_SELECTED_SLOT,
    slot
});
