import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ExpressCheckoutMethodsList.style';

/** @namespace Scandipwa/Component/ExpressCheckoutMethodsList/Component */
export class ExpressCheckoutMethodsListComponent extends PureComponent {
    static propTypes = {
        methodRenderers: PropTypes.arrayOf(PropTypes.func).isRequired
    };

    /**
     * Renders injected payment methods
     */
    renderMethods() {
        const {
            methodRenderers
        } = this.props;

        return methodRenderers.map(
            /* methodRenderer function should return a React component */
            (methodRenderer) => methodRenderer()
        );
    }

    render() {
        const {
            methodRenderers
        } = this.props;

        /**
         * Don't render anything if there are no methods plugged in
         */
        if (methodRenderers.length === 0) {
            return null;
        }

        return (
            <div block="ExpressCheckoutMethodsList">
                <span
                  block="ExpressCheckoutMethodsList"
                  elem="Title"
                >
                    { __('Express Checkout') }
                </span>
                <div
                  block="ExpressCheckoutMethodsList"
                  elem="OutlineWrapper"
                >
                    { this.renderMethods() }
                </div>
            </div>
        );
    }
}

export default ExpressCheckoutMethodsListComponent;
