import { isEqual, noop } from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';

import FieldSelectComponent from 'Component/FieldSelect/FieldSelect.component';
import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';
import { DeviceType, getDeviceType, getIsMobile } from 'Util/Device';
import { isEmpty } from 'Util/Misc';
import { isMobile } from 'Util/Mobile';

import { DROPDOWN_MIN_HEIGHT, DROPDOWN_SCROLL_MIN_ITEMS } from './FieldSelect.config';

/** @namespace Scandipwa/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    static propTypes = {
        ...this.propTypes,
        isSearch: PropTypes.bool,
        triggerOnlyOnClick: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isSearch: false,
        triggerOnlyOnClick: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleSearchBlur: this.handleSearchBlur.bind(this),
        handleSearchFocus: this.handleSearchFocus.bind(this),
        handleSearchChange: this.handleSearchChange.bind(this),
        handleOnMouseDown: this.handleOnMouseDown.bind(this),
        handleNativeSelectBlur: this.handleNativeSelectBlur.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            triggerSelectExpand: false,
            selectedOptionLabel: null,
            isSearching: false,
            filter: '',
            selectedOptionIndex: -1
        };

        const {
            attr: {
                defaultValue = '',
                value: attrValue = ''
            } = {}
        } = props;

        const initialValue = !isEmpty(attrValue) ? attrValue : defaultValue;
        const options = this.getOptions();

        if (isEmpty(initialValue) || !options?.length) {
            return;
        }

        // Find option for default value if provided to get its label
        const defaultOptionIndex = FieldSelectContainer.getOptionIndexByValue(options, initialValue);

        if (!options[defaultOptionIndex]) {
            return;
        }

        const {
            label
        } = options[defaultOptionIndex];

        this.state = {
            ...this.state,
            filter: label,
            selectedOptionIndex: defaultOptionIndex,
            selectedOptionLabel: options[defaultOptionIndex]?.label ?? '',
            selectedOptionValue: initialValue ?? ''
        };
    }

    containerProps() {
        const {
            isSearch,
            isShowHtmlOptions,
            attr: { selectPlaceholder = '' }
        } = this.props;

        const {
            isSearching
        } = this.state;

        const {
            triggerSelectExpand,
            filter,
            selectedOptionLabel,
            selectedOptionValue
        } = this.state;

        return {
            ...super.containerProps(),
            isSearch,
            isShowHtmlOptions,
            triggerSelectExpand,
            filter,
            selectedOptionLabel,
            placeholder: selectPlaceholder,
            isSearching,
            selectedOptionValue,
            isNoResultsAndIsSearching: this.getIsNoResultsWhileSearching()
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedOptionIndex: prevSelectedOptionIndex } = prevState;

        const {
            isDisabled: prevIsDisabled,
            attr: { triggerExpand: prevTriggerExpand, value: prevAttrValue },
            options: prevPropsOptions
        } = prevProps;

        const {
            selectedOptionIndex,
            filter,
            isExpanded
        } = this.state;

        const {
            attr: { triggerExpand, value: attrValue },
            isSearch,
            isDisabled,
            options: propsOptions,
            isDisableResetOnOptionsChange,
            triggerOnlyOnClick
        } = this.props;

        const options = this.getOptions();

        if (!triggerOnlyOnClick && prevSelectedOptionIndex !== selectedOptionIndex) {
            const { value } = options[selectedOptionIndex] || {};

            this.triggerOnChange(value);
        }

        const stateUpdate = {};

        if (attrValue !== prevAttrValue) {
            const selectedOptionIndex = FieldSelectContainer.getOptionIndexByValue(options, attrValue);
            stateUpdate.selectedOptionIndex = selectedOptionIndex;
            stateUpdate.selectedOptionLabel = options[selectedOptionIndex]?.label ?? '';
            stateUpdate.selectedOptionValue = attrValue ?? '';
        }

        // If element changes disabled state, reset field select to not have selected option
        if (
            prevIsDisabled !== isDisabled
            || (
                propsOptions
                && Object.keys(propsOptions).length
                && !isEqual(prevPropsOptions, propsOptions)
                && !isDisableResetOnOptionsChange
            )
        ) {
            stateUpdate.selectedOptionIndex = isSearch ? -1 : 0;
            stateUpdate.selectedOptionLabel = isSearch ? '' : options[0].label;
            stateUpdate.selectedOptionValue = isSearch ? '' : options[0].value;
            stateUpdate.filter = '';
            stateUpdate.isExpanded = false;
        }

        if (triggerExpand !== prevTriggerExpand) {
            stateUpdate.isExpanded = triggerExpand;
        }

        if (!isSearch && !Object.keys(stateUpdate).length) {
            return;
        }

        if (!isSearch) {
            this.setState(stateUpdate);

            return;
        }

        const matchedOptionIndex = FieldSelectContainer.getOptionIndexByLabel(
            options,
            filter
        );

        if (matchedOptionIndex !== -1) {
            const matchedOption = options[matchedOptionIndex];
            const { label } = matchedOption;

            stateUpdate.filter = label;
        }

        if (matchedOptionIndex === -1 && !isExpanded) {
            stateUpdate.filter = '';
        }

        stateUpdate.selectedOptionIndex = matchedOptionIndex;
        stateUpdate.selectedOptionLabel = options[matchedOptionIndex]?.label ?? '';
        stateUpdate.selectedOptionValue = options[matchedOptionIndex]?.value ?? '';

        this.setState(stateUpdate);
    }

    triggerOnChange(value) {
        const { events: { onChange } = {} } = this.props;

        if (onChange) {
            onChange(value);
        }
    }

    getSearchResult() {
        const { options } = this.props;
        const { filter } = this.state;

        return options.filter(
            ({ label }) => label?.toLowerCase()?.includes(filter?.toLowerCase())
        );
    }

    // vvv Overridden to also include search filter
    getOptions() {
        const {
            isSearch,
            options,
            isRemoveUnavailable
        } = this.props;
        const { isSearching } = this.state;

        if (isSearch && isSearching) {
            const searchResult = this.getSearchResult();

            return searchResult;
        }

        if (isSearch) {
            return options;
        }

        const formattedOptions = isRemoveUnavailable && isMobile.any()
            ? options?.filter(({ isAvailable }) => isAvailable)
            : options;

        return this.prependFakeOption(formattedOptions);
    }

    prependFakeOption(options) {
        const {
            attr: {
                id = 'select',
                selectPlaceholder = __('Select item...'),
                noPlaceholder
            } = {}
        } = this.props;

        if (noPlaceholder) {
            return options;
        }

        return [
            {
                id: `${id}-placeholder`,
                name: `${id}-placeholder`,
                label: selectPlaceholder,
                value: '',
                sort_order: -100,
                isPlaceholder: true
            },
            ...options
        ];
    }

    getIsNoResultsWhileSearching() {
        const { isSearch } = this.props;

        if (!isSearch) {
            return false;
        }

        const searchResult = this.getSearchResult();

        return !searchResult?.length;
    }

    static getOptionIndexByLabel(options, filter) {
        return options.findIndex(
            (option) => option.label?.toLowerCase() === filter?.toLowerCase()
        );
    }

    static getOptionIndexByValue(options, value) {
        return options.findIndex(({ value: optValue }) => optValue === value);
    }

    static getOptionByValue(options, value) {
        return options.find(({ value: optValue }) => optValue === value);
    }

    // Overridden to properly close expandable part for the next possible ChevronIcon click
    handleSelectExpandedExpand(event) {
        const { isExpanded } = this.state;

        if (isExpanded) {
            this.handleSelectExpand(event);
        }
    }

    handleIsScrollableList() {
        const { attr } = this.props;
        const options = this.getOptions();

        if (options.length > attr.minItems || DROPDOWN_SCROLL_MIN_ITEMS) {
            this.setState({ isScrollable: true });
        } else {
            this.setState({ isScrollable: false });
        }
    }

    // Overridden to not handle keypress if its search
    handleSelectListKeyPress(event) {
        const { isSearch } = this.props;

        if (!isSearch) {
            super.handleSelectListKeyPress(event);
        }
    }

    // Overridden to also update filter value
    handleSelectListOptionClick(option) {
        return () => {
            const {
                changeValueOnDoubleClick,
                triggerOnlyOnClick
            } = this.props;

            const {
                value,
                target: {
                    value: targetValue
                } = {},
                label
            } = option;

            const {
                selectedOptionIndex
            } = this.state;

            const options = this.getOptions();

            const {
                value: selectedOptionValue
            } = options[selectedOptionIndex] || {};

            const fieldValue = value || targetValue || '';
            const selectedOptionLabel = label || value;

            const hasChanged = fieldValue !== selectedOptionValue;

            if (changeValueOnDoubleClick && selectedOptionValue === fieldValue) {
                this.setState({
                    filter: '',
                    selectedOptionLabel: '',
                    selectedOptionIndex: -1
                });

                if (triggerOnlyOnClick) {
                    this.triggerOnChange(null);
                }

                return;
            }

            if (triggerOnlyOnClick && hasChanged) {
                this.triggerOnChange(fieldValue);
            }

            this.setState({
                filter: label,
                selectedOptionIndex: FieldSelectContainer.getOptionIndexByValue(options, fieldValue),
                selectedOptionLabel,
                selectedOptionValue: fieldValue
            });
        };
    }

    handleSearchBlur() {
        const { selectedOptionIndex } = this.state;
        const options = this.getOptions();
        const { value } = options[selectedOptionIndex] || {};

        this.triggerOnChange(value);

        this.setState({
            isExpanded: false
        });
    }

    handleSearchFocus() {
        this.setState({
            isExpanded: true,
            isSearching: false
        });
    }

    handleSearchChange(event) {
        this.setState({
            filter: event.target.value,
            isSearching: true
        });
    }

    /**
     *  New method to disable hide options expandable by click on the scrollbar
    */
    handleOnMouseDown(event) {
        if (event && event.target.localName === 'div') {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();

            return false;
        }

        return true;
    }

    handleDropdownOpenDirection() {
        const windowHeight = document.documentElement.clientHeight;
        const rect = this.fieldRef.getBoundingClientRect?.();
        const bottomPosition = Math.round(windowHeight - rect?.bottom);

        if (bottomPosition < DROPDOWN_MIN_HEIGHT) {
            this.setState({ isDropdownOpenUpwards: true });
        } else {
            this.setState({ isDropdownOpenUpwards: false });
        }
    }

    /**
     * Overriden to:
     * - Prevent cartItem click
     * - Change field select expand detector
     */
    handleSelectExpand(event) {
        if (getIsMobile()) {
            return;
        }

        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }

        if (!this.isSelectDisabled()) {
            if (!event) {
                this.setState({ isExpanded: false });
                return;
            }

            const clickedItem = event.target;
            const isClickOnChevron = clickedItem.localName === 'path' || clickedItem.localName === 'svg';

            if (clickedItem === this.fieldRef.parentElement
                || clickedItem.localName === 'select'
                || isClickOnChevron
            ) {
                this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
            } else if (clickedItem.closest('.FieldSelect-Options')) {
                // clickedItem.closest('.FieldSelect-Options'): if clicked element ancestor is .FieldSelect-Options
                this.setState({ isExpanded: false });
            }
        }

        this.handleDropdownOpenDirection();
    }

    /**
     * New function to:
     * - Handle onBlur on mobile safari browsers (detect outside click for system popup)
     */
    handleNativeSelectBlur(e) {
        const {
            events: {
                onBlur = noop
            }
        } = this.props;

        // call onBlur event if passed from props
        onBlur(e);

        if (getDeviceType() === DeviceType.IOS) {
            this.setState({ isExpanded: false });
        }
    }

    render() {
        return (
            <FieldSelectComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export const MemoizedFieldSelectContainer = memo(FieldSelectContainer, (prevProps, nextProps) => {
    const {
        attr,
        events,
        options,
        ...restProps
    } = prevProps;

    const {
        attr: nextAttr,
        events: nextEvents,
        options: nextOptions,
        ...restNextProps
    } = nextProps;

    // vvv replaced JSON.stringify() with isEqual function from lodash
    // vvv because JSON.stringify() throws 'cyclic object value' error
    // vvv on installment fields
    // vvv https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
    if (
        !isEqual(attr, nextAttr)
        // vvv Maybe redo...
        || Object.keys(events).some((key) => !nextEvents[key])
        || !isEqual(options, nextOptions)
    ) {
        return false;
    }

    const restPropsAreNotEqual = Object.keys(nextProps).some(
        (key) => (typeof restProps[key] === 'function' ? false : restProps[key] !== restNextProps[key])
    );

    return !restPropsAreNotEqual;
});

export default MemoizedFieldSelectContainer;
