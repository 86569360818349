/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import { TotalsType } from 'Type/MiniCart.type';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import AmastyReturnInformationQuery from '../../query/AmastyReturnInformation.query';
import AmastyRmaInCart from './AmastyRmaInCart.component';

/** @namespace Scandiweb/AmastyRma/Component/AmastyRmaInCart/Container */
export class AmastyRmaInCartContainer extends PureComponent {
    static propTypes = {
        totals: TotalsType.isRequired
    };

    containerFunctions = {
    };

    state = {
        isLoading: true,
        returnInfoData: []
    };

    componentDidMount() {
        this.fetchReturnInformation();
    }

    async fetchReturnInformation() {
        const { totals: { items } } = this.props;

        const names = items.map((item) => {
            const { product: { name } } = item;

            return name;
        });

        try {
            const items = await Promise.all(
                names.map(async (name) => {
                    const {
                        products: { items }
                    } = await executeGet(
                        prepareQuery([AmastyReturnInformationQuery.getProductsField(name)]),
                        'AmastyRMAproducts',
                        ONE_MONTH_IN_SECONDS
                    );

                    const { amrma_return_info } = items.find((item) => item.amrma_return_info.length === 1) || {};

                    return {
                        name,
                        info: amrma_return_info
                    };
                })
            );

            this.setState({
                returnInfoData: items,
                isLoading: false
            });
        } catch (error) {
            this.setState({
                isLoading: false
            });
        }
    }

    formatReturnInfo() {
        const { totals: { items } } = this.props;

        return items.map((item) => {
            const { product: { name, amrma_return_info }, product } = item;

            if (product.variants && product.variants.length) {
                return {
                    name: product.variants[0].name,
                    info: product.variants[0].amrma_return_info
                };
            }

            return {
                name,
                info: amrma_return_info
            };
        });
    }

    containerProps() {
        const { totals } = this.props;
        const { returnInfoData, isLoading } = this.state;

        return {
            returnInfo: this.formatReturnInfo(),
            totals,
            returnInfoData,
            isLoading
        };
    }

    render() {
        return (
            <AmastyRmaInCart
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AmastyRmaInCartContainer;
