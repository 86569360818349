/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import FacebookIconComponent from '../FacebookIcon/FacebookIcon.component';
import GoogleIconComponent from '../GoogleIcon/GoogleIcon.component';

import './SocialLoginButton.style';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Component */
export class SocialLoginButtonComponent extends PureComponent {
     static propTypes = {
         label: PropTypes.string.isRequired,
         type: PropTypes.string.isRequired,
         onClick: PropTypes.func.isRequired
     };

     renderButton() {
         const { label, type, onClick } = this.props;

         const comp = label.toLowerCase() === 'facebook' ? <FacebookIconComponent /> : <GoogleIconComponent />;

         return (
             <div block="SocialLoginButton">
                 <button
                   block="SocialLoginButton"
                   mods={ { type } }
                   mix={ { block: 'Button' } }
                   onClick={ onClick }
                 >
                    { comp }
                    { __('Continue with ') }
                    { __(label) }
                 </button>
             </div>
         );
     }

     render() {
         return (
             <div block="SocialLoginButton">
                 { this.renderButton() }
             </div>
         );
     }
}

export default SocialLoginButtonComponent;
