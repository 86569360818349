Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/amasty-gift-cards/src/plugin/Query/CheckoutQuery.plugin.js')]);
/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmazonPayfort/Query/PayfortInstallmentPlans/Query */
export class PayfortInstallmentPlansQuery {
    getPayfortInstallmentPlansField(type, id) {
        return new Field('payfortInstallmentPlans')
            .addArgument('input', 'PayfortInstallmentPlansInput!', { type, id })
            .addFieldList(this.getPayfortInstallmentPlansFields());
    }

    getPayfortInstallmentPlansFields() {
        return [
            'success',
            'isEmpty',
            this.getCountriesField(),
            this.getIssuersField(),
            this.getPlansField()
        ];
    }

    getCountriesField() {
        return new Field('countries')
            .addFieldList(this.getCountriesFields());
    }

    getCountriesFields() {
        return [
            'code',
            'label'
        ];
    }

    getIssuersField() {
        return new Field('issuers')
            .addFieldList(this.getIssuersFields());
    }

    getIssuersFields() {
        return [
            'country_code',
            'issuer_code',
            'issuer_name',
            'terms_url',
            'issuer_logo',
            'bins'
        ];
    }

    getPlansField() {
        return new Field('plans')
            .addFieldList(this.getPlansFields());
    }

    getPlansFields() {
        return [
            'issuer_code',
            'interest',
            'interest_info',
            'fees_amount',
            'plan_type',
            'amountPerMonth',
            'plan_code',
            'maximum_amount',
            'minimum_amount',
            'currency_code',
            'rate_type',
            'fees_type',
            'number_of_installment',
            'processing_fees',
            'processing_fees_info',
            'fee_display_value',
            'plan_merchant_type'
        ];
    }
}

export default new PayfortInstallmentPlansQuery();
